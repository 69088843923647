/*
 *   File : orderDetail.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Order detail Modal
 *   Integrations : 24-05-2022
 *   Version : v1.0
 */

/*import packages*/
import React, { useState, useEffect } from 'react';
import $ from 'jquery'

/*import componets*/
import { Badge } from '../../components/badge/index';
import Tooltip from '../../components/badge/tooltip';

import { Accordion, AccordionDebt, FundsTab, } from '../../components/dataFormating/index';
import { FundClass, AddAssetClass, Confirmation } from '../../components/modal';
import { Modal } from 'bootstrap';
import SelectBox from '../../components/dropdown/selectBox';
import { PrimaryBtn, PrimaryLink } from '../../components/cta';
import { EmptyNote, Toast } from '../../components/note';

/*import styles*/
import styles from '../../styles/components/transactionDetail.module.scss';
import { Loader } from '../loader';

/*import assets*/
import AddNew from '../../assets/images/dataFormating/add-new.svg';

/*import services */
import APIGet from '../../services/getApi';
import Datatable from '../../components/dataFormating/dataTable';
import devconfig from '../../config/config';
import APIPost from '../../services/postApi';
import { useNavigate } from 'react-router-dom';

const TYPE_TAB = [
	{ title: "Lumpsum", id: 1, value: "lumpsum" },
	{ title: "SIP", id: 2, value: "sip" },
]

/* ----- basket  data ------*/
const TAB_DATA = [
	{
		name: "BASKET_ONE",
		id: 1,
		displayName: "Fund option 1",
		goal: true,
	},
]

export default function VersionHistoryDetail(props) {
	const navigate = useNavigate()


	const [index, setIndex] = useState(1)
	const [fundData, setFundData] = useState({});
	const [fundLoader, setFundLoader] = useState(true);
	const [basket, setBasket] = useState("BASKET_ONE")
	//eslint-disable-next-line
	const [updated, setUpdated] = useState("");
	const [isDebt, setIsDebt] = useState(false);
	const [type, setType] = useState(1);
	const [selectedYear, setSelectedYear] = useState({ label: "1 yr", value: 1 });
	const [selectedYearFd, setSelectedYearFd] = useState({ label: "6 Months", value: 7 });
	const [goal, setGoal] = useState(true);
	const [value, setValue] = useState("lumpsum");
	const [versionData, setVersionData] = useState(props.data)
	const [fdData, setFdData] = useState({})
	const [fdOptions, setFdOptions] = useState([]);
	const [debtOptions, setDebtOptions] = useState([]);
	const [success, setSuccess] = useState(null);
	const [error, setError] = useState(null);
	const [syncLoader, setSyncLoader] = useState(false);
	const [addFund, setAddFund] = useState(false);
	const [equityLumpsumAllocation, setEquityLumpsumAllocation] = useState(null);
	const [equitySipAllocation, setEquitySipAllocation] = useState(null);
	const [debtLumpsumAllocation, setDebtLumpsumAllocation] = useState(null);
	const [debtSipAllocation, setDebtSipAllocation] = useState(null);
	const [fdAllocation, setFdAllocation] = useState(null);
	const [editedData, setEdiedData] = useState({});
	const [assetListLoader, setAssetListLoader] = useState(false);
	const [assetList, setAssetList] = useState([]);
	const [assetData, setAssetData] = useState({});
	const [update, setUpdate] = useState(false);


	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		});
	}, []);

	useEffect(() => {
		var myModalEl = document.getElementById('versionDetail')
		myModalEl.addEventListener('hidden.bs.modal', (event) => {
			props.close()
		})
		//eslint-disable-next-line
	}, [])

	useEffect(() => {
		if (props.data) {
			setVersionData(props?.data)
			_getFunds(props?.data?.version_id)
		}
		//eslint-disable-next-line
	}, [props.data]);
	useEffect(() => {
		if (index === 4) {
			_getAssetClass()
		}
		//eslint-disable-next-line
	}, [index]);


	useEffect(() => {
		if (addFund) {
			_openAddFundModal()
		}
	}, [addFund])
	useEffect(() => {
		_getYear();
		//eslint-disable-next-line
	}, [index]);


	useEffect(() => {
		if (error !== null) {
			setTimeout(() => {
				setError(null);
			}, 3000);
		}
	}, [error])

	useEffect(() => {
		if (success !== null) {
			setTimeout(() => {
				setSuccess(null);
			}, 3000);
		}
	}, [success])


	/* ---- get mutual funds ---*/
	const _getFunds = (loader = versionData.version_id) => {
		setFundLoader(loader);
		setEquityLumpsumAllocation(null);
		setFdAllocation(null);
		setDebtLumpsumAllocation(null);
		console.log(loader)

		let url = devconfig.admin + '/rebalance/version-history-detail'
		let data = JSON.stringify({
			version_id: loader
		});
		APIPost(url, data).then((response) => {
			if (response.status_code === 200) {

				setFundLoader(false);
				if (basket === null) {
					setFdData(response.data.mfs);
					const temp_fd_length_arr = [];

					if (Object.keys(response.data.mfs).filter((item) => item === selectedYearFd.label).length > 0) {
						//eslint-disable-next-line
						Object.values(response.data.mfs[selectedYearFd.label]).map((item) => {
							item.map((fund) => {
								return (
									temp_fd_length_arr.push(fund.allocation)
								);
							})
						});
						//calculation total fd allocation
						setFdAllocation(temp_fd_length_arr.reduce((a, b) => a + b, 0));
					}
				} else {
					setFundData(response.data);
					const temp_equity_lumpsum_length_arr = []
					//eslint-disable-next-line
					Object.values(response.data["lumpsum"].equity).map((item) => {
						item.map((fund) => {
							return (
								temp_equity_lumpsum_length_arr.push(fund.allocation)
							);
						})
					});
					//calculation total equity lumpsum allocation
					setEquityLumpsumAllocation(temp_equity_lumpsum_length_arr.reduce((a, b) => a + b, 0));

					const temp_equity_sip_length_arr = []
					//eslint-disable-next-line
					Object.values(response.data["sip"].equity).map((item) => {
						item.map((fund) => {
							return (
								temp_equity_sip_length_arr.push(fund.allocation)
							);
						})
					});
					//calculation total equity sip allocation
					setEquitySipAllocation(temp_equity_sip_length_arr.reduce((a, b) => a + b, 0));

					const temp_debt_lumpsum_length_arr = []
					if (Object.keys(response.data["lumpsum"].debt).filter((item) => Number(item) === selectedYear.value).length > 0) {
						//eslint-disable-next-line
						Object.values(response.data["lumpsum"].debt[selectedYear.value]).map((item) => {
							item.map((fund) => {
								return (
									temp_debt_lumpsum_length_arr.push(fund.allocation)
								);
							})
						});
						//calculation total debt allocation
						setDebtLumpsumAllocation(temp_debt_lumpsum_length_arr.reduce((a, b) => a + b, 0));

					}

					const temp_debt_sip_length_arr = []
					if (Object.keys(response.data["sip"].debt).filter((item) => Number(item) === selectedYear.value).length > 0) {
						//eslint-disable-next-line
						Object.values(response.data["sip"].debt[selectedYear.value]).map((item) => {
							item.map((fund) => {
								return (
									temp_debt_sip_length_arr.push(fund.allocation)
								);
							})
						});
						//calculation total debt allocation
						setDebtSipAllocation(temp_debt_sip_length_arr.reduce((a, b) => a + b, 0));
					}

				}
			}
			else {
				setFundLoader(false);
				setFundData([]);
			}
		});

	}

	/* ---- get year filter api ---*/
	const _getYear = () => {
		let url = devconfig.admin + '/fund/year';

		APIGet(url).then((response) => {
			if (response.status_code === 200) {
				let tempFdOptions = []
				response.data.fd.sort((a, b) => a.id - b.id).map((item) => {
					return (
						tempFdOptions.push({
							label: item.name,
							value: item.id
						})
					)
				})
				setSelectedYearFd(tempFdOptions[0])
				setFdOptions(tempFdOptions)


				let tempDebtOptions = []
				response.data.goal.sort((a, b) => a.id - b.id).map((item) => {
					return (
						tempDebtOptions.push({
							label: item.name,
							value: item.id
						})
					)
				})
				setDebtOptions(tempDebtOptions)
				setSelectedYear(tempDebtOptions[0])
			}
		})
	}

	/* ---- api to sync latest data with data servers  ---*/
	const _syncData = () => {
		setSyncLoader(true);

		let url = devconfig.admin + '/fund/set-basket-data';
		let data = JSON.stringify({
			"basket_type": basket,
			"is_lumpsum": type === 1 ? true : false,
			"tenure": goal ? selectedYear.value : selectedYearFd.value,
		});

		APIPost(url, data).then((response) => {
			if (response.status_code === 200) {
				setSyncLoader(false);
				setSuccess("Data synced successfully")
			} else {
				setSyncLoader(false);
				setError(response.message)
			}
		})
	}

	/* ------ open add fund Modal -----*/
	const _openAddFundModal = () => {
		var myModal = new Modal(document.getElementById("addFundClass"), {});
		myModal.show();
	}

	const _openAssetModal = () => {
		var myModal = new Modal(document.getElementById("addAsset"), {});
		myModal.show();
	}

	/* ------ asset class list -----*/
	const _getAssetClass = () => {
		setAssetListLoader(true);
		const url = devconfig.admin + "/asset-class/list"

		APIGet(url).then((response) => {
			if (response.status_code === 200) {
				setAssetListLoader(false);
				setAssetList(response.data);
			} else {
				setAssetListLoader(false);
				setAssetList([]);
			}
		});
	}

	const ASSET_COLUMNS = [
		{
			name: 'Name',
			selector: 'name',
			cell: (row) => row.name ? row.name : '--',
			sortable: false,
			left: true,
			minWidth: "280px",
		},

		{
			name: 'Order',
			selector: (row) => row.order ? row.order : '--',
			sortable: false,
			right: false,
			minWidth: "180px",
		},
		{
			name: 'Color',
			cell: (row) => row.color ? row.color : "-",
			sortable: false,
			right: false,
			minWidth: "180px",
		},
		{
			name: 'Created on',
			cell: (row) => row.created ? row.created : "-",
			sortable: false,
			right: false,
			minWidth: "180px",
		},
		{
			name: 'Modified on',
			cell: (row) => row.modified ? row.modified : "-",
			sortable: false,
			right: false,
			minWidth: "180px",
		},
		{
			name: "",
			cell: row => <div className='d-flex'>
				<PrimaryLink name="Edit"
					handleClick={() => {
						setAssetData(row);
						_openAssetModal();
						setUpdate(true);
					}} />

				{/* <PrimaryLink name="Delete"
					className='e-link-delete ps-4'
					toggle="modal"
					target="#confirmationEquity"
					handleClick={() => {
						setAssetData(row);
					}} /> */}
			</div>
		}
	]



	return (
		<>

			<div className={`${styles.e_transaction_detail} modal fade e-order-detail-modal mb-5`}
				id="versionDetail"
				tabIndex="-1"
				aria-labelledby="success"
				aria-hidden="true">
				<div style={{ minWidth: '1000px' }} className="modal-dialog modal-dialog-centered">
					<div className={`${styles.e_modal_content} modal-content px-0`}>
						<div className="modal-header p-4 pb-3 ">

							<div className='position-relative'>
								<h6 className={`${styles.e_modal_title} pt-3 `}>Name: {versionData?.version}
								</h6>
								<span className={`pt-3 `}>Created on: {versionData?.created}
								</span>
							</div>
							<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
						</div>

						<div className="row pb-5">
							<div className="col-lg-12">
								<div className="card e-dashboard-card e-faq-card  border-0 p-0">
									<div className="row pb-5">
										<div className="col-lg-12">
											<div className="card e-dashboard-card e-faq-card  border-0 p-0">
												{/*-------------- baskets ----------*/}
												<div className='row ps-4 pt-4'>
													<div className='col-lg-9 col-9 e-funds-tab mt-2 '>
														<FundsTab data={TAB_DATA}
															funds={true}
															version={true}
															index={index}
															setTab={(temp, name, goal) => {
																setIndex(temp);
																setBasket(name);
																setSelectedYear({ label: "1 yr", value: 1 });
																setGoal(goal);
																setType(1);
																setValue("lumpsum")
															}} />
													</div>
													{
														index === 3 ?
															<div className='col-lg-3 col-3'>
																<SelectBox
																	options={fdOptions}
																	value={selectedYearFd}
																	isSearchable={false}
																	selectchange={(temp) => {
																		setSelectedYearFd(temp)
																	}} />
															</div>
															:
															null
													}
												</div>



												{/*----------- list of funds added ------------------*/}

												{
													index < 3 ?
														<>
															<div className='row pt-3 mx-0 mb-3 ps-4'>
																<div className={` col-lg-12 ps-0 pt-2 e-type-sec`}>
																	<div className='d-flex'>
																		{
																			TYPE_TAB.map((item, tabKey) => {
																				return (
																					<span
																						className={type === item.id ? `e-active-tab me-4` : `e-tab me-4`}
																						onClick={() => {
																							setType(item.id);
																							setValue(item.value);
																							setSelectedYear({ label: "1 yr", value: 1 })
																						}} key={tabKey}
																					>
																						{item.title}
																					</span>
																				)
																			})
																		}
																	</div>
																</div>
															</div>

															<div className='row mb-3 ps-4'>
																<div className='col-lg-12 d-flex pt-4 pb-0 e-equity'>
																	<h6 className='mb-0'>Equity</h6>
																	<p className='ps-3 mb-0'>Allocation
																		{
																			Object.keys(fundData).length > 0 && Object.keys(fundData[value]).length > 0 && Object.values(fundData[value].equity)?.length > 0 && equityLumpsumAllocation !== null ?
																				<span className='ps-2'>{value === "lumpsum" ? equityLumpsumAllocation : equitySipAllocation}%</span>
																				:
																				<span className='ps-2'>0%</span>
																		}
																	</p>
																</div>
															</div>
															{
																fundLoader ?
																	<Loader />
																	:
																	<>
																		{
																			Object.keys(fundData).length > 0 && Object.keys(fundData[value]).length > 0 && Object.values(fundData[value].equity).length > 0 ?
																				<div className="col-lg-12 px-4">
																					<Accordion
																						data={fundData[value].equity}
																						index={index}
																						name={basket}
																						version={true}
																						updatedFund={(data) => {
																							_getFunds();
																							setUpdated(data);
																						}}
																						close={() => {
																							_getFunds(false)
																						}}
																						setEditedData={(item) => {
																							setEdiedData(item)
																						}}
																					/>
																				</div>
																				:
																				null
																		}

																	</>
															}

															<div className='row mb-2 px-4'>
																<div className='col-lg-9 col-9 d-flex e-equity pt-3'>
																	<h6 className='mb-0 '>Debt</h6>
																	<p className='ps-3 mb-0'>Allocation
																		{
																			Object.keys(fundData).length > 0 && Object.keys(fundData[value]).length > 0 && Object.keys(fundData[value].debt).filter((item) => Number(item) === selectedYear.value).length > 0 && debtLumpsumAllocation !== null ?
																				<span className='ps-2'>{
																					value === "lumpsum" ?
																						debtLumpsumAllocation
																						:
																						debtSipAllocation
																				}%</span>
																				:
																				<span className='ps-2'>0%</span>
																		}
																	</p>
																</div>
																<div className='col-lg-3 col-3'>
																	<SelectBox
																		options={debtOptions}
																		value={selectedYear}
																		isSearchable={false}
																		selectchange={(temp) => setSelectedYear(temp)} />
																</div>
															</div>
															{
																fundLoader ?
																	<Loader />
																	:
																	<>

																		{
																			Object.keys(fundData).length > 0 && Object.keys(fundData[value]).length > 0 && Object.keys(fundData[value].debt).filter((item) => Number(item) === selectedYear.value).length > 0 ?
																				<div className="col-lg-12 px-4">

																					<AccordionDebt
																						data={fundData[value].debt[selectedYear?.value]}
																						index={index}
																						name={basket}
																						version={true}
																						updatedFund={(data) => {
																							_getFunds();
																							setUpdated(data);
																						}}
																						close={() => {
																							_getFunds(false)
																						}}

																						setEditedData={(item) => {
																							setEdiedData(item)
																						}}
																					/>
																				</div>
																				:
																				null
																		}
																	</>
															}
														</>
														:
														index === 3 ?
															fundLoader ?
																<Loader />
																:
																<>
																	<div className='col-lg-12 d-flex e-equity pt-3 px-4'>
																		<p className='ps-0'>Allocation
																			{
																				fdData && Object.keys(fdData).length > 0 && fdData[selectedYearFd.label] && Object.keys(fdData[selectedYearFd.label]).length > 0 && fdAllocation !== null ?
																					<span className='ps-2'>
																						{
																							fdAllocation
																						}%
																					</span>
																					:
																					<span className='ps-2'>0%</span>
																			}
																		</p>
																	</div>
																	<>

																		{

																			fdData && Object.keys(fdData).length > 0 && fdData[selectedYearFd.label] && Object.keys(fdData[selectedYearFd.label]).length > 0 && Object.keys(fdData).filter((item) => item === selectedYearFd.label).length > 0 ?
																				<div className="col-lg-12 px-4">
																					<Accordion
																						data={fdData[selectedYearFd.label]}
																						index={index}
																						name={basket}
																						version={true}
																						updatedFund={(data) => {
																							_getFunds();
																							setUpdated(data);
																						}}
																						setEditedData={(item) => {
																							setEdiedData(item);
																						}}
																						close={() => {
																							_getFunds(false)
																						}}
																					/>
																					{/* <Accordion
																			data={fundData["value"].equity}
																			index={index}
																			name={basket}
																			updatedFund={(data) => {
																				_getFunds();
																				setUpdated(data);
																			}}
																		/> */}
																				</div>
																				:
																				null
																		}

																	</>
																</>
															:
															assetListLoader ?
																<Loader />
																:

																<div className="row mx-0 mt-4">
																	<div className='col-lg-2 offset-10'>
																		<div className="e-add-sec d-flex justify-content-center " onClick={() => _openAssetModal()}>
																			<h6 className='px-0 d-flex justify-content-center'><span className="pe-3"><img src={AddNew} alt="add" /></span>Add New</h6>
																		</div>
																	</div>
																	<div className='col-12 px-0'>
																		{
																			assetList.length > 0 ?
																				<Datatable
																					columns={ASSET_COLUMNS}
																					data={assetList}
																				/>
																				:
																				<EmptyNote note="No data" />
																		}
																	</div>
																</div>

												}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

					</div>
				</div>
			</div>

		</>
	);
}
