/*
 *   File : dateRangepicker.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description :  dateRangepicker.js
 *   Integrations : 
 *   Version : v1.0
 *   Created : 09-12-2021
 */

/*Package importing*/
import React from 'react';

/*import Image from 'next/image' */
import { DatePicker } from 'antd';
import moment from 'moment';
/* import styles*/
import 'antd/dist/antd.css';
import { useState } from 'react';
import { useEffect } from 'react';

const { RangePicker } = DatePicker;
const DateRange = (props) => {
	const dateFormat = 'DD-MM-YYYY';
	const [start, setStart] = useState(null);
	const [end, setEnd] = useState(null);

	/* ---- moment ---*/
	useEffect(() => {
		if (props.start) {
			let tempDate = moment(props.start, 'DD-MM-YYYY');
			setStart(tempDate);
		}
		else {
			setStart(null);
		}
		if (props.end) {
			let tempDate = moment(props.end, 'DD-MM-YYYY');
			setEnd(tempDate);
		}
		else {
			setEnd(null);
		}
	}, [props.start, props.end])

	/* ---- formatting date to yyyy-mm-dd ---*/
	const handleChange = (date, dateString) => {
		if (date) {
			// 	var tempStart = moment(date[0], 'YYYY-MM-DD');
			props.onDatesChange(dateString[0], dateString[1]);
		}
		else {

			props.onDatesChange(null, null);
		}
	}
	const disabledDate = (current) => {
		if (current.isAfter(moment())) { return true }
	}

	return (
		<div className="e-ant-daterange-picker">
			{/* <RangePicker
				onChange={handleChange}
				format='YYYY-MM-DD'
				value={[start, end]}
				placeholder={["yyyy-mm-dd", "yyyy-mm-dd"]}
				allowClear={true}
			/> */}
			<RangePicker
				format={dateFormat}
				placeholder={["dd-mm-yyyy", "dd-mm-yyyy"]}
				allowClear={true}
				value={[start, end]}
				onChange={(date, dateString) => { handleChange(date, dateString) }}
				disabledDate={disabledDate}
				onKeyDown={e => e.preventDefault()}

			/>
		</div>
	);
}

export default DateRange
