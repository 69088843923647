import $ from 'jquery';
import devconfig from '../config/config';

export default async function APIGet(url) {
    let token = localStorage.getItem('access_token');
    return await $.ajax({
        type: "GET",
        url: url,
        dataType: 'json',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        }
    })
        .then(function (response) { return response })
        .catch(function (response) {
            if (response.responseJSON && response.responseJSON.status_code === 401) {
                localStorage.clear();
                window.location.href = devconfig.AUTH_URL + '?expire=true';
            }
            else if (response.responseJSON) {
                return response.responseJSON;
            }
            else {
                let respond = { status: "error", message: "Something went wrong!" };
                return respond;
            }
        })
}
