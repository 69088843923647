/*
 *   File : line.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : line.js
 *   Integrations : 
 *   Version : v1.0
 */

/*import package*/
import React from 'react';
import Chart from "react-apexcharts";


class GradientChart extends React.Component {
    constructor(props) {

        super(props);
        // let images = this.props.images
        this.state = {
            series: [

                {
                    name: '',
                    type: 'area',
                    data: this.props.performace
                },
                {
                    name: '',
                    type: 'line',
                    data: this.props.target
                },
            ],
            options: {
                chart: {
                    height: 100,
                    type: 'line',

                },
                // dataLabels: {
                //     enabled: false
                // },
                stroke: {
                    width: [2, 2],
                    type: 'line',
                    dashArray: [0, 5],
                    // curve: 'straight',
                    colors: ["#4F7097", "#106e88"]
                },

                xaxis: {
                    categories: this.props.categories,
                    labels: {
                        show: true,
                        style: {
                            colors: ['#1E2F97'],
                            fontSize: '12px',
                            fontFamily: 'Mulish',
                            fontWeight: 500,
                            cssClass: 'apexcharts-xaxis-label',

                        },

                    },
                },
                title: {
                    text: '',
                    align: 'left',
                    style: {
                        fontSize: "16px",
                        color: '#666'
                    }
                },
                fill: {

                    type: 'gradient',
                    gradient: {
                        shadeIntensity: 1,
                        inverseColors: false,
                        opacityFrom: 0.45,
                        opacityTo: 0.05,
                        stops: [this.props.target[1], this.props.target[this.props.target.length - 1]]
                    },
                    colors: ["#106EE8",]
                },
                // colors: ["#106EE8",],

                yaxis: {
                    // show: true,
                    // showAlways: true,
                    // min: 1,
                    // // max: 6
                    // tickAmount: 1,
                    // tickPlacement: 'between',
                    // show: true,
                    axisBorder: {
                        show: true,
                        // color: '#000000',
                        // offsetX: 0,
                        // offsetY: 0
                    },

                    lines: {
                        show: true
                    },
                    labels: {
                        show: true,
                        type: 'area',
                        style: {
                            colors: ['#1E2F97'],
                            fontSize: '12px',
                            fontFamily: 'Mulish',
                            fontWeight: 500,
                            cssClass: 'apexcharts-yaxis-label',
                        },
                        // formatter: function (value) {
                        //   if (value < 6) {
                        //     return value + "L";
                        //   }
                        //   else {
                        //     return value + "Cr";
                        //   }

                        // }
                    },
                },
                grid: {
                    show: false,

                },
                markers: {
                    size: 1
                },
                dataLabels: {
                    type: 'area',
                    enabled: false,
                    textAnchor: 'start',
                    style: {
                        colors: ['#fff']
                    },
                    // formatter: function (val, opt) {
                    //     return <img src={images[opt.dataPointIndex]} alt="" />

                    // },
                    offsetX: 0,
                    dropShadow: {
                        enabled: true
                    }
                },

                tooltip: {
                    enabled: true,
                    enabledOnSeries: undefined,
                    shared: true,
                    followCursor: false,
                    intersect: false,
                    inverseOrder: false,
                    custom: undefined,
                    fillSeriesColor: false,
                    theme: false,

                    style: {
                        fontSize: '12px',
                        fontFamily: undefined,
                        colors: ["#106EE8", "#4F7097"]
                    },
                    onDatasetHover: {
                        highlightDataSeries: false,

                    },
                    x: {
                        show: false,
                        format: 'dd MMM',
                        formatter: undefined,
                    },
                    y: {
                        formatter: undefined,
                        title: {
                            formatter: (seriesName) => seriesName,
                        },
                    },
                    z: {
                        formatter: undefined,
                        title: 'Size: '
                    },
                    marker: {
                        show: false,
                    },
                    items: {
                        display: 'flex',
                    },
                    fixed: {
                        enabled: false,
                        position: 'topRight',
                        offsetX: 0,
                        offsetY: 0,
                    },
                }

            },


        };
    }



    render() {
        return (


            <div id="chart">
                <Chart options={this.state.options} series={this.state.series} type="line" height={300} />
            </div>

        );
    }
}


export default GradientChart;