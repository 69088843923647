/*
 *   File : accordion.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Accordion
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 09-12-2021
 */

/*import package*/
import React, { useState } from 'react';
import { Modal } from 'bootstrap';

/*import components*/
import { TextBtn } from '../../components/cta/index';
import { EditFundClass, Confirmation } from '../../components/modal/index';

/*import style*/
import styles from '../../styles/components/accordion.module.scss';

/*import assets*/
import Rename from '../../assets/images/dataFormating/rename.svg';
import Add from '../../assets/images/modal/add.svg';
import EditAllocation from '../modal/editAllocations';
import DeleteFunds from '../modal/deleteFunds';
import { useEffect } from 'react';

export default function Accordion(props) {

	const [edit, setEdit] = useState({});
	const [id, setId] = useState(0);
	const [ids, setIds] = useState([]);

	/* ------ open edit fund Modal -----*/
	const openModal = (item) => {
		setEdit(props.data[item])
		var myModal = new Modal(document.getElementById("editEquityFundClass"), {});
		myModal.show();
	}
	useEffect(()=>{
		console.log("hello", props)
	},[])
	return (
		<>
			<div className={`${styles.e_faq_accordion}`}>
				<div className="accordion" id="accordionDescription">
					<div className="row mx-0">
						{
							Object.keys(props.data).map((item, key) => {
								return (
									<div className={`${styles.e_accordion_sec} col-lg-12`}>
										<div className={`${styles.e_accordion_item} accordion-item`}>
											<h2 className={`${styles.e_accordion_header} accordion-header`} id={"collapseDescription" + key} >
												<button className={`${styles.e_accordion_button}`} type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${key}`} aria-expanded="false" aria-controls={`collapse${key}`} >
													<div className="row" onClick={() => { setId(key); }}>
														{
															key !== id ?
																<div className="row" >
																	<div className="col-lg-10" >
																		<span className={`${styles.e_accordion_title}`}>{item} </span>
																		<span className={`${styles.e_accordion_sub_title} ps-2`}>{props.data[item].reduce((a, b) => a + b.allocation, 0)}%</span>
																		{
																			key === id && !props.version ?
																				<>
																					<span onClick={() => openModal(item)} edit="true" className="ps-2"><img src={Rename} alt="" /></span>
																				</>
																				:
																				null
																		}
																	</div>
																	<div className="col-lg-2" >
																		<span className={`${styles.e_accordion_icon}`} ></span>
																	</div>
																</div>
																:
																null
														}
													</div>
													<div className="row" onClick={() => { setId(null); }}>
														{
															key === id ?
																<div className="row" >
																	<div className="col-lg-10" >
																		<span className={`${styles.e_accordion_title}`}>{item}</span>
																		<span className={`${styles.e_accordion_sub_title} ps-2`}>{props.data[item].reduce((a, b) => a + b.allocation, 0)}%</span>
																		{
																			key === id && !props.version ?
																				<>
																					<span onClick={() => openModal(item)} edit="true" className="ps-2"><img src={Rename} alt="" /></span>
																				</>
																				:
																				null
																		}
																	</div>
																	<div className="col-lg-2" >
																		<span className={`${styles.e_accordion_icon_minus}`} ></span>
																	</div>
																</div>
																:
																null
														}
													</div>
												</button>
											</h2>
											{
												key === id ?
													<>

														{
															props.data[item].map((fund) => {
																return (
																	<div  >
																		<div className={`${styles.e_accordion_body} accordion-body mt-3`}>
																			<div className={`${styles.e_accordion_wrapper} row pt-3`}>
																				{
																					item.mf_image ?
																						<span className={`${styles.e_accordion_content_img} col-lg-1`}><img src={fund.mf_image} alt="" /></span>
																						:
																						null
																				}
																				<div className={`${styles.e_accordion_content} col-lg-11`}>
																					<div className='d-flex'>
																						<div>{fund.name}</div>
																						<h6 style={{ paddingTop: "4px" }} className='mb-0 ps-2'>{fund.fund_alias ? `(${fund.fund_alias})` : null}</h6>
																					</div>
																					<h6 >Allocation: {fund.allocation}%</h6>
																				</div>
																			</div>

																		</div>
																	</div>
																)
															})
														}
														<div className="row mt-3">
															<div className="col-lg-9 text-start">
																{ !props.version && <TextBtn
																	left={true}
																	name="Change allocation"
																	className={`${styles.e_change} ms-1 me-3`}
																	toggle="modal"
																	target="#editAllocations"
																	handleClick={() => {
																		setEdit(props.data[item])
																	}}
																/>}
																{!props.version && <TextBtn
																	left={true}
																	name="Delete fund"
																	className={`${styles.e_delete}`}
																	toggle="modal"
																	target="#deleteAllocations"
																	handleClick={() => setEdit(props.data[item])}
																/>}
															</div>
															<div className='col-lg-3 text-end'>
																{!props.version && <TextBtn
																	left={true}
																	name="Add more fund"
																	image={Add}
																	className={`${styles.e_change} ms-1 me-3`}
																	toggle="modal"
																	target="#addFundClass"
																	handleClick={() => {
																		props.setEditedData(props.data[item])
																	}}
																/>}
															</div>
														</div>
													</>
													:
													null



											}
										</div>
									</div>
								)
							})
						}
					</div>
				</div>
			</div>
			{/*-------------- Change allocation modal -----------*/}

			<EditAllocation
				editedData={edit}
				// riskId={props.riskId}
				name={props.name}
				debt={false}
				index={props.index}
				updatedAllocation={(data) => { props.updatedFund(data) }}
				close={() => {
					// props.close()
					setEdit({});
				}}
			/>


			{/*-------------- edit fund class modal -----------*/}

			<EditFundClass
				editedData={edit}
				name={props.name}
				debt={false}
				updatedClass={(data) => { props.updatedFund(data) }}
				close={() => {
					setEdit({});
				}}
			/>


			{/* -------------- Add fund modal -----------
			<FundClass
				editedData={edit}
				updatedFund={(data) => { props.updatedFund(data) }}
				close={() => {
					setEdit({})
				}}
			/> */}

			<DeleteFunds
				close={() => {

				}}
				editedData={edit}
				selectedSchemes={(temp) => {
					setIds(temp);
					setTimeout(() => {
						var myModal = new Modal(document.getElementById("confirmationEquity"), {});
						myModal.show();
					}, 500)
				}} />

			{/*-------------- Delete fund modal -----------*/}

			<Confirmation
				title="fund"
				allocation_ids={ids}
				name={props.name}
				debt={false}
				content="Are you sure you want to delete this fund?"
				deleteFund={true}
				index={props.index}
				updatedFund={(data) => { props.updatedFund(data) }}

			/>

		</>
	);
}


