/*
 *   File : addGlobalClass.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Change Allocation Success Modal
 *   Integrations :29-04-2022
 *   Version : v1.0
 */

/*import packages*/
import React, { useState, useEffect } from 'react';
import $ from 'jquery';

/*import componets*/
import { Input } from '../input/index';
import { SecondaryBtn, PrimaryBtn } from '../cta/index';
import { Toast } from '../note';

/*import styles*/
import styles from '../../styles/components/risk.module.scss';
import devconfig from '../../config/config';
import APIPost from '../../services/postApi';

/*import services */


export default function AddAssetClass(props) {

    const [name, setName] = useState("");
    const [error, setError] = useState(null);
    const [loader, setLoader] = useState(false);
    const [order, setOrder] = useState("");
    const [color, setColor] = useState("");
    const [id, setId] = useState("");

    useEffect(() => {
        if (error !== null) {
            setTimeout(() => {
                setError(null)
            }, 3000)
        }
    }, [error]);

    useEffect(() => {
        if (Object.keys(props.data.length > 0)) {
            setName(props.data.name);
            setColor(props.data.color);
            setOrder(props.data.order);
            setId(props.data.id);
        }
    }, [props.data]);

    useEffect(() => {
        var myModalEl = document.getElementById('addAsset')
        myModalEl.addEventListener('hidden.bs.modal', (event) => {
            setName("");
            setOrder("");
            setColor("");
            props.closeAssetModal()
        })
        //eslint-disable-next-line
    }, [])

    /* add global class */
    const _addAssetClass = () => {
        setLoader(true);
        let url = devconfig.admin + '/asset-class/add'
        let data = JSON.stringify({
            "name": name,
            "order": order,
            "color": color
        });
        APIPost(url, data).then((response) => {
            if (response.status_code === 200) {
                if (response.status === true) {
                    $("#addAsset").trigger('click');
                    $(".btn-close").trigger('click');
                    props.addedClass(true);
                    setLoader(false);
                    setName("");
                    setOrder("");
                    setColor("");
                } else {
                    setError(response.message)
                    setLoader(false);
                }
            }
            else {
                setError(response.message);
                setLoader(false);
            }
        });
    }

    /* add global class */
    const _updateAssetClass = () => {
        setLoader(true);
        let url = devconfig.admin + '/asset-class/update'
        let data = JSON.stringify({
            "id": id,
            "name": name,
            "order": order,
            "color": color
        });
        APIPost(url, data).then((response) => {
            if (response.status_code === 200) {
                if (response.status === true) {
                    $("#addAsset").trigger('click');
                    $(".btn-close").trigger('click');
                    props.addedClass(true);
                    setLoader(false);
                } else {
                    setError(response.message)
                    setLoader(false);
                }
            }
            else {
                setError(response.message);
                setLoader(false);
            }
        });
    }

    return (
        <div className={`${styles.e_risk} modal fade`} id="addAsset" tabIndex="-1" aria-labelledby="success" aria-hidden="true">
            <div className={`${styles.e_modal_dialog} modal-dialog modal-dialog-centered`}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h6>Add new asset class</h6>
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            onClick={() => setName("")}>
                        </button>
                    </div>
                    <div className="container px-3">
                        <div className="row">
                            <div className="col-lg-12">
                                <label className='mb-2'>Asset class name </label>
                                <Input
                                    placeholder="Equity"
                                    value={name}
                                    valueChange={(value) => {
                                        setName(value)
                                    }}
                                    type="text"
                                />
                            </div>
                            <div className="col-lg-12 mt-3">
                                <label className='mb-2'>Order</label>
                                <Input
                                    placeholder="1"
                                    value={order}
                                    valueChange={(value) => {
                                        setOrder(value)
                                    }}
                                    type="text"
                                />
                            </div>
                            <div className="col-lg-12 mt-3">
                                <label className='mb-2'>Color </label>
                                <Input
                                    placeholder="#9999"
                                    value={color}
                                    valueChange={(value) => {
                                        setColor(value)
                                    }}
                                    type="text"
                                />
                            </div>
                        </div>
                        <div className="row pt-4 mt-2 pb-2">
                            <div className="col-lg-12">
                                <div className="d-flex justify-content-end">
                                    <SecondaryBtn
                                        name="Cancel"
                                        className={`${styles.e_cancel} e-cancel`}
                                        cancel="modal"
                                        handleClick={() => setName("")}
                                    />
                                    {
                                        name && order && color ?
                                            <PrimaryBtn
                                                name={props.update ? "Update" : "Submit"}
                                                className={loader ? "e-modal-submit e-btn-loader" : "e-modal-submit"}
                                                handleClick={() => props.update ? _updateAssetClass() : _addAssetClass()}
                                            />
                                            :
                                            <PrimaryBtn
                                                name={props.update ? "Update" : "Submit"}
                                                className='e-modal-submit e-disabled'
                                            />
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                error !== null ?
                    <Toast data={error} type="error" id="1" />
                    :
                    null
            }
        </div>
    );
}
