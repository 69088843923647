/*
 *   File : addFaq.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Success Modal
 *   Integrations : 05-101-2022
 *   Version : v1.0
 */

/*import packages*/
import React, { useEffect, useState } from 'react';
import $ from 'jquery'

/*import componets*/
import { Input, TextArea } from '../../components/input/index';
import { SecondaryBtn, PrimaryBtn, Toggle, PrimaryLink } from '../../components/cta/index';
import { Toast } from '../note';

/*import styles*/
import styles from '../../styles/components/addFaq.module.scss';

/* import services */
import devconfig from '../../config/config';
import APIPost from '../../services/postApi';
import SelectBox from '../dropdown/selectBox';
import APIGet from '../../services/getApi';

const ACCOUNT_TYPE_OPTIONS = [
    {
        label: "Current account",
        value: 50
    },
    {
        label: "Savings account",
        value: 43
    },
    {
        label: "NRO",
        value: 57
    },
    {
        label: "NRE",
        value: 58
    }
]

export default function UpdateBankDetail(props) {

    const [accountNumber, setAccountNumber] = useState("");
    const [name, setName] = useState("");
    const [isJointAcc, setIsJointAcc] = useState(false);
    const [loader, setLoader] = useState(false);
    const [success, setSuccess] = useState(null);
    const [error, setError] = useState(null);
    const [accountType, setAccountType] = useState(null);
    const [ifsc, setIfsc] = useState("");
    const [bankName, setBankName] = useState("");
    const [branchName, setBranchName] = useState("");


    useEffect(() => {
        if (error !== null) {
            setTimeout(() => {
                setError(null);
            }, 3000);
        }
    }, [error])


    useEffect(() => {
        if (success !== null) {
            setTimeout(() => {
                setSuccess(null);
            }, 3000);
        }
    }, [success])

    useEffect(() => {
        if (Object.keys(props.data).length > 0 && Object.values(props.data.bank).length > 0 && props.data.overview) {
            setAccountNumber(props.data.bank.account);
            setName(props.data.overview.name);
            setIfsc(props.data.bank.ifsc);
            setIsJointAcc(props.data.bank.is_joint_account);
            if (props.data.bank.bank_account_type) {

                let account_type = ACCOUNT_TYPE_OPTIONS.find((item) => item.label.toLocaleLowerCase().includes(props.data.bank.bank_account_type.toLocaleLowerCase()));
                setAccountType(account_type);
            }
            setBankName(props.data.bank.name);
            setBranchName(props.data.bank.branch);

        }
    }, [props.data]);


    const clearFields = () => {
        setAccountNumber("");

    }

    const updateBankDetail = (modal_id) => {
        setLoader(true);
        let url = devconfig.admin + "/user/update-bank";

        let data = JSON.stringify({
            "customer_id": Number(props.id),
            "account_number": accountNumber,
            "account_holder_name": name,
            "account_type": accountType.value,
            "ifsc": ifsc,
            "is_joint_account": isJointAcc,
            "branch_name": branchName,
            "bank_name": bankName
        })

        APIPost(url, data).then((response) => {
            if (response.status_code === 200) {
                setLoader(false);
                setSuccess("Bank detail updated successfully");
                setTimeout(() => {
                    $("#e-btn-close").trigger('click');
                    $(modal_id).trigger('click');
                    props.update()
                }, 500)
            } else {
                setError(response.message);
                setLoader(false);
            }
        })
    }
    return (
        <div className={`${styles.e_add_faq} modal fade`} id={"updateBankDetail"} tabIndex="-1" aria-labelledby="success" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h6>{"Update bank details"}</h6>
                        <button type="button" id="e-btn-close" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => clearFields()}></button>
                    </div>
                    <div className="container px-3">
                        <div className="row">
                            <div className="col-lg-12 mb-2">
                                <label>Account number</label>
                                <Input
                                    placeholder="Account number"
                                    value={accountNumber}
                                    type="number"
                                    valueChange={(value) => {
                                        setAccountNumber(value)
                                    }} />
                            </div>
                            <div className="col-lg-12 mb-2">
                                <label>Account holder name</label>
                                <Input
                                    placeholder="Account holder name"
                                    value={name}
                                    type="text"
                                    valueChange={(value) => {
                                        setName(value)
                                    }} />
                            </div>

                            <div className="col-lg-12 mb-2">
                                <label>Account type</label>
                                <SelectBox
                                    options={ACCOUNT_TYPE_OPTIONS}
                                    placeholder="Account holder name"
                                    value={accountType}
                                    type="number"
                                    selectchange={(value) => {
                                        setAccountType(value)
                                    }} />
                            </div>

                            <div className="col-lg-12 mb-2">
                                <label>IFSC</label>
                                <Input
                                    placeholder="IFSC"
                                    value={ifsc}
                                    type="text"
                                    valueChange={(value) => {
                                        setIfsc(value)
                                    }} />
                                <PrimaryLink name="Get Bank details"
                                    className="mt-1"
                                    handleClick={() => {
                                        window.open(`https://ifsc.razorpay.com/${ifsc}`)
                                    }} />
                            </div>

                            <div className="col-lg-12 mb-2">
                                <label>Bank</label>
                                <Input
                                    placeholder="Bank"
                                    value={bankName}
                                    type="text"
                                    valueChange={(value) => {
                                        setBankName(value)
                                    }} />
                            </div>

                            <div className="col-lg-12 mb-2">
                                <label>Branch</label>
                                <Input
                                    placeholder="Branch"
                                    value={branchName}
                                    type="text"
                                    valueChange={(value) => {
                                        setBranchName(value)
                                    }} />
                            </div>

                            <div className='col-lg-6 text-start pt-3 '>
                                <Toggle checked={isJointAcc} onChange={(e) => { setIsJointAcc(e.target.checked) }} />
                                <span className={`${styles.e_toggle_label} pt-3 ps-2`}>Joint account</span>
                            </div>
                        </div>

                        <div className="row mt-3 pb-2">
                            <div className="col-lg-12">
                                {

                                    <div className="d-flex justify-content-end">
                                        <SecondaryBtn
                                            name="Cancel"
                                            className="e-cancel"
                                            cancel="modal"
                                            handleClick={() => clearFields()} />
                                        {
                                            accountNumber && name && accountType && ifsc && bankName && branchName ?
                                                <PrimaryBtn name="Update"
                                                    className={loader ? "e-modal-submit e-btn-loader" : "e-modal-submit"}
                                                    handleClick={() => { updateBankDetail("#updateBankDetail") }} />
                                                :
                                                <PrimaryBtn name="Update" className="e-modal-submit e-disabled" />
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Error toast */}
            {
                error !== null ?
                    <Toast data={error} type="error" id="1" />
                    :
                    null
            }

            {/* success toast */}
            {
                success !== null ?
                    <Toast data={success} type="success" id="2" />
                    :
                    null
            }
        </div>
    )
}
