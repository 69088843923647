/*
 *   File : accordion.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Accordion
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 09-12-2021
 */

/*import package*/
import React, { useEffect, useState } from 'react';

/*import components*/
import { TextBtn } from '../../components/cta/index';
import { Toast } from '../note';
import { Input } from '../input';

/*import style*/
import styles from '../../styles/components/accordion.module.scss';

/* import services */
import devconfig from '../../config/config';
import APIPost from '../../services/postApi';

/*import assets*/

export default function AccordionAllocation(props) {
    const [id, setId] = useState(0);
    const [index, setIndex] = useState(1);
    //eslint-disable-next-line
    const [defensive, setDefensive] = useState("");
    const [allocObj, setAllocObj] = useState([])
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [loader, setLoader] = useState(false);


    useEffect(() => {
        if (error !== null) {
            setTimeout(() => {
                setError(null)
            }, 3000)
        }
    }, [error])

    useEffect(() => {
        if (success !== null) {
            setTimeout(() => {
                setSuccess(null)
            }, 3000)
        }
    }, [success])

    const updateAllocation = () => {

        setLoader(true);
        let temp = []
        allocObj.map((item) => {
            return (
                temp.push({
                    allocation: item.allocation !== null ? item.allocation : 0,
                    allocation_id: item.allocation_id,
                    style: item.style
                })
            )
        })
        let url = devconfig.admin + "/allocation/update";

        let data = JSON.stringify({
            // "allocation_id": allocObj.allocation_id,
            // "category_id": index,
            "is_equity": props.value === "equity" ? true : false,
            "allocations": temp
        })

        APIPost(url, data).then((response) => {
            if (response.status_code === 200) {
                setLoader(false);
                props.Update();
                setSuccess("Allocation updated successfully");
                setAllocObj([]);
            } else {
                setLoader(false);
                setError(response.message);
            }
        });
    }

    return (
        <div className={`${styles.e_allocation_accordion} e-allcation-accordion`}>
            <div className="accordion" id="accordionDescription">
                <div className="row mx-0">
                    <div className={`${styles.e_accordion_sec} col-lg-12`}>
                        <div className={`${styles.e_accordion_item} accordion-item`}>
                            <h2 className={`${styles.e_accordion_header} accordion-header`} id={"collapseDescription"} >
                                <button className={`${styles.e_accordion_button}`} type="button" data-bs-toggle="collapse" data-bs-target={`#collapse`} aria-expanded="false" aria-controls={`collapse`} >

                                    <div className="row" onClick={() => setId(props.id)}>
                                        {
                                            id !== props.id ?
                                                <>
                                                    <div className="col-lg-10" >
                                                        <span className={`${styles.e_accordion_title}`}>{props.title}</span>
                                                        {/* <span className={`${styles.e_accordion_sub_title} ps-2`}>{props.data[props.value][`${index}`].reduce((intial, final) => intial + final.allocation, 0)}%</span> */}
                                                    </div>
                                                    <div className="col-lg-2" >
                                                        <span className={`${styles.e_accordion_icon}`} ></span>
                                                    </div>
                                                </>
                                                :
                                                null
                                        }
                                    </div>
                                    <div className="row" onClick={() => { setId(null); }}>
                                        {
                                            id === props.id ?
                                                <div className="row" >
                                                    <div className="col-lg-10" >
                                                        <span className={`${styles.e_accordion_title}`}>{props.title}</span>
                                                        {/* <span className={`${styles.e_accordion_sub_title} ps-2`}>{props.data[props.value][`${index}`].reduce((intial, final) => intial + final.allocation, 0)}%</span> */}

                                                    </div>
                                                    <div className="col-lg-2" >
                                                        <span className={`${styles.e_accordion_icon_minus}`} ></span>
                                                    </div>
                                                </div>
                                                :
                                                null
                                        }
                                    </div>
                                </button>
                            </h2>

                            {
                                id === props.id ?
                                    <div  >
                                        <div className='row pt-3 mx-0'>
                                            <div className={`${styles.e_accordion_body_tab} col-lg-12`}>
                                                <div className='d-flex'>
                                                    {
                                                        props.data.tenure.sort((a, b) => a.id - b.id).map((item, tabKey) => {
                                                            return (
                                                                <span
                                                                    className={index === item.id ? `${styles.e_active_tab} me-4` : `${styles.e_tab} me-4`}
                                                                    onClick={() => {
                                                                        setIndex(item.id);
                                                                    }} key={tabKey}
                                                                >
                                                                    {item.title}
                                                                </span>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        <div className={`${styles.e_accordion_body} accordion-body `}>
                                            <div className={`${styles.e_accordion_wrapper} `}>
                                                <div className='row mt-3'>
                                                    {
                                                        props.data[props.value][`${index}`].map((item, key) => {
                                                            return (
                                                                <div className='col-lg-3 position-relative'>
                                                                    <label className='mb-2'>{item.style}</label>
                                                                    <Input
                                                                        value={item.allocation}
                                                                        placeholder="20"
                                                                        suffix="%"
                                                                        type="number"
                                                                        valueChange={(value) => {
                                                                            setDefensive(item.allocation)
                                                                            let temp = props.data[props.value]
                                                                            if (value === "") {
                                                                                temp[`${index}`][key]["allocation"] = null

                                                                            } else {
                                                                                temp[`${index}`][key]["allocation"] = parseFloat(value)
                                                                            }
                                                                            props.setData(temp)
                                                                            setAllocObj(temp[index])
                                                                        }}
                                                                    />
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                            <div className="row mt-3">
                                                <div className="col-lg-12 text-start">
                                                    {/* {
                                                        props.data[props.value][`${index}`].reduce((intial, final) => intial + final.allocation, 0) === 100 ? */}
                                                    {
                                                        (allocObj).length > 0 ?

                                                            <TextBtn
                                                                left={true}
                                                                name="Save"
                                                                className={loader ? `${styles.e_save} ms-3 me-3 e-disabled` : `${styles.e_save} ms-3 me-3`}
                                                                handleClick={() => updateAllocation()}
                                                            />
                                                            :
                                                            <TextBtn
                                                                left={true}
                                                                name="Save"
                                                                className={`${styles.e_save} ms-3 me-3 e-disabled`}
                                                            />
                                                    }
                                                    <TextBtn
                                                        left={true}
                                                        name="Cancel"
                                                        className={`${styles.e_cancel}`}
                                                        handleClick={() => {
                                                            props.Update();
                                                            setAllocObj([]);
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    :
                                    null
                            }
                        </div>
                    </div>

                </div>
            </div>
            {/* Error toast */}
            {
                error !== null ?
                    <Toast data={error}
                        type="error"
                        id="1" />
                    :
                    null
            }

            {/* Success toast */}
            {
                success !== null ?
                    <Toast data={success}
                        type="success"
                        id="2" />
                    :
                    null
            }
        </div >
    );
}


