/*
 *   File : accordion.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Accordion
 *   Integrations : 12-09-2022
 *   Version : 1.0.0
 *   Created : 09-12-2021
 */

/*import package*/
import React, { useEffect, useState } from 'react';

/*import components*/
import { PrimaryLink } from '../../components/cta/index';
import { Input } from '../input';
import { Toast } from '../note';

/*import services*/
import devconfig from '../../config/config';
import APIGet from '../../services/getApi';
import APIPost from '../../services/postApi';

/*import style*/
import styles from '../../styles/components/accordion.module.scss';
import { Loader } from '../loader';
import FundsTab from './fundsTab';

/*import assets*/

/* --- accordion data ---*/


const FD_DATA = [
    {
        name: "Better than fixed deposit",
        id: 3,

    }
]


/* ---- investment types for goals ----*/
const INVESTMENT_TYPE_TABS = [
    {
        id: 2,
        displayName: "Balance"
    },
    {
        id: 3,
        displayName: "Moderate"
    },
    {
        id: 4,
        displayName: "Aggressive"
    },
]

/* ---- year tabs for goals ----*/
const YEAR_TABS = [
    { title: "1 year", id: 1 },
    { title: "2 years", id: 2 },
    { title: "3 years", id: 3 },
    { title: "4 years", id: 4 },
    { title: "5 years", id: 5 },
    { title: "6 years", id: 6 },
]
export default function ReturnsAccordion(props) {
    const [id, setId] = useState(1);
    // const [data, setData] = useState([])
    const [goalData, setGoalData] = useState({});
    const [homeData, setHomeData] = useState([])
    const [data2, setData2] = useState(FD_DATA);
    const [eqEnabled, setEqEnabled] = useState(false);
    const [homeEnabled, setHomeEnabled] = useState("");
    const [fdEnabled, setFdEnabled] = useState("");
    const [success, setSuccess] = useState(null);
    const [error, setError] = useState(null);
    const [loader, setLoader] = useState(false);
    // const [dataLoader, setDataLoader] = useState(false);
    const [apiLoader, setApiLoader] = useState(false);
    const [index, setIndex] = useState(2);
    const [year, setYear] = useState(1);
    const [goalFd, setGoalFd] = useState(0)
    const [goalNifty, setGoalNifty] = useState(0)
    const [goalMira, setGoalMira] = useState(0)
    const [goalReturns, setGoalReturns] = useState("")


    useEffect(() => {
        //api call
        _getReturns(); //api call
        //eslint-disable-next-line
    }, []);

    // useEffect(() => {
    //     getYearFilter();
    // }, [homeData])

    /* set success null to remove success toast*/
    useEffect(() => {
        if (success !== null) {
            setTimeout(() => {
                setSuccess(null)
            }, 3000);
        }
    }, [success]);

    /* set error null to remove toast*/
    useEffect(() => {
        if (error !== null) {
            setTimeout(() => {
                setError(null)
            }, 3000);
        }
    }, [error]);

    /* values of goals from api */
    useEffect(() => {
        if (Object.values(goalData).length > 0) {
            setGoalFd(goalData[index][year].fd)
            setGoalNifty(goalData[index][year].nifty)
            setGoalMira(goalData[index][year].mira)
            setGoalReturns(goalData[index][year].annual_returns !== null ? goalData[index][year].annual_returns : "")
        }
    }, [goalData, index, year])

    //enabling equity fields
    const _enableEquity = () => {
        setEqEnabled(true);
        document.getElementById("eqFd").disabled = false
        document.getElementById("eqNifty").disabled = false
        document.getElementById("eqMira").disabled = false
    }

    //enabling home page fields
    const _enableHomeFields = (index) => {
        setHomeEnabled(index)
        document.getElementById(`fd${index}`).disabled = false
        document.getElementById(`nifty${index}`).disabled = false
        document.getElementById(`mira${index}`).disabled = false
    }

    //enabling fd fields
    const _enableFdFields = (index) => {
        setFdEnabled(index)
        document.getElementById(`fd${index}`).disabled = false
        // document.getElementById(`nifty${index}`).disabled = false
        document.getElementById(`mira${index}`).disabled = false
        document.getElementById(`returns${index}`).disabled = false
    }

    /* ----- save goal api ---*/

    const _saveGoal = () => {
        setLoader(true);
        let url = devconfig.admin + "/returns/goal";
        let data = JSON.stringify({
            "investment_style": index,
            "year": year,
            "returns": {
                "fd": goalFd ? parseFloat(goalFd) : 0,
                "mira": goalMira ? parseFloat(goalMira) : 0,
                "nifty": goalNifty ? parseFloat(goalNifty) : 0
            },
            "annual_returns": goalReturns === "" || goalReturns === null ? 0 : goalReturns
        })
        APIPost(url, data).then((response) => {
            if (response.status_code === 200) {
                setEqEnabled(false);
                setLoader(false);
                setSuccess("Goals updated successfully");
                _getReturns()
            } else {
                setLoader(false);
                setError(response.message);
            }
        });
    }

    /* ----- update FD api ---*/

    const _saveFd = (data) => {
        setLoader(true);
        let url = devconfig.admin + "/returns/fd";
        let api_data = JSON.stringify({
            "year": data.year,
            "fd": data.fd ? parseFloat(data.fd) : 0,
            "nifty": parseFloat(data.nifty),
            "mira": data.mira ? parseFloat(data.mira) : 0,
            "annual_returns": data.annual_returns === "" || data.annual_returns === null ? 0 : data.annual_returns
        })

        APIPost(url, api_data).then((response) => {
            if (response.status_code === 200) {
                setFdEnabled("");
                setLoader(false);
                setSuccess("FD updated successfully");
                _getReturns()
            } else {
                setLoader(false);
                setError(response.message);
            }
        });
    }

    /* ----- get all returns ---*/

    const _getReturns = () => {
        setApiLoader(true)
        let url = devconfig.admin + "/returns/get";

        APIGet(url).then((response) => {
            if (response.status_code === 200) {
                setApiLoader(false)
                setGoalData(response.data.goal)
                setHomeData(response.data.home)
                let ele = []
                let tempFd = []

                let element = Object.keys(response.data.fd)
                Object.values(response.data.fd).map((item, key) => {
                    return (
                        tempFd.push({
                            year: element[key],
                            fd: item.fd,
                            nifty: item.nifty,
                            mira: item.mira,
                            annual_returns: item.annual_returns
                        })
                    )

                })
                ele.push({ name: "Better than FD", id: 3, values: tempFd })
                setData2(ele);

                let tempEle = []
                let tempHome = []

                let homeElement = Object.keys(response.data.home)
                Object.values(response.data.home).map((item, key) => {
                    return (
                        tempHome.push({
                            year: homeElement[key],
                            fd: item.fd,
                            nifty: item.nifty,
                            mira: item.mira,
                        })
                    )

                })
                tempEle.push({ name: "Home page returns", id: 1, values: tempHome })
                setHomeData(tempEle)
            } else {
                setApiLoader(false)
            }
        });
    }

    /* ----- save home page returns ---*/

    const _saveHomepageReturns = (data) => {
        setLoader(true);
        let url = devconfig.admin + "/returns/update";
        let request = JSON.stringify({
            "year": data.year,
            "fd": data.fd ? parseFloat(data.fd) : 0,
            "nifty": data.nifty ? parseFloat(data.nifty) : 0,
            "mira": data.mira ? parseFloat(data.mira) : 0
        })

        APIPost(url, request).then((response) => {
            if (response.status_code === 200) {
                setHomeEnabled("");
                setLoader(false);
                setSuccess("Home page returns updated successfully");
                _getReturns();
            } else {
                setLoader(false);
                setError(response.message);
            }
        });
    }

    return (
        <div className={`${styles.e_returns_accordion} e-allcation-accordion`}>
            <div className="accordion" id="accordionDescription">
                {
                    apiLoader ?
                        <Loader />
                        :
                        <>
                            {/*----------home page section----*/}
                            <div className="row mx-0">
                                {
                                    homeData && homeData.length && homeData?.map((item, key) => {
                                        return (
                                            <div className={`${styles.e_accordion_sec} col-lg-12`}>
                                                <div className={`${styles.e_accordion_item} accordion-item`}>
                                                    <h2 className={`${styles.e_accordion_header} accordion-header`} id={"collapseDescription" + key} >
                                                        <button className={`${styles.e_accordion_button}`} type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${key}`} aria-expanded="false" aria-controls={`collapse${key}`} >
                                                            <div className="row" onClick={() => { setId(item.id); }}>
                                                                {
                                                                    item.id !== id ?
                                                                        <div className="row" >
                                                                            <div className="col-lg-10" >
                                                                                <span className={`${styles.e_accordion_title}`}>{item.name}</span>
                                                                            </div>
                                                                            <div className="col-lg-2" >
                                                                                <span className={`${styles.e_accordion_icon}`} ></span>
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        null
                                                                }
                                                            </div>
                                                            <div className="row" onClick={() => { setId(null); }}>
                                                                {
                                                                    item.id === id ?
                                                                        <div className="row" >
                                                                            <div className="col-lg-10" >
                                                                                <span className={`${styles.e_accordion_title}`}>{item.name}</span>
                                                                            </div>
                                                                            <div className="col-lg-2" >
                                                                                <span className={`${styles.e_accordion_icon_minus}`} ></span>
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        null
                                                                }
                                                            </div>
                                                        </button>
                                                    </h2>
                                                    {
                                                        item.id === id ?

                                                            <div className={`${styles.e_accordion_body} accordion-body `}>
                                                                <div className={`${styles.e_accordion_wrapper} `}>
                                                                    {
                                                                        item.values.map((itemValue, index) => {
                                                                            return (
                                                                                <div className='row mt-3'>
                                                                                    <div className='col-lg-3 col-3 position-relative'>
                                                                                        <label className='mb-2'>Year/CAGR%</label>
                                                                                        <Input
                                                                                            value={itemValue.year}
                                                                                            disabled={true}
                                                                                            placeholder="20"

                                                                                        />
                                                                                    </div>

                                                                                    <div className='col-lg-2 col-3 position-relative'>
                                                                                        <label className='mb-2'>FD%</label>
                                                                                        <Input
                                                                                            value={itemValue.fd}
                                                                                            placeholder="20"
                                                                                            id={`fd${index}`}
                                                                                            type="number"
                                                                                            disabled={index !== homeEnabled ? true : false}
                                                                                            valueChange={(value) => {
                                                                                                let tempArray = [...homeData]
                                                                                                tempArray[key].values[index]["fd"] = value
                                                                                                setHomeData(tempArray)
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                    <div className='col-lg-2 col-3 position-relative'>
                                                                                        <label className='mb-2'>Nifty%</label>
                                                                                        <Input
                                                                                            value={itemValue.nifty}
                                                                                            id={`nifty${index}`}
                                                                                            placeholder="20"
                                                                                            type="number"
                                                                                            disabled={index !== homeEnabled ? true : false}
                                                                                            valueChange={(value) => {
                                                                                                let tempArray = [...homeData]
                                                                                                tempArray[key].values[index]["nifty"] = value
                                                                                                setHomeData(tempArray)
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                    <div className='col-lg-2 col-3 position-relative'>
                                                                                        <label className='mb-2'>MIRA%</label>
                                                                                        <Input
                                                                                            value={itemValue.mira}
                                                                                            placeholder="20"
                                                                                            type="number"
                                                                                            id={`mira${index}`}
                                                                                            disabled={index !== homeEnabled ? true : false}
                                                                                            valueChange={(value) => {
                                                                                                let tempArray = [...homeData]
                                                                                                tempArray[key].values[index]["mira"] = value
                                                                                                setHomeData(tempArray)
                                                                                            }}
                                                                                        />
                                                                                    </div>

                                                                                    <div className='col-lg-1 col-12 d-flex justify-content-end mt-4 pe-0'>
                                                                                        {
                                                                                            index !== homeEnabled ?
                                                                                                <PrimaryLink
                                                                                                    name="Edit"
                                                                                                    className={styles.e_edit}
                                                                                                    handleClick={() => _enableHomeFields(index)} />
                                                                                                :
                                                                                                itemValue.fd >= 0 && itemValue.mira >= 0 && itemValue.nifty >= 0 ?
                                                                                                    <PrimaryLink
                                                                                                        name="Save"
                                                                                                        className={loader ? `${styles.e_edit} e-butn-loader` : styles.e_edit}
                                                                                                        handleClick={() => _saveHomepageReturns(itemValue)} />
                                                                                                    :
                                                                                                    <PrimaryLink
                                                                                                        name="Save"
                                                                                                        className={"e-disabled"}
                                                                                                    />
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                            :
                                                            null
                                                    }
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>

                            {/*----------home page section end----*/}

                            {/*----------goal section----*/}

                            <div className="row mx-0">
                                <div className={`${styles.e_accordion_sec} col-lg-12`}>
                                    <div className={`${styles.e_accordion_item} accordion-item`}>
                                        <h2 className={`${styles.e_accordion_header} accordion-header`} id={"collapseDescription"} >
                                            <button className={`${styles.e_accordion_button}`} type="button" data-bs-toggle="collapse" data-bs-target={`#collapse`} aria-expanded="false" aria-controls={`collapse`} >
                                                <div className="row" onClick={() => { setId(2); }}>
                                                    {
                                                        id !== 2 ?
                                                            <div className="row" >
                                                                <div className="col-lg-10" >
                                                                    <span className={`${styles.e_accordion_title}`}>Goals</span>
                                                                </div>
                                                                <div className="col-lg-2" >
                                                                    <span className={`${styles.e_accordion_icon}`} ></span>
                                                                </div>
                                                            </div>
                                                            :
                                                            null
                                                    }
                                                </div>
                                                <div className="row" onClick={() => { setId(null); }}>
                                                    {
                                                        id === 2 ?
                                                            <div className="row" >
                                                                <div className="col-lg-10" >
                                                                    <span className={`${styles.e_accordion_title}`}>Goals</span>
                                                                </div>
                                                                <div className="col-lg-2" >
                                                                    <span className={`${styles.e_accordion_icon_minus}`} ></span>
                                                                </div>
                                                            </div>
                                                            :
                                                            null
                                                    }
                                                </div>
                                            </button>
                                        </h2>
                                        {
                                            id === 2 ?
                                                <>
                                                    <div className={`${styles.e_accordion_body} accordion-body `}>
                                                        <div className={`${styles.e_accordion_wrapper} `}>
                                                            <div className='col-lg-12 e-funds-tab'>
                                                                <FundsTab
                                                                    data={INVESTMENT_TYPE_TABS}
                                                                    index={index}
                                                                    funds={true}
                                                                    setTab={(temp, name) => {
                                                                        setIndex((temp));

                                                                    }} />
                                                            </div>
                                                            <div className='row pt-3 mx-0'>
                                                                <div className={`${styles.e_accordion_body_tab} col-lg-12 ps-0 pt-3`}>
                                                                    <div className='d-flex'>
                                                                        {
                                                                            YEAR_TABS.map((item, tabKey) => {
                                                                                return (
                                                                                    <span
                                                                                        className={year === item.id ? `${styles.e_active_tab} me-4` : `${styles.e_tab} me-4`}
                                                                                        onClick={() => {
                                                                                            setYear(item.id);
                                                                                        }} key={tabKey}
                                                                                    >
                                                                                        {item.title}
                                                                                    </span>
                                                                                )
                                                                            })
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <>
                                                                <div className='row mt-3'>
                                                                    <div className='col-lg-2 col-3 position-relative'>
                                                                        <label className='mb-2'>FD%</label>
                                                                        <Input
                                                                            value={goalFd}
                                                                            placeholder="20"
                                                                            id={`eqFd`}
                                                                            type="number"
                                                                            disabled={!eqEnabled}
                                                                            valueChange={(value) => {
                                                                                setGoalFd((value))
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div className='col-lg-2 col-3 position-relative'>
                                                                        <label className='mb-2'>Nifty%</label>
                                                                        <Input
                                                                            value={goalNifty}
                                                                            placeholder="20"
                                                                            disabled={!eqEnabled}
                                                                            id={`eqNifty`}
                                                                            type="number"
                                                                            valueChange={(value) => {
                                                                                setGoalNifty((value))
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div className='col-lg-2 col-3 position-relative'>
                                                                        <label className='mb-2'>MIRA%</label>
                                                                        <Input
                                                                            value={goalMira}
                                                                            placeholder="20"
                                                                            disabled={!eqEnabled}
                                                                            id={`eqMira`}
                                                                            type="number"
                                                                            valueChange={(value) => {
                                                                                setGoalMira((value))
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div className='col-lg-2 col-3 position-relative'>
                                                                        <label className='mb-2'>Yearly returns</label>
                                                                        <Input
                                                                            value={goalReturns}
                                                                            placeholder="20"
                                                                            disabled={!eqEnabled}
                                                                            id={`eqMira`}
                                                                            type="text"
                                                                            valueChange={(value) => {
                                                                                setGoalReturns((value))
                                                                            }}
                                                                        />
                                                                    </div>

                                                                    <div className='col-lg-1 col-12 d-flex justify-content-end mt-4 pt-2 pe-0'>
                                                                        {
                                                                            !eqEnabled ?
                                                                                <PrimaryLink
                                                                                    name="Edit"
                                                                                    className={`${styles.e_edit}`}
                                                                                    handleClick={() => _enableEquity()} />
                                                                                :
                                                                                goalFd >= 0 && goalNifty >= 0 && goalMira >= 0 ?
                                                                                    <PrimaryLink
                                                                                        name="Save"
                                                                                        className={loader ? `${styles.e_edit} e-butn-loader` : `${styles.e_edit}`}
                                                                                        handleClick={() => _saveGoal()} />
                                                                                    :
                                                                                    <PrimaryLink
                                                                                        name="Save"
                                                                                        className={"e-disabled"}
                                                                                    />

                                                                        }
                                                                    </div>
                                                                </div>

                                                            </>
                                                        </div>
                                                    </div>
                                                </>
                                                :
                                                null
                                        }
                                    </div>
                                </div>
                            </div>
                            {/*----------goal section end----*/}

                            {/*----------FD section----*/}
                            <div className="row mx-0">
                                {
                                    data2 && data2.length && data2.map((item, key) => {
                                        return (
                                            <div className={`${styles.e_accordion_sec} col-lg-12`}>
                                                <div className={`${styles.e_accordion_item} accordion-item`}>
                                                    <h2 className={`${styles.e_accordion_header} accordion-header`} id={"collapseDescription" + key} >
                                                        <button className={`${styles.e_accordion_button}`} type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${key}`} aria-expanded="false" aria-controls={`collapse${key}`} >
                                                            <div className="row" onClick={() => { setId(item.id); }}>
                                                                {
                                                                    item.id !== id ?
                                                                        <div className="row" >
                                                                            <div className="col-lg-10" >
                                                                                <span className={`${styles.e_accordion_title}`}>{item.name}</span>
                                                                            </div>
                                                                            <div className="col-lg-2" >
                                                                                <span className={`${styles.e_accordion_icon}`} ></span>
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        null
                                                                }
                                                            </div>
                                                            <div className="row" onClick={() => { setId(null); }}>
                                                                {
                                                                    item.id === id ?
                                                                        <div className="row" >
                                                                            <div className="col-lg-10" >
                                                                                <span className={`${styles.e_accordion_title}`}>{item.name}</span>
                                                                            </div>
                                                                            <div className="col-lg-2" >
                                                                                <span className={`${styles.e_accordion_icon_minus}`} ></span>
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        null
                                                                }
                                                            </div>
                                                        </button>
                                                    </h2>
                                                    {
                                                        item.id === id ?

                                                            <div className={`${styles.e_accordion_body} accordion-body `}>
                                                                <div className={`${styles.e_accordion_wrapper} `}>

                                                                    {
                                                                        item.values.sort((a, b) => a.year - b.year).map((itemValue, index) => {
                                                                            return (
                                                                                <div className='row mt-3'>
                                                                                    <div className='col-lg-3 col-3 position-relative'>
                                                                                        <label className='mb-2'>Year/CAGR%</label>
                                                                                        <Input
                                                                                            value={itemValue.year}
                                                                                            placeholder="20"
                                                                                            disabled={true}
                                                                                        // valueChange={(value) => {
                                                                                        //     let tempArray = [...data2]
                                                                                        //     tempArray[key].values[index]["cagr"] = value
                                                                                        //     setData(tempArray)
                                                                                        // }}
                                                                                        />
                                                                                    </div>

                                                                                    <div className='col-lg-2 col-3 position-relative'>
                                                                                        <label className='mb-2'>FD%</label>
                                                                                        <Input
                                                                                            value={itemValue.fd}
                                                                                            id={`fd${index}`}
                                                                                            disabled={index !== fdEnabled ? true : false}
                                                                                            placeholder="20"
                                                                                            type="number"
                                                                                            valueChange={(value) => {
                                                                                                let tempArray = [...data2]
                                                                                                tempArray[key].values[index]["fd"] = value
                                                                                                setData2(tempArray)
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                    {/* <div className='col-lg-2 col-3 position-relative'>
                                                                                        <label className='mb-2'>Nifty%</label>
                                                                                        <Input
                                                                                            value={itemValue.nifty}
                                                                                            placeholder="20"
                                                                                            id={`nifty${index}`}
                                                                                            type="number"
                                                                                            disabled={index !== fdEnabled ? true : false}
                                                                                            valueChange={(value) => {
                                                                                                let tempArray = [...data2]
                                                                                                tempArray[key].values[index]["nifty"] = value
                                                                                                setData2(tempArray)
                                                                                            }}
                                                                                        />
                                                                                    </div> */}
                                                                                    <div className='col-lg-2 col-3 position-relative'>
                                                                                        <label className='mb-2'>MIRA%</label>
                                                                                        <Input
                                                                                            value={itemValue.mira}
                                                                                            placeholder="20"
                                                                                            id={`mira${index}`}
                                                                                            type="number"
                                                                                            disabled={index !== fdEnabled ? true : false}
                                                                                            valueChange={(value) => {
                                                                                                let tempArray = [...data2]
                                                                                                tempArray[key].values[index]["mira"] = value
                                                                                                setData2(tempArray)
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                    <div className='col-lg-2 col-3 position-relative'>
                                                                                        <label className='mb-2'>Yearly returns</label>
                                                                                        <Input
                                                                                            value={itemValue.annual_returns}
                                                                                            placeholder="20"
                                                                                            id={`returns${index}`}
                                                                                            // type="text"
                                                                                            disabled={index !== fdEnabled ? true : false}
                                                                                            valueChange={(value) => {
                                                                                                let tempArray = [...data2]
                                                                                                tempArray[key].values[index]["annual_returns"] = value
                                                                                                setData2(tempArray)
                                                                                            }}
                                                                                        />
                                                                                    </div>

                                                                                    <div className='col-lg-1 col-12 d-flex justify-content-end mt-4 pe-0'>
                                                                                        {
                                                                                            index !== fdEnabled ?
                                                                                                <PrimaryLink
                                                                                                    name="Edit"
                                                                                                    className={styles.e_edit}
                                                                                                    handleClick={() => _enableFdFields(index)} />
                                                                                                :
                                                                                                itemValue.fd >= 0 && itemValue.mira >= 0 ?
                                                                                                    <PrimaryLink
                                                                                                        name="Save"
                                                                                                        className={styles.e_edit}
                                                                                                        handleClick={() => _saveFd(itemValue)} />
                                                                                                    :
                                                                                                    <PrimaryLink
                                                                                                        name="Save"
                                                                                                        className={"e-disabled"}
                                                                                                    />
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>

                                                            :
                                                            null
                                                    }
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            {/*----------FD section end----*/}
                        </>
                }
            </div>
            {/*----------success toast----*/}
            {
                success !== null ?
                    <Toast data={success}
                        type="success"
                        id="2"
                    />
                    :
                    null
            }

            {/*----------error toast----*/}
            {
                error !== null ?
                    <Toast data={error}
                        type="error"
                        id="1"
                    />
                    :
                    null
            }
        </div>
    );
}


