/*
 *   File : changeAllocation.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Change Allocation Success Modal
 *   Integrations :14-01-2022
 *   Version : v1.0
 */

/*import packages*/
import React, { useEffect, useState } from 'react';
import $ from 'jquery';

/*import componets*/
import { Datepicker, Input } from '../../components/input/index';
import { SecondaryBtn, PrimaryBtn } from '../../components/cta/index';
import { Toast } from '../note';

/*import styles*/
import styles from '../../styles/components/risk.module.scss';


/*import services */
import devconfig from '../../config/config';
import APIPost from '../../services/postApi';

export default function EditMaturity(props) {

    const [allocation, setAllocation] = useState(null);
    const [date, setDate] = useState(null);
    const [id, setId] = useState(null);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [loader, setLoader] = useState(false);
    const [schemeCode, setSchemeCode] = useState(null);
    const [assetClass, setAssetClass] = useState("");
    const [fundAlias, setFundAlias] = useState("");

    useEffect(() => {
        var myModalEl = document.getElementById("editMaturity")
        myModalEl.addEventListener('hidden.bs.modal', (event) => {
            props.close()
        })
        //eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (Object.keys(props.editedData).length > 0) {
            setId(props.editedData.scheme_id);
            setAllocation(parseFloat(props.editedData.ytm));
            setDate(props.editedData.mature_date);
            setSchemeCode(props.editedData.mutual_fund.scheme_code);
            setAssetClass(props.editedData.asset_class);
            setFundAlias(props.editedData.fund_alias);
        }
    }, [props.editedData]);

    useEffect(() => {
        if (error !== null) {
            setTimeout(() => {
                setError(null);
            }, 4000);
        }
    }, [error]);

    useEffect(() => {
        if (success !== null) {
            setTimeout(() => {
                setSuccess(null);
            }, 4000);
        }
    }, [success])

    /*-----update ytm---- */
    const updateMaturityFund = () => {
        setLoader(true);
        let url = devconfig.admin + '/fund/tm-manage'
        let data = JSON.stringify({
            "id": id,
            "scheme_code": schemeCode,
            "maturity_date": date,
            "ytm": allocation,
            "asset_class": assetClass,
            "fund_alias": fundAlias
        });
        APIPost(url, data).then((response) => {
            if (response.status_code === 200) {
                setSuccess("Fund updated successfully");
                setLoader(false);
                setTimeout(() => {
                    $('#editMaturity').trigger('click');
                    props.updatedFund(true)
                }, 1000)

            } else {
                setError(response.message);
                setLoader(false);
            }
        })

    }

    return (
        <>
            <div className={`${styles.e_risk} modal fade`} id={"editMaturity"} tabIndex="-1" aria-labelledby="success" aria-hidden="true">
                <div className={`${styles.e_modal_dialog} modal-dialog modal-dialog-centered`}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h6>Change</h6>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" ></button>
                        </div>
                        <div className="container px-3">
                            <div className="row">
                                <div className='col-lg-12 mb-3'>
                                    <label className='mb-1'>Asset class</label>
                                    <Input
                                        placeholder="Eg: Indian equities"
                                        value={assetClass}
                                        type="text"
                                        valueChange={(value) => {
                                            setAssetClass(value)
                                        }}
                                    />
                                </div>
                                <div className="col-lg-12 mb-3">
                                    <label>Maturity date</label>
                                    <Datepicker
                                        placeholder="Select the date"
                                        value={date}
                                        onChange={(value) => {

                                            setDate(value)
                                        }}
                                    />
                                </div>
                                <div className="col-lg-12 mb-3">
                                    <label>YTM (%)</label>
                                    <Input
                                        placeholder="Enter the percentage"
                                        value={allocation}
                                        valueChange={(value) => {
                                            setAllocation(parseFloat(value))
                                        }}
                                        type="number"
                                    />
                                </div>
                                <div className='col-lg-12'>
                                    <label className='mb-1'>Fund alias</label>
                                    <Input
                                        placeholder="Eg: Indian equities"
                                        value={fundAlias}
                                        type="text"
                                        valueChange={(value) => {
                                            setFundAlias(value)
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row pt-4 mt-2 pb-2">
                                <div className="col-lg-12">
                                    <div className="d-flex justify-content-end">
                                        <SecondaryBtn
                                            name="Cancel"
                                            className={`${styles.e_cancel} e-cancel`}
                                            cancel="modal"
                                            handleClick={() => setAllocation("")}
                                        />
                                        {
                                            allocation && date && assetClass && fundAlias ?
                                                <PrimaryBtn
                                                    name="Change"
                                                    className={loader ? "px-4 e-btn-loader" : "px-4"}
                                                    handleClick={() => updateMaturityFund()}
                                                />
                                                :
                                                <PrimaryBtn
                                                    name="Change"
                                                    className='px-4 e-disabled'
                                                />
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            {/* Error toast */}
            {
                error !== null ?
                    <Toast data={error}
                        type="error"
                        id="1" />
                    :
                    null
            }

            {/* success toast */}
            {
                success !== null ?
                    <Toast data={success}
                        type="success"
                        id="2" />
                    :
                    null
            }
        </>
    );
}
