/*
*   File : index.js
*   Author URI : https://evoqueinnovativelab.com
*   Description : Index file for Notification container;
 *   Integrations : 23-01-2023
 *   Version : v1.0
 *   Created : 17-10-2022	
*/

/*import packages*/
import { useEffect, useState } from 'react';
import $ from 'jquery';
import { Modal } from 'bootstrap';

import Preview from '../../assets/images/banner/preview.svg';
import LoaderImg from '../../assets/images/loader/mintd-loader.gif'
import Delete from '../../assets/images/modal/delete.svg';

/*import components*/
import { FileUpload, Input, Radio, TextArea } from '../../components/input/index';
import { PrimaryBtn, SecondaryBtn } from '../../components/cta/index';
import { Toast } from '../../components/note';
import { Editor } from '../../components/input/index';
import { NotificationConfirm } from '../../components/modal';
import SelectBox from '../../components/dropdown/selectBox';

/* import services */
import devconfig from '../../config/config';
import APIPost from '../../services/postApi';

const DEVICE_OPTIONS = [
	{
		label: "Google Android", value: 1
	},
	{
		label: "iOS", value: 2
	}
]

const Index = () => {

	const [title, setTitle] = useState("");
	const [content, setContent] = useState("");
	const [error, setError] = useState(null);
	const [success, setSuccess] = useState(null);
	// const [notificationData, setNotificationData] = useState([]);
	// const [tabIndex, setTabindex] = useState(1);
	// const [loader, setLoader] = useState(true);
	const [loader, setLoader] = useState(false);
	const [imageUrl, setImageUrl] = useState("");
	const [imageName, setImageName] = useState("");
	const [fileUpload, setFileUpload] = useState(false);
	const [previewUrl, setPreviewUrl] = useState("");
	const [longDescription, setLongDescription] = useState("");
	const [type, setType] = useState(null);
	const [userUrl, setUserUrl] = useState("");
	const [csvName, setCsvName] = useState("");
	//eslint-disable-next-line
	const [csvLoader, setCsvLoader] = useState(false);
	const [openPopup, setOpenPopup] = useState(false);
	const [device, setDevice] = useState(DEVICE_OPTIONS[0]);
	const [descriptionType, setDescriptionType] = useState(3);
	const [url, setUrl] = useState("");

	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		});

		// getNotifications();
	}, []);

	// hiding error toast after 3sec
	useEffect(() => {
		if (error !== null) {
			setTimeout(() => {
				setError(null);
			}, 3000);
		}
	}, [error]);

	// hiding success toast after 3sec
	useEffect(() => {
		if (success !== null) {
			setTimeout(() => {
				setSuccess(null);
			}, 3000);
		}
	}, [success]);


	const _showConfirmation = () => {
		const modal = new Modal(document.getElementById("pushConfirm"));
		modal.show();
	}

	/*----- send global notification api ---*/
	const _sendGlobalNotification = () => {
		setLoader(true);
		let api_url = devconfig.admin + '/notification/send-global'
		let data = JSON.stringify({
			title: title,
			message: content,
			image: imageUrl,
			long_description: longDescription,
			type: type,
			url: url,
			csv_url: userUrl
		});


		APIPost(api_url, data).then((response) => {
			if (response.status_code === 200) {
				setSuccess("Notification sent successfully");
				setLoader(false);
			} else {
				setError(response.message);
				setLoader(false);
			}
		});
	}

	const _uploadFile = (temp, for_users) => {
		if (for_users) {
			setCsvLoader(true)
		} else {
			setFileUpload(true)
		}
		let url = devconfig.admin + '/file/upload'

		let data = JSON.stringify({
			file: temp.name,
			is_public: true,
		})

		APIPost(url, data).then((response) => {
			if (response.status_code === 200) {
				if (for_users) {
					setCsvLoader(true);
				} else {
					setFileUpload(true)
				}
				_uploadDocument(response.data, temp, for_users); // upload file in AWS s3
			} else {
				setError(response.message);
				if (for_users) {
					setCsvLoader(false)
				} else {
					setFileUpload(false)
				}
			}
		})
	}

	/* Uploading to s3 */
	const _uploadDocument = async (data, file, for_users) => {
		if (for_users) {
			setCsvLoader(true)
		} else {
			setFileUpload(true)
		}
		var formdata = new FormData();
		formdata.append("key", data.upload_info.fields.key);
		formdata.append("x-amz-algorithm", data.upload_info.fields['x-amz-algorithm']);
		formdata.append("x-amz-credential", data.upload_info.fields['x-amz-credential']);
		formdata.append("x-amz-date", data.upload_info.fields['x-amz-date']);
		formdata.append("policy", data.upload_info.fields['policy']);
		formdata.append("x-amz-signature", data.upload_info.fields['x-amz-signature']);
		formdata.append("file", file, file.name);

		var requestOptions = {
			method: 'POST',
			body: formdata,
			redirect: 'follow'
		};

		fetch(data.upload_info.url, requestOptions)
			.then(response => response.text())
			.then(result => {
				if (for_users) {
					setCsvLoader(false);
					setUserUrl(data.view_info);
					setCsvName(file.name);
				} else {
					setFileUpload(false)
					setImageUrl(data.view_info);
					setOpenPopup(true);
				}
			})
			.catch(error => {
				// console.log('error', error); // show error toast here
			});
	}

	const _clearFields = () => {
		setContent("");
		setTitle("");
		setPreviewUrl("");
		setImageName("");
		setImageUrl("");
		setCsvName("");
		setUserUrl("");
		setLongDescription("");
		setType(null);
		setDescriptionType(3);
		setOpenPopup(false)
	}


	return (
		<div className="row ">
			<div className="col-lg-6 ">

				{/*-----------notification card to send global notifications---------*/}
				<div className="card border-0 e-dashboard-card pt-4 px-4 pb-2 e-notification">
					<div className="col-lg-12  mb-3">
						<h5 className='e-notification-form-title'>Push notifications</h5>
					</div>
					<div className='row px-2'>
						<div className="col-lg-12 pe-0 mb-4">
							<label>Title</label>
							<Input
								placeholder="Notification title"
								value={title}
								className="e-notification-form-element"
								valueChange={(temp) => {
									setTitle(temp);
								}}
							/>
						</div>

						<div className='col-lg-12 pe-0'>
							<label>Upload image</label>
							{
								previewUrl ?
									<div className='e-preview position-relative mb-3'>
										<img src={Delete} alt="delete"
											width={"32px"} className='position-absolute e-delete-image' style={{ right: "16px", cursor: "pointer" }}
											onClick={() => {
												setPreviewUrl("");
												setImageUrl("");
												setImageName("");
											}} />
										{
											fileUpload ?
												<img src={LoaderImg} alt="" height="80px" width="100%" style={{ objectFit: "contain", marginTop: "40px" }} />
												:
												<img src={previewUrl} alt="" height="100%" width="100%" style={{ objectFit: "contain" }} />
										}
										<span className='e-choose-btn' onClick={() => { $('#e-upload').trigger("click") }}>
											<img src={Preview} alt="" height="30px" width="30px" className='me-2' />
											Change image
										</span>
										{/* <span className='e-drag-text'>(Drag and drop image or click here to select image)</span> */}
										<FileUpload
											accept={" application/pdf, image/*"}
											className="d-none"
											id="e-upload"
											key="e-upload-image"
											upload={(temp) => {
												// setUploadImage(temp.name)
												let preview = URL.createObjectURL(temp);
												setPreviewUrl(preview)
												_uploadFile(temp)
											}} />
									</div>
									:
									<div className="e-uploader mb-4" onClick={() => { $('#e-upload').click() }}>
										<div className='text-center'>

											<p className='text-center mb-2'>
												{
													imageName ? imageName : "Upload an image"
												}
											</p>
											{/* 
                                                            video !== 1 ?
                                                            <span className='e-drag-text'>(Drag and drop image or click here to select image)</span>
                                                            :
                                                            null */}
										</div>
										<FileUpload
											accept={" application/pdf, image/*"}
											className="d-none"
											id="e-upload"
											key="e-upload-image"
											upload={(temp) => {
												setImageName(temp.name);
												let preview = URL.createObjectURL(temp);
												setPreviewUrl(preview);
												_uploadFile(temp)
											}} />
									</div>
							}
						</div>
						<div className="col-lg-12 pe-0 mb-4">
							<label>Content</label>
							<TextArea
								placeholder="Content of the push notification"
								value={content}
								className="e-notification-form-element"
								rows="9"
								valueChange={(temp) => {
									setContent(temp);
								}}
							/>
						</div>
						<div className="col-lg-12 pe-0 mb-4">
							<label>URL</label>
							<Input
								placeholder="URL"
								value={url}
								className="e-notification-form-element"
								valueChange={(temp) => {
									setUrl(temp);
								}}
							/>
						</div>
						<div className="col-lg-12 pe-0 mb-4">
							<label className='d-flex align-items-center'>
								Long description
								<div onChange={(e) => {
									setDescriptionType(Number(e.target.value));
									if (Number(e.target.value) === 4) {
										const editorContent = document.getElementById("html-content");
										setLongDescription(editorContent.innerText && editorContent.innerText.length > 0 ? editorContent.innerText : "")
									}
								}} className="d-flex ps-3">
									<Radio id={3} value={3} checked={descriptionType === 3 ? true : false} label="Wysiwyg" />
									<Radio id={4} value={4} checked={descriptionType === 4 ? true : false} label="Plain text " />

								</div>
							</label>
							{
								descriptionType === 3 ?
									<div id="html-content">
										<Editor
											editorChange={(temp) => {
												setLongDescription(temp)
											}} value={longDescription} />
									</div>
									:
									<TextArea
										placeholder="Long description"
										value={longDescription}
										className="e-notification-form-element"
										rows="9"
										valueChange={(temp) => {
											setLongDescription(temp);
										}}
									/>
							}
						</div>
						<div className='col-12 mb-2'>
							<div onChange={(e) => setType(Number(e.target.value))} className="d-flex col-lg-12 ps-2">
								<Radio id={1} value={1} checked={type === 1 ? true : false} label="All" />
								<Radio id={2} value={2} checked={type === 2 ? true : false} label="Segment" />

							</div>
						</div>
						{
							type === 2 ?

								<div className='col-lg-12'>
									<label>Upload csv url</label>
									{


										<div className="e-uploader mb-4" onClick={() => { $('#e-csv-upload').click() }}>
											<div className='text-center'>

												<p className='text-center mb-2'>
													{
														csvName ? csvName : "Upload csv file"
													}
												</p>
											</div>
											<FileUpload
												accept={".csv"}
												className="d-none"
												id="e-csv-upload"
												key="e-upload-image"
												upload={(temp) => {


													_uploadFile(temp, true)
												}} />
										</div>
									}
								</div>
								:
								null
						}

						<div className="col-lg-12 d-flex justify-content-start text-left mb-3">
							<SecondaryBtn
								name="Clear"
								handleClick={() => {
									_clearFields()
								}} />
							{
								title.length > 1 && content.length > 1 && type === 1 ?
									<PrimaryBtn
										name="Send now"
										className={
											loader ?
												"e-notification-form-btn e-btn-loader ms-3"
												:
												"e-notification-form-btn ms-3"}
										handleClick={() => {
											_showConfirmation();
										}}
									/>
									:
									title.length > 1 && content.length > 1 && type === 2 && userUrl ?
										<PrimaryBtn
											name="Send now"
											className={
												loader ?
													"e-notification-form-btn e-btn-loader ms-3"
													:
													"e-notification-form-btn ms-3"}
											handleClick={() => {
												_showConfirmation();
											}}
										/>
										:
										<PrimaryBtn
											name="Send now"
											className="e-disabled ms-3"
										/>
							}
						</div>
					</div>
				</div>
			</div>
			<div className="col-lg-6">
				<div className="card border-0 e-dashboard-card pt-4 px-4 pb-2 ">
					{
						device.value === 1 ?
							<div className='e-exVHZH eXxezI'>
								<div className='e-gHetzH fTdPov'>
									<div className='e-jnWwQn bJDHE'>
										<div className='e-lGdcD dwCIcN'>
											<div className="e-cdZahM fhnWcW">
												<div className={openPopup ? "e-ibEqUB eDMUfQ" : "e-ibEqUB  jlzOwm"} onClick={() => setOpenPopup(!openPopup)}>
													<div className="e-lbhYTY iSaNhF">
														<div className="e-hCDzWh jWpOyP">
															<span className="e-eBxihg iJWpSm"></span>
														</div>
													</div>
													<header className={"e-eTFcpK dhiXIF"}>
														<div className="e-gauFBm kHNOOQ">
															{
																openPopup ?
																	null
																	:
																	<h1 className="e-kRktcz jgpaiD">{title} </h1>
															}
															<span className="e-cBQgDv gcOyUw">MIRA Money • now</span>
														</div>
														{
															openPopup ?
																null
																:
																<>

																	<div className="e-ejVUYw djPDJK">{content ? content : "Default message"}</div>

																</>
														}
													</header>
													<div className="e-hFvvYD gYnYpU">
														{
															openPopup ?
																<span className="e-ldZUWu cwKmnI "></span>
																:
																<span className="e-ldZUWu jsykPp "></span>
														}
													</div>
													{
														openPopup ?
															<>
																<h1 className="e-kRktcz jgpaiE">{title}</h1>

																<div className="e-ejVUYw djPDJN">{content ? content : "Default message"}</div>
																<img src={imageUrl}
																	className="e-bfKFlL fZOMbY"
																	alt="" />
															</>
															:
															null
													}
												</div>
											</div>

										</div>
									</div>
								</div>
							</div>
							:
							<div className="e-exVHZH eXxezI">
								<div className="e-gHetzH fTdPov">
									<div className="e-jnWwQn bJDHE">
										<div className="e-jUtGcO eLyRQE">
											<div className="e-eCDnqH ewaPOn" >
												<div className="e-keNpes fDvEly">
													<div className='e-iJyeUs'>
														<div className="e-feINqK jQzjPe">
															<div className="e-hrzOVh jARDEl">
																<div className="e-hcJkSI hthLDl">
																	<div className="e-jTUlZf bGfzT"></div>
																</div>
															</div>
															<div className="e-lcoedz kjMA-dz">
																<h2 className="e-lmHNfd cswsXo">{title}</h2>
																<div className="e-fpcwso kHEcjk mt-1">
																	<p className="e-bdxVC jUeHVs">{content ? content : "Default Message"}</p>
																</div>
															</div>
															<div className="e-eMWWsl gDJlbH">
																<div className="e-fYIOIr iajhFQ">now</div>
															</div>
														</div>
														{
															imageUrl ?
																<img src={imageUrl} className="e-cmYsgE jISezq" alt="preview" />
																:
																null
														}
													</div>
												</div>

											</div>
										</div>
									</div>
								</div>
							</div>

					}
					<div className='row'>
						<div className='col-6 offset-3  pt-4'>
							<SelectBox
								isSearchable={false}
								options={DEVICE_OPTIONS}
								value={device}
								selectchange={(selected_value) => {
									setDevice(selected_value)
								}} />
						</div>
					</div>
				</div>
			</div>
			{/*-----------notification card ends---------*/}

			{/*---------------- list of push notifications ------------*/}
			{/* <div className="col-lg-12 mt-4 position-relative">
				{
					loader === true ?
						<Loader />
						:
						<div className="e-dashboard-card card border-0 p-4">
							{
								notificationData.length > 0 ?
									<>
										<h6 className="e-card-title mb-3">Push notification history</h6>
										{
											notificationData?.map((item, key) => (
												<div className="row" key={key}>
													<div className="col-lg-12">
														<div className="card border-0 e-notification-history p-3 mb-3">
															<span className="e-notification-history-time d-block">
																{item.date}
															</span>
															<h6 className="e-title mt-1 mb-3">{item.title}</h6>
															<span className="e-notification-history-content d-block">
																{item.description}
															</span>
														</div>
													</div>
												</div>
											))
										}
									</>
									:
									<EmptyNote note="No notification found" />
							}
						</div>
				}
			</div> */}


			{/*--------- error toast --------*/}
			{
				error !== null ?
					<Toast
						data={error}
						type="error"
						id="1"
					/>
					:
					null
			}


			{/*--------- success toast --------*/}
			{
				success !== null ?
					<Toast
						data={success}
						type="success"
						id="2"
					/>
					:
					null
			}
			<NotificationConfirm
				type={type}
				Confirm={() => {
					_sendGlobalNotification();
				}} />
		</div>
	);
}

export default Index;