/*
 *   File :  privacyModal.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description :  PrivacyModal Modal
 *   Integrations :
 *   Version : v1.0
 */

/*import packages*/
import React, { useState, useEffect } from 'react';
import $ from 'jquery';

/*import components*/
import Preview from '../../assets/images/banner/preview.svg';
import LoaderImg from '../../assets/images/loader/mintd-loader.gif'
import { Editor, FileUpload } from '../input';
import Delete from '../../assets/images/modal/delete.svg';

/*import styles*/
import styles from '../../styles/components/addFaq.module.scss';

/* import services */
import devconfig from '../../config/config';
import APIPost from '../../services/postApi';
import { PrimaryBtn, SecondaryBtn } from '../cta';
import { Toast } from '../note';


export default function TriggerModal(props) {
    const [imageUrl, setImageUrl] = useState("");
    const [imageName, setImageName] = useState("");
    const [fileUpload, setFileUpload] = useState(false);
    const [previewUrl, setPreviewUrl] = useState("");
    const [longDescription, setLongDescription] = useState("");
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [triggerLoader, setTriggerLoader] = useState(false);

    useEffect(() => {
        var detailModal = document.getElementById('trigger');
        detailModal.addEventListener('hidden.bs.modal', async function () {
            setImageUrl('');
            setImageName("");
            setPreviewUrl("");
            setLongDescription("");
        });
    }, []);

    // hiding error toast after 3sec
    useEffect(() => {
        if (error !== null) {
            setTimeout(() => {
                setError(null);
            }, 3000);
        }
    }, [error]);

    // hiding success toast after 3sec
    useEffect(() => {
        if (success !== null) {
            setTimeout(() => {
                setSuccess(null);
            }, 3000);
        }
    }, [success]);

    const _uploadFile = (temp, for_users) => {

        setFileUpload(true)

        let url = devconfig.admin + '/file/upload'

        let data = JSON.stringify({
            file: temp.name,
            is_public: true,
        })

        APIPost(url, data).then((response) => {
            if (response.status_code === 200) {
                setFileUpload(true);
                _uploadDocument(response.data, temp, for_users); // upload file in AWS s3
            } else {
                setError(response.message);
                setFileUpload(false)

            }
        })
    }

    /* Uploading to s3 */
    const _uploadDocument = async (data, file, for_users) => {

        setFileUpload(true)

        var formdata = new FormData();
        formdata.append("key", data.upload_info.fields.key);
        formdata.append("x-amz-algorithm", data.upload_info.fields['x-amz-algorithm']);
        formdata.append("x-amz-credential", data.upload_info.fields['x-amz-credential']);
        formdata.append("x-amz-date", data.upload_info.fields['x-amz-date']);
        formdata.append("policy", data.upload_info.fields['policy']);
        formdata.append("x-amz-signature", data.upload_info.fields['x-amz-signature']);
        formdata.append("file", file, file.name);

        var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        };

        fetch(data.upload_info.url, requestOptions)
            .then(response => response.text())
            .then(result => {

                setFileUpload(false)
                setImageUrl(data.view_info);

            })
            .catch(error => {
                // console.log('error', error); // show error toast here
            });
    }

    // trigger rebalance
    const _triggerRebalance = () => {
        setTriggerLoader(true);
        let url = devconfig.admin + '/rebalance/trigger';
        let data = JSON.stringify({
            summary: longDescription,
            image: imageUrl
        })
        APIPost(url, data).then((response) => {
            if (response.status_code === 200) {
                setSuccess("Rebalance triggered successfully")
                setTriggerLoader(false);
                const closeModal = document.getElementById("close-btn");
                closeModal.click()
            } else {
                setTriggerLoader(false);
            }
        })
    }
    return (
        <div className={`${styles.e_privacy} modal fade e-notification`} id="trigger" tabIndex="-1" aria-labelledby="success" aria-hidden="true">
            <div className={`${styles.e_modal_dialog} modal-dialog modal-dialog-centered`}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h6>Trigger balance</h6>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" id="close-btn"></button>
                    </div>
                    <div className="container px-3">
                        <div className="row">
                            <div className='col-lg-12'>
                                <label>Upload image</label>
                                {
                                    previewUrl ?
                                        <div className='e-preview position-relative mb-3'>
                                            <img src={Delete} alt="delete"
                                                width={"32px"} className='position-absolute e-delete-image' style={{ right: "16px", cursor: "pointer" }}
                                                onClick={() => {
                                                    setPreviewUrl("");
                                                    setImageUrl("");
                                                    setImageName("");
                                                }} />
                                            {
                                                fileUpload ?
                                                    <img src={LoaderImg} alt="" height="80px" width="100%" style={{ objectFit: "contain", marginTop: "40px" }} />
                                                    :
                                                    <img src={previewUrl} alt="" height="100%" width="100%" style={{ objectFit: "contain" }} />
                                            }
                                            <span className='e-choose-btn' onClick={() => { $('#e-upload').trigger("click") }}>
                                                <img src={Preview} alt="" height="30px" width="30px" className='me-2' />
                                                Change image
                                            </span>
                                            {/* <span className='e-drag-text'>(Drag and drop image or click here to select image)</span> */}
                                            <FileUpload
                                                accept={" application/pdf, image/*"}
                                                className="d-none"
                                                id="e-upload"
                                                key="e-upload-image"
                                                upload={(temp) => {
                                                    // setUploadImage(temp.name)
                                                    let preview = URL.createObjectURL(temp);
                                                    setPreviewUrl(preview)
                                                    _uploadFile(temp)
                                                }} />
                                        </div>
                                        :
                                        <div className="e-uploader mb-4" onClick={() => { $('#e-upload').click() }}>
                                            <div className='text-center'>

                                                <p className='text-center mb-2'>
                                                    {
                                                        imageName ? imageName : "Upload an image"
                                                    }
                                                </p>
                                                {/* 
                                                            video !== 1 ?
                                                            <span className='e-drag-text'>(Drag and drop image or click here to select image)</span>
                                                            :
                                                            null */}
                                            </div>
                                            <FileUpload
                                                accept={" application/pdf, image/*"}
                                                className="d-none"
                                                id="e-upload"
                                                key="e-upload-image"
                                                upload={(temp) => {
                                                    setImageName(temp.name);
                                                    let preview = URL.createObjectURL(temp);
                                                    setPreviewUrl(preview);
                                                    _uploadFile(temp)
                                                }} />
                                        </div>
                                }
                            </div>
                            <div className='col-lg-12 mb-4'>
                                <label>Why is rebalance required now?</label>
                                <Editor
                                    editorChange={(temp) => {
                                        setLongDescription(temp)
                                    }} value={longDescription} />
                            </div>
                            <div className='col-lg-12 d-flex justify-content-end mb-3'>
                                <SecondaryBtn name="Cancel"
                                    cancel="modal" />
                                {imageUrl && longDescription ?
                                    <PrimaryBtn name="Trigger balance"
                                        className={triggerLoader ? "e-btn-loader ms-3" : "ms-3"}
                                        handleClick={_triggerRebalance} />
                                    :
                                    <PrimaryBtn name="Trigger balance"
                                        className={"e-disabled ms-3"} />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Error toast */}
            {
                error !== null ?
                    <Toast data={error} type="error" id="1" />
                    :
                    null
            }

            {/* success toast */}
            {
                success !== null ?
                    <Toast data={success} type="success" id="2" />
                    :
                    null
            }
        </div>
    )
}
