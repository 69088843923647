/*
 *   File : index.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Index file for Manage allocation container;
 *   Integrations 19-08-2022
 *   Version : v1.0
 *   Created : 10-08-2022
*/

/*import packages*/
import React, { useEffect, useState } from "react";


/*import components*/
import { AccordionAllocation } from "../../components/dataFormating";
import { Loader } from "../../components/loader";
import { EmptyNote } from "../../components/note";

/*import assets*/

/*import services */
import devconfig from "../../config/config";
import APIGet from "../../services/getApi";

const Index = () => {

    const [loader, setLoader] = useState(false);
    const [allocationData, setAllocationData] = useState({});
    const [title, setTitle] = useState([]);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })

        getAllocation(true);

    }, []);


    /*--------- get allocation list api ---*/
    const getAllocation = (status) => {
        setLoader(status);
        let url = devconfig.admin + "/allocation/list";

        APIGet(url).then((response) => {

            if (response.status_code === 200) {
                setLoader(false);
                setAllocationData(response.data);
                for (let i = 0; i < Object.keys(response.data).length; i++) {
                    let ele = Object.keys(response.data)
                    setTitle(ele)
                }

            } else {
                setLoader(false);
                setAllocationData({});
            }
        });
    }

    return (
        <>
            <div className="row">
                <div className="col-lg-12">
                    <div className="card e-dashboard-card border-0 p-4 position-relative">
                        {
                            loader ?
                                <Loader />
                                :
                                Object.keys(allocationData).length > 0 ?
                                    <>
                                        {
                                            title.filter((item) => item !== "tenure").map((titleName, index) => {
                                                return (
                                                    <AccordionAllocation
                                                        data={allocationData}
                                                        value={titleName}
                                                        title={titleName === "equity" ? "Equity" : "Debt"}
                                                        id={index}
                                                        Update={() => {
                                                            getAllocation(false)
                                                        }}
                                                        setData={(arr) => {
                                                            let temp = allocationData
                                                            temp.titleName = arr
                                                            setAllocationData(temp)
                                                        }} />
                                                )
                                            })
                                        }

                                        {/* <AccordionAllocation
                                            data={allocationData}
                                            title="Debt"
                                            value="debt"
                                            id={2}
                                            setData={(arr) => {
                                                let temp = allocationData
                                                temp.debt = arr
                                                setAllocationData(temp)
                                            }} /> */}
                                    </>
                                    :
                                    <EmptyNote />
                        }
                    </div>
                </div>
            </div>
        </>
    );
}
export default Index