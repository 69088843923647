/*
 *   File : index.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Index file for dashboard container;
 *   Integrations :01-02-2022
 *   Version : v1.0
 *   Created : 11-12-2021
 */

/*import packages*/
import { useState, useEffect } from 'react';

/*import components*/
import { Summary } from '../../components/card/index';
import { LineChart, BarChartSecond } from '../../components/chart/index';
import SelectBox from '../../components/dropdown/selectBox';
import { EmptyNote, Toast } from '../../components/note';
import { Loader } from '../../components/loader';

/* import services */
import devconfig from '../../config/config';
import APIGet from '../../services/getApi';
// import APIPost from '../../services/postApi';


/*import assets*/
import Investors from '../../assets/images/card/investors.svg';
import TotalAum from '../../assets/images/card/total-aum.svg';
import PendingAum from '../../assets/images/card/pending-aum.svg';
import Investment from '../../assets/images/card/investment.svg';
import Goals from '../../assets/images/card/goals.svg';
import PendingIcon from '../../assets/images/card/pendingaum.svg'
import APIPost from '../../services/postApi';
// import DateRange from '../../components/input/dateRangepicker';

const Index = (props) => {
    const [error, setError] = useState(null);
    const [selected, setSelected] = useState({ value: 2, label: 'Weekly', });
    const [dataLoader, setDataLoader] = useState(false);
    const [dashboardData, setDashboardData] = useState({});
    // const [staticsLabel, setStatisticsLabel] = useState([])
    const [cumulativeAumData, setCumulativeAumData] = useState([]);
    const [cumulativeUsersData, setCumulativeUsersData] = useState([]);
    const [loader, setLoader] = useState(false);
    // const [startDate, setStartDate] = useState(null);
    // const [endDate, setEndDate] = useState(null)
    const [cumulativeAumLabel, setCumulativeAumLabel] = useState([]);
    const [investmentLoader, setInvestmentLoader] = useState(false);
    const [investmentAumData, setInvestmentAumData] = useState([]);
    const [investmentTotalData, setInvestmentTotalData] = useState([]);
    const [investmentAumLabel, setInvestmentAumLabel] = useState([]);
    const [investmentTotalLabel, setInvestmentTotalLabel] = useState([]);

    useEffect(() => {
        getDashboardData();
        getStatistics();
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, []);

    useEffect(() => {
        setTimeout(() => {
            setError(null);
        }, 300);
    }, [error]);

    useEffect(() => {
        getCumulativePerformace();
        //eslint-disable-next-line
    }, [selected])
    /* select box options ---*/

    const cumulativeOptions = [
        {
            value: 1, label: 'Daily',
        },
        {
            value: 2, label: 'Weekly'
        },
        {
            value: 3, label: 'Monthly'
        },
    ]


    /*----- dashboard data api ---*/
    const getDashboardData = () => {
        setDataLoader(true)
        let url = devconfig.admin + '/dashboard/get'
        APIGet(url).then((response) => {
            if (response.status_code === 200) {
                setDataLoader(false);
                setDashboardData(response.data);
            }
            else {
                setDataLoader(false);
                setError(response.message);
            }
        });
    }

    /*----- statistics api ---*/
    const getStatistics = () => {
        setInvestmentLoader(true);
        setInvestmentAumData([]);
        setInvestmentTotalData([]);
        setInvestmentAumLabel([]);
        setInvestmentTotalLabel([]);
        let url = devconfig.admin + '/dashboard/goal-statistics'
        let data = JSON.stringify({
            "filter_id": 1,
            "from_date": null,
            "till_date": null
        })

        APIPost(url, data).then((response) => {
            if (response.status_code === 200) {
                setInvestmentLoader(false);
                setInvestmentAumData(response.data.goal_aum.data);
                setInvestmentTotalData(response.data.goal_investment.data);
                setInvestmentAumLabel(response.data.goal_aum.label);
                setInvestmentTotalLabel(response.data.goal_investment.data);
            }
            else {
                setInvestmentLoader(false);
                setInvestmentAumData([]);
                setInvestmentTotalData([]);
                setInvestmentAumLabel([]);
                setInvestmentTotalLabel([]);
            }
        });
    }

    /*----- statistics api ---*/
    const getCumulativePerformace = () => {
        setCumulativeAumData([]);
        setCumulativeUsersData([]);
        setCumulativeAumLabel([])
        setLoader(true)
        let url = devconfig.admin + '/dashboard/cumulative-performance'

        let data = JSON.stringify({
            filter_id: selected.value,
            from_date: null,
            till_date: null
        })
        APIPost(url, data).then((response) => {
            if (response.status_code === 200) {
                setLoader(false)
                setCumulativeAumData(Object.values(response.data.aum))
                setCumulativeAumLabel(response.data.aum.label)
                setCumulativeUsersData(Object.values(response.data.users))
            }
            else {
                setLoader(false)
                setCumulativeAumData([]);
                setCumulativeUsersData([]);
                setCumulativeAumLabel([]);
            }
        });
    }

    return (
        <>
            {
                !dataLoader ?
                    <>
                        {/*-------------- Dashboard cards---------------- */}

                        <div className="row e-dashboard">
                            {/* <div className="col-lg-3">
								<Summary 
								data={{ title: 'AUM', currency: "₹", count: dashboardData.aum_fee, icon: Transaction, text: "" }} 
								/>
							</div> */}
                            <div className="col-lg-3 col-md-6 col-sm-6 mb-4">
                                <Summary
                                    data={{
                                        title: 'Active Investors',
                                        count: Object.values(dashboardData).length > 0 && dashboardData && dashboardData.active_investors ?
                                            dashboardData.active_investors
                                            :
                                            "-"
                                        ,
                                        icon: Investors,
                                        text: ""
                                    }}
                                />
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 mb-4">
                                <Summary
                                    data={{
                                        title: 'Accounts in Pipeline',
                                        count: Object.values(dashboardData).length > 0 && dashboardData && dashboardData.inactive_investors ?
                                            dashboardData.inactive_investors
                                            :
                                            "-",
                                        icon: Investment
                                    }}
                                />
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 mb-4">
                                <Summary
                                    data={{
                                        title: 'Total AUM',
                                        count: Object.values(dashboardData).length > 0 && dashboardData && dashboardData.aum ?
                                            dashboardData.aum.split(" ")[0]
                                            :
                                            "-",
                                        icon: TotalAum,
                                        text: Object.values(dashboardData).length > 0 && dashboardData && dashboardData.aum ?
                                            dashboardData.aum.split(" ")[1]
                                            :
                                            null,
                                    }}
                                />
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 mb-4">
                                <Summary
                                    data={{
                                        title: `Pending AUM`,
                                        suffix: PendingIcon,
                                        text: Object.values(dashboardData).length > 0 && dashboardData && dashboardData.pending_transactions ?
                                            dashboardData.pending_transactions.split(" ")[1]
                                            :
                                            null,
                                        count: Object.values(dashboardData).length > 0 && dashboardData && dashboardData.pending_transactions ?
                                            dashboardData.pending_transactions.split(" ")[0]
                                            :
                                            "-",
                                        icon: PendingAum,
                                    }}
                                />
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 mb-4">
                                <Summary
                                    data={{
                                        title: 'SIP Investors',
                                        count: Object.values(dashboardData).length > 0 && dashboardData && dashboardData.sip_customer_count ?
                                            dashboardData.sip_customer_count
                                            :
                                            "-",
                                        icon: Investors
                                    }}
                                />
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 mb-4">
                                <Summary
                                    data={{
                                        title: 'SIP Amount',
                                        count: Object.values(dashboardData).length > 0 && dashboardData && dashboardData.total_sip_amount_text ?
                                            dashboardData.total_sip_amount_text
                                            :
                                            "-",
                                        icon: TotalAum
                                    }}
                                />
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 mb-4">
                                <Summary
                                    data={{
                                        title: 'Total goals',
                                        count: Object.values(dashboardData).length > 0 && dashboardData && dashboardData.goals ?
                                            dashboardData.goals
                                            :
                                            "-",
                                        icon: Goals
                                    }}
                                />
                            </div>

                            <div className="col-lg-3 col-md-6 col-sm-6 mb-4">
                                <Summary
                                    data={{
                                        title: 'Pending goals',
                                        count: Object.values(dashboardData).length > 0 && dashboardData && dashboardData.pending_goals ?
                                            dashboardData.pending_goals
                                            :
                                            "-",
                                        icon: Goals
                                    }}
                                />
                            </div>


                        </div>

                        {/*------------------ Dashboard line graph------------------ */}

                        <div className="row mt-5 e-sort">
                            <div className='col-lg-6'>
                                <h6 className="e-card-setcion-title">Cumulative performance</h6>
                            </div>
                            <div className='col-lg-6 d-flex justify-content-end'>
                                {/* <DateRange

                                    onDatesChange={(start, end) => {
                                        setStartDate(start);
                                        setEndDate(end);
                                    }}
                                /> */}
                                <SelectBox
                                    value={selected}
                                    // isDisabled={startDate && endDate ? true : false}
                                    options={cumulativeOptions}
                                    className="e-dashboard-card-sort ms-3"
                                    selectchange={(temp) => { setSelected(temp); }}
                                    isSearchable={false}
                                />
                            </div>

                        </div>
                        <div className="row mt-3">
                            <div className="col-lg-6 mb-lg-0 mb-md-4 mb-4">
                                <div className="card e-dashboard-card e-dashboard-row-2 e-round-chart-container border-0 p-4">
                                    <h6 className="e-card-title">AUM</h6>
                                    {
                                        !loader ?
                                            cumulativeAumData && cumulativeAumData.length > 0 && cumulativeAumLabel && cumulativeAumLabel.length > 0 ?
                                                <div className="col-lg-12 ms-3 e-w-800 ">
                                                    <LineChart
                                                        colors={['#106EE8', '#FFFFFF']}
                                                        data={cumulativeAumData[0]}
                                                        label={cumulativeAumLabel}
                                                    />
                                                </div>
                                                :
                                                <EmptyNote note="No data" />
                                            :
                                            null
                                    }
                                </div>
                            </div>
                            <div className="col-lg-6 pe-0">
                                <div className="card e-dashboard-card e-dashboard-row-2 e-round-chart-container border-0 p-4">
                                    <h6 className="e-card-title">Users</h6>
                                    {!loader ?
                                        cumulativeUsersData && cumulativeUsersData.length > 0 ?
                                            <div className="col-lg-12 ms-3 e-w-800 ">
                                                <LineChart
                                                    colors={['#106EE8', '#FFFFFF']}
                                                    data={cumulativeUsersData[0]}
                                                    label={cumulativeUsersData[1]}
                                                    users={true}
                                                />
                                            </div>
                                            :
                                            <EmptyNote note="No data" />
                                        :
                                        null
                                    }
                                </div>
                            </div>

                        </div>

                        {/* -------- Dashboard Periodic performance bar graph--------------*/}

                        {/* <div className="d-flex justify-content-between mt-5 e-sort">
                            <h6 className="e-section-title">Cumulative Performance</h6>
                            <SelectBox
                                value={selectedPeriod}
                                options={periodicOptions}
                                defaultValue={periodicOptions[1]}
                                className="e-dashboard-card-sort"
                                selectchange={(temp) => { getPeriodicPerformance(temp.value); setSelectedPeriod(temp) }}
                                isSearchable={false}
                            />
                        </div> */}
                        <div className="row mt-4 e-bar-chart">

                            <div className="col-lg-12 mt-2 pe-0">
                                <div className="card e-dashboard-card e-dashboard-row-2 border-0 p-4 pe-0 " style={{ width: "100%" }}>
                                    <h6 className="e-card-title">Total investments</h6>
                                    {
                                        !investmentLoader ?
                                            investmentTotalData && investmentTotalData.length > 0 &&
                                                investmentTotalLabel && investmentTotalLabel.length > 0 ?
                                                <div className='ms-3 e-w-1600'>
                                                    <BarChartSecond
                                                        data={investmentTotalData}
                                                        label={investmentAumLabel}
                                                    />
                                                </div>

                                                :
                                                <EmptyNote note="No data" />
                                            :
                                            null
                                    }
                                </div>
                            </div>
                            <div className="col-lg-12 mt-4 pe-0">
                                <div className="card e-dashboard-card e-dashboard-row-2 border-0 p-4" style={{ width: "100%" }}>
                                    <h6 className="e-card-title">AUM</h6>
                                    {
                                        !investmentLoader ?
                                            investmentAumData && investmentAumData.length > 0 &&
                                                investmentAumLabel && investmentAumLabel.length > 0 ?
                                                <div className='ms-2 e-w-1600'>
                                                    <BarChartSecond
                                                        data={investmentAumData}
                                                        label={investmentAumLabel}
                                                    />
                                                </div>

                                                :
                                                <EmptyNote note="No data" />
                                            :
                                            null
                                    }
                                </div>
                            </div>
                        </div>
                    </>
                    :
                    <Loader />
            }

            {/* --------------- error message toast---------- */}
            {
                error !== null ?
                    <Toast
                        data={error}
                        id="1"
                        type="error"
                    />
                    :
                    null
            }
        </>
    );
}
export default Index;