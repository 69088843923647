/*
 *   File : index.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Index file for manage-content container;
 *   Integrations : 05-10-2022
 *   Version : v1.0
 *   Created : 17-09-2022
*/

/*import packages*/
import { useEffect, useState } from 'react';

/*import components*/
import { FundsTab } from '../../components/dataFormating/index';
import { PrimaryBtn } from '../../components/cta/index';
import { AddFaq } from '../../components/modal/index';
import FaqAccordion from '../../components/dataFormating/faqAccordion';
import Privacy from './privacy';



/* import assets */
import Add from '../../assets/images/manage-content/add.svg'
import Terms from './terms';

/* import services */
import devconfig from '../../config/config';
import APIGet from '../../services/getApi';
import { Loader } from '../../components/loader';
import { EmptyNote } from '../../components/note';

const Index = (props) => {
	const [index, setIndex] = useState(1);
	const [loader, setLoader] = useState(false);
	const [faqData, setFaqData] = useState([]);

	useEffect(() => {
		getFaq();
	}, [])

	/*--- Faq listing api ---*/
	const getFaq = () => {
		setLoader(true);
		let url = devconfig.admin + "/content/list-faq"
		APIGet(url).then((response) => {
			if (response.status_code === 200) {
				setLoader(false);
				setFaqData(response.data);
			} else {
				setLoader(false);
				setFaqData([]);
			}
		});
	}

	return (
		<>
			<div className='e-tab-position'>
				<FundsTab
					data={['FAQ', 'Privacy', 'Terms']}
					index={index}
					setTab={(temp) => setIndex(temp)} />
			</div>
			<div className="row">
				<div className="col-lg-12">
					<div className="card e-dashboard-card e-faq-card border-0 p-4">
						{
							index === 1 ?
								<>
									<div className="row mb-4">
										<div className="col-lg-9 col-8">
											<h6 className="e-card-title pt-3">Question & answer</h6>
										</div>
										<div className="col-lg-3 col-4 d-flex justify-content-end">
											<PrimaryBtn name="Add question"
												prefix={Add}
												target="#addFaq"
												toggle="modal" />
										</div>
									</div>
									{
										loader ?
											<Loader />
											:
											faqData.length > 0 ?
												<div className="col-lg-12">
													<FaqAccordion data={faqData} update={() => { getFaq() }} />
												</div>
												:
												<EmptyNote note="No data found" />
									}
								</>
								:
								index === 2 ?
									<>
										<Privacy />
									</>
									:
									<>
										<Terms />
									</>
						}
					</div>
				</div>
			</div>
			{/* add faq modal */}
			<AddFaq
				edit={false}
				update={() => { getFaq() }}
				close={() => {

				}} />
		</>
	)
}

export default Index;