
/*
 *   File : line.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : line.js
 *   Integrations : 
 *   Version : v1.0
 */

/*import package*/
import React, { Component } from 'react';
import Chart from "react-apexcharts";

class LineChartLast extends Component {
	constructor(props) {
		super(props);
		this.state = {
			series: [{
				name: 'New',
				type: 'area',
				data: this.props.data
			}],
			options: {
				// chart: {
				//   height: 350,
				//   type: 'area',
				// },
				stroke: {
					width: [2],
					curve: 'straight'
				},
				xaxis: {
					categories: this.props.label,
					allowDecimals: false,
					axisBorder: {
						show: false,
					},
					axisTicks: {
						show: false,
					},
					lines: {
						show: false
					},
					labels: {
						style: {
							colors: ['#6D6D6D'],
							fontSize: '10px',
							fontFamily: 'Poppins',
							fontWeight: 400,
							cssClass: 'apexcharts-xaxis-label',
						},
					},
				},
				yaxis: {
					categories: ['1k', '2k', '3k', '4k'],
					show: true,
					labels: {
						show: true,
						formatter: (idx) => { return Math.abs(idx) > 99999 ? "₹" + Math.sign(idx) * ((Math.abs(idx) / 100000).toFixed(1)) + 'L' : Math.abs(idx) > 999 ? "₹" + Math.sign(idx) * ((Math.abs(idx) / 1000).toFixed(1)) + 'k' : Math.sign(idx) * Math.abs(idx) },
						style: {
							colors: ['#6D6D6D'],
							fontSize: '10px',
							fontFamily: 'Monsterrat',
							fontWeight: 300,
							cssClass: 'apexcharts-yaxis-label',
						},
					},
				},
				grid: {
					show: true,
					borderColor: '#6D6D6D',
					strokeDashArray: 2,
					position: 'back',
				},
				fill: {
					type: 'gradient',
					gradient: {
						shadeIntensity: 1,
						opacityFrom: 0.1,
						opacityTo: 0.9,
						stops: [0, 90, 100]
					},
					colors: this.props.colors,
				},
				colors: this.props.colors,
				markers: {
					size: 0
				},
				dataLabels: {
					enabled: false,
				},
				tooltip: {
					shared: true,
					intersect: false,
					y: {
						formatter: function (y) {
							if (typeof y !== "undefined") {
								return y.toFixed(0) + " points";
							}
							return y;
						}
					}
				}
			},
		};
	}

	render() {

		return (
			<Chart
				options={this.state.options}
				series={this.state.series}
				type="area"
				height={350}
			/>
		);
	}
}


export default LineChartLast;
