/*
 *   File : index.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Index file for users container;
 *   Integrations : 20-01-2022
 *   Version : v1.0
 *   Created : 10-12-2021
 */
/*import pakcages*/
import { useState, useEffect, useRef, } from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

/*import components*/
import { DataTable } from '../../components/dataFormating/index';
import { FilterBtn, DownloadBtn } from '../../components/cta/index';
import { DateRangePicker, SearchInput } from '../../components/input/index';
import SelectBox from '../../components/dropdown/selectBox';
import { Loader } from '../../components/loader';
import { EmptyNote, Toast } from '../../components/note';

/*import styles*/
import styles from '../../styles/components/searchInput.module.scss';

/*import assets*/
import View from '../../assets/images/dataFormating/view.png';
import Block from '../../assets/images/dataFormating/warning.png';

/* import services */
import devconfig from '../../config/config';
import APIPost from '../../services/postApi';
import { ConfirmationforBlock } from '../../components/modal';
import { Modal } from 'bootstrap';

const Index = (props) => {

	const navigate = useNavigate();
	const wrapperRef = useRef(null);
	const userDataRef = useRef([])
	const [search, setSearch] = useState('');
	const [more, setMore] = useState(false);
	const [filterDown, setFilterDown] = useState(false);
	const [dates, setDates] = useState(null);
	const [refKey, setRefKay] = useState('');
	const [check, setCheck] = useState([])
	const [selectedStatus, setSelectedStatus] = useState(null);
	const [userData, setUserData] = useState([]);
	const [totalRecords, setTotalRecords] = useState(null);
	const [error, setError] = useState(null);
	const [loader, setLoader] = useState(true);
	const [downloadLoader, setDownloadLoader] = useState(false);
	const [success, setSuccess] = useState(null);
	const [userId, setUserId] = useState(null);
	const [blockStatus, setBlockStatus] = useState(false);
	const [blockedStatus, setBlockedStatus] = useState(null);
	const [pageNum, setPageNum] = useState(1);
	const [perPage, setPerPage] = useState(10);

	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		});

		// filterData();

		document.addEventListener('mouseup', _closeMenu);
		return () => {
			document.removeEventListener('mouseup', _closeMenu);
		};
	}, []);

	useEffect(() => {
		if (sessionStorage.getItem("invested_status")) {
			setSelectedStatus(JSON.parse(sessionStorage.getItem("invested_status")));
			setFilterDown(true);
		} else {
			setSelectedStatus(statusOptions[0]);
		}
		//eslint-disable-next-line
	}, [sessionStorage.getItem("invested_status")]);

	useEffect(() => {
		if (sessionStorage.getItem("blocked_status")) {
			setBlockedStatus(JSON.parse(sessionStorage.getItem("blocked_status")));
			setFilterDown(true);
		} else {
			setBlockedStatus(blockedStatusOptions[0]);
		}
		//eslint-disable-next-line
	}, [sessionStorage.getItem("blocked_status")]);

	useEffect(() => {
		if (sessionStorage.getItem("range")) {
			setDates(JSON.parse(sessionStorage.getItem("range")));
			setFilterDown(true);
		} else {
			setDates({
				start: null,
				end: null
			});
		}
		//eslint-disable-next-line
	}, [sessionStorage.getItem("range")]);

	useEffect(() => {
		if (sessionStorage.getItem("user_search")) {
			setSearch(JSON.parse(sessionStorage.getItem("user_search")));
		} else {
			setSearch('');
		}
		//eslint-disable-next-line
	}, [sessionStorage.getItem("user_search")]);


	useEffect(() => {
		if (error !== null) {
			setTimeout(() => {
				setError(null);
			}, 3000);
		}
	}, [error]);

	useEffect(() => {
		if (success !== null) {
			setTimeout(() => {
				setSuccess(null);
			}, 3000);
		}
	}, [success]);


	useEffect(() => {
		if (dates !== null && selectedStatus !== null && blockedStatus !== null) {
			_getUsersList();
		}

		//eslint-disable-next-line
	}, [selectedStatus, dates, blockedStatus, perPage, pageNum]);

	const customDateSort = (a, b, selector, given) => {
		if (selector === "joined") {
			let format = given ? given : "DD MMM YYYY"
			if (a[selector] && b[selector]) return moment(a[selector], format).valueOf() - moment(b[selector], format).valueOf();
			else if (a[selector]) return -1;
			else if (b[selector]) return 1;
			else return 0;
		}
	}

	const customStringSort = (a, b, selector) => {
		if (selector === "name") {
			if (a[selector] && b[selector]) {
				let stringA = a[selector].toUpperCase().trim();
				let stringB = b[selector].toUpperCase().trim();
				if (stringA < stringB) return -1;
				else if (stringA > stringB) return 1;
				else return 0;
			}
			else if (a[selector]) return -1;
			else if (b[selector]) return 1;
			else return 0;
		}

	}

	const statusOptions = [
		{
			value: null, label: 'All',
		},
		{
			value: true, label: 'Invested'
		},
		{
			value: false, label: 'Not yet invested'
		},
	]

	const blockedStatusOptions = [
		{
			value: null, label: 'All',
		},
		{
			value: true, label: 'Blocked'
		},
		{
			value: false, label: 'Not Blocked'
		},
	]

	/* ---- columns for user table ---*/
	const columns = [
		{
			name: 'Cust. ID',
			selector: row => row.user_id,
			sortable: true,
			left: true,
			width: "120px",
			defaultSortAsc: false,
			cell: row => row.user_id ? row.user_id : "--"
		},
		{
			name: 'Joined on',
			selector: "user_id",
			sortable: false,
			right: false,
			minWidth: '140px',
			cell: row => row.joined,
			defaultSortAsc: false,
			sortFunction: (a, b) => customDateSort(a, b, 'joined',),
		},
		{
			name: 'Name',
			selector: (row) => row.name ? row.name : '--',
			sortable: true,
			left: true,
			minWidth: '180px',
			cell: row => <>
				<div className='e-link' onClick={() => {
					navigate(`/user-detail?id=${row.user_id}`, {
						state: {
							from_date: dates.start,
							to_date: dates.end,
							is_invested: selectedStatus?.value,
							query: search
						}
					})
				}}>
					{row.is_blocked ? "🚫" : "  "}
					{row.name}
				</div>
			</>,
			sortFunction: (a, b) => customStringSort(a, b, 'name',),
		},
		{
			name: 'Phone',
			selector: (row) => row.phone ? row.phone : '--',
			sortable: false,
			right: false,
			minWidth: "180px",
		},
		{
			name: 'KYC status',
			cell: (row) => row.kyc_status_text ? row.kyc_status_text : "-",
			sortable: false,
			right: false,
			minWidth: "180px",
		},
		{
			name: 'Signup progress',
			cell: (row) => row.signup_progress_status,
			sortable: false,
			right: false,
			minWidth: "220px",
		},
		{
			name: 'AUM',
			selector: row =>
				row.aum ?
					row.aum
					:
					0
			,
			sortable: true,
			minWidth: "150px",
			right: false,
			cell: row => <>
				{
					row.aum ?
						"₹ " + row.aum.toFixed(2)
						:
						"₹ 0"
				}
			</>
		},
		{
			name: 'Name on PAN',
			selector: 'name_on_pan',
			cell: (row) => row.name_on_pan ? row.name_on_pan : '--',
			sortable: false,
			left: true,
			minWidth: "280px",
		},
		{
			name: 'Name in Bank account',
			selector: 'bank_account_name',
			cell: (row) => row.bank_account_name ? row.bank_account_name : '--',
			sortable: false,
			left: true,
			minWidth: "280px",
		},
		{
			name: 'Email',
			selector: 'email',
			cell: (row) => row.email ? row.email : '--',
			sortable: false,
			left: true,
			minWidth: "280px",
		},

		{
			name: 'Blocked?',
			cell: (row) => row.is_blocked ? "Yes" : "No",
			sortable: false,
			right: false,
			minWidth: "180px",
		},

		{
			name: 'Bank name',
			cell: (row) => row.bank_name ? row.bank_name : "-",
			sortable: false,
			right: false,
			minWidth: "180px",
		},

		{
			name: '',
			sortable: false,
			right: true,
			width: "100px !important;",
			cell: row => <div onClick={() => { setRefKay(row.user_id); setMore(true) }} ref={wrapperRef}>
				<span className="e-more" ></span>
				{
					more && row.user_id === refKey ?
						<div className="e-card" ref={wrapperRef} >
							<div onClick={() => navigate(`/user-detail?id=${row.user_id}`)}><img src={View} alt="View details" className="me-3" /><span>View details</span></div>
							{
								row.is_blocked === true ?
									<div className='mt-3' onClick={() => _openBlockUserModal(row)}><img src={Block} alt="Block" className="me-3" />Unblock user</div>
									:
									<div className='mt-3' onClick={() => _openBlockUserModal(row)}><img src={Block} alt="Block" className="me-3" />Block user</div>
							}
						</div>
						:
						null
				}
			</div>
		}
	];

	/*--------- active users list -----------*/
	const _getUsersList = (query) => {
		setCheck([]);

		let url = devconfig.admin + '/user/list';

		let data = JSON.stringify({
			from_date: dates.start,
			to_date: dates.end,
			is_invested: selectedStatus?.value,
			is_blocked: blockedStatus?.value,
			query: query ? query.value : search,
			page_num: pageNum,
			page_size: perPage
		});

		APIPost(url, data).then((response) => {
			if (response.status_code === 200) {

				setCheck([]);
				setLoader(false);
				userDataRef.current = response.data.data
				setUserData(userDataRef.current);
				setTotalRecords(response.data.total_records)
			}
			else {
				setUserData([]);
				setLoader(false)
			}
		})
	}

	/*---- download list api--*/
	const _downloadData = () => {
		setDownloadLoader(true);

		let url = devconfig.admin + '/user/list-download';

		let data = JSON.stringify({
			user_ids: check.length === userData.length ? [] : check
		});

		APIPost(url, data).then((response) => {
			if (response.status_code === 200) {
				if (response.status === true) {
					let a = document.createElement("a");
					a.href = response.data.url;
					document.body.appendChild(a);
					a.click();
					document.body.removeChild(a);
					setCheck([]);
					setDownloadLoader(false);
					setSearch("");
					setSuccess("List downloaded successfully")

				} else {
					setError(response.message);
					setDownloadLoader(false);
					setCheck([]);
				}
			}
			else {
				setError(response.message);
				setDownloadLoader(false);
				setCheck([]);
			}
		})
	}

	/*--- block user---*/
	const _openBlockUserModal = (row) => {
		setUserId(row.user_id);
		setBlockStatus(row.is_blocked);
		setTimeout(() => {
			const modal = new Modal(document.getElementById("Confirm"), {});
			modal.show();
		}, 200);

	}

	const _closeMenu = (event) => {
		if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
			setMore(false);
		}
	}

	const _handlePageChange = (page_num) => {

		setPageNum(page_num);

		window.scrollTo({
			top: 0, behavior: 'smooth'
		});
	};

	const _handlePerPage = (per_page) => {
		setPerPage(per_page);
		window.scrollTo({
			top: 0, behavior: 'smooth'
		});
	};

	/* --- selected row pushed to array ---*/
	const _handleChange = (row) => {
		let selected = []
		for (let i = 0; i < row.selectedRows.length; i++) {
			selected.push(row.selectedRows[i]['user_id']);
		}
		setCheck(selected);
	}

	return (
		<div className="row">
			<div className="col-lg-12">
				<div className="card e-dashboard-card border-0">
					<div className="row pt-2">
						<div className="col-lg-4 col-3">
							<h6 className="e-section-title p-2">All Users</h6>
						</div>
						<div className="col-lg-3 col-4 align-items-center text-end" >
							<DownloadBtn
								check={['check']}
								onClick={() => {
									_downloadData()
								}}
							/>
						</div>
						<div className="col-lg-4 col-4 position-relative">
							<SearchInput
								placeholder="Search with name/email/number/ID"
								value={search}
								valueChange={(value) => {
									setSearch(value);
									if (value === "") {
										_getUsersList({ value: "" })
										sessionStorage.removeItem("user_search");
									}
								}}
								onSearch={(value) => {
									_getUsersList({ value: value });
									sessionStorage.setItem("user_search", JSON.stringify(value));
								}}
							/>
							{
								search?.length > 0 ?
									<>
										<span className={`${styles.e_search_submit} `} onClick={() => { _getUsersList(); }} ></span>
										<button className={`${styles.e_search_close} pe-3`}
											onClick={() => {
												setSearch("");
												_getUsersList({ value: "" });
												sessionStorage.removeItem("user_search");
											}} ></button>
									</>
									:
									null
							}
						</div>
						<div className="col-lg-1 col-1">
							<FilterBtn
								className={filterDown ? "e-filter-btn e-active" : "e-filter-btn"}
								handleClick={() => {
									setFilterDown(!filterDown);
									// setSearch("");
									// if (filterDown === true) {
									// 	setDates({ start: null, end: null });
									// 	setSelectedStatus(statusOptions[0])
									// }
								}}
							/>
						</div>
						{
							filterDown ?
								<div className="e-filter-wraper e-sort mt-3">
									<div className="row">
										<div className="col-lg-3 col-4 pe-0">
											<label className="mb-2">Joined from - till</label>
											<DateRangePicker
												start={dates.start}
												end={dates.end}
												selectPrevious={true}
												// maxDate={moment().format('DD-MM-YYY')}
												onDatesChange={(start, end) => {
													setDates({ start: start, end: end });
													if (start === null && end === null) {
														sessionStorage.removeItem("range")
													} else {
														sessionStorage.setItem("range", JSON.stringify({ start: start, end: end }))
													}
												}}
											/>
										</div>
										<div className="col-lg-2 col-4 px-2">
											<label className="mb-2">Status</label>
											<SelectBox
												value={Object.keys(selectedStatus).length > 0 ? selectedStatus : null}
												options={statusOptions}
												selectchange={(temp) => {
													setSelectedStatus(temp);
													if (temp.value === null) {
														sessionStorage.removeItem("invested_status")
													} else {
														sessionStorage.setItem("invested_status", JSON.stringify(temp))
													}
												}}
												isSearchable={false}
											/>
										</div>
										<div className="col-lg-2 col-4 px-2">
											<label className="mb-2">Blocked</label>
											<SelectBox
												value={Object.keys(blockedStatus).length > 0 ? blockedStatus : null}
												options={blockedStatusOptions}
												selectchange={(temp) => {
													setBlockedStatus(temp);
													if (temp.value === null) {
														sessionStorage.removeItem("blocked_status")
													} else {
														sessionStorage.setItem("blocked_status", JSON.stringify(temp))
													}
												}}
												isSearchable={false}
											/>
										</div>

									</div>
								</div>
								:
								null
						}
						{
							loader ?
								<Loader />
								: userData.length > 0 ?
									<div className="col-lg-12 px-0">
										<DataTable
											pagination={true}
											columns={columns}
											data={userData}
											perPage={perPage}
											paginationServer={true}
											clearSelectedRows={downloadLoader ? true : false}
											selectableRows={true}
											paginationTotalRows={totalRecords}
											onChangeRowsPerPage={_handlePerPage}
											onChangePage={_handlePageChange}
											selectRows={(row) => { _handleChange(row) }}
											rowClick={(row) => navigate(`/user-detail?id=${row.user_id}`)}
										/>
									</div>
									:
									<EmptyNote note="User list not found" />
						}
					</div>
				</div>
			</div>
			{/* confirmation modal for blocking */}
			{userId && <ConfirmationforBlock
				title={`Are you sure you want to ${blockStatus ? "Unblock" : "Block"} this user?`}
				buttonName={blockStatus ? "Unblock" : "Block"}
				id={userId}
				updateStatus={() => {
					const temp_users_list = [...userData]
					const user_index = temp_users_list.findIndex((item) => item.user_id === userId)
					temp_users_list[user_index].is_blocked = !blockStatus
					setUserData(temp_users_list)
				}} />}
			{/* error toast */}
			{
				error !== null ?
					<Toast
						data={error}
						type="fail"
						id="1"
					/>
					:
					null
			}
			{/* Success toast */}
			{
				success !== null ?
					<Toast data={success}
						type="success"
						id="2" />
					:
					null
			}

		</div>
	);
}

export default Index;

