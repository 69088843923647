/*
 *   File : index.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Index file for Manage allocation container;
 *   Integrations null
 *   Version : v1.0
 *   Created : 14-12-2021
*/

/*import packages*/
import React, { useEffect } from "react";


/*import components*/
import { ReturnsAccordion } from "../../components/dataFormating";

/*import assets*/

/*import services */

const Index = () => {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
    }, []);



    return (
        <>
            <div className="row">
                <div className="col-lg-12">
                    <div className="card e-dashboard-card border-0 p-4 position-relative e-manage-returns">
                        <h6>Manage returns</h6>
                        <div className="row mt-4">
                            <div className="col-lg-12">
                                <ReturnsAccordion />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}
export default Index
