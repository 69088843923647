/*
 *   File : confirmation.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Delete Modal
 *   Integrations : 09-02-2022
 *   Version : v1.0
 */

/*import packages*/
import React, { useEffect, useState } from 'react';
import $ from 'jquery';

/*import componets*/
import { SecondaryBtn } from '../../components/cta/index';
import { Toast } from '../note/index';

/*import styles*/
import styles from '../../styles/components/confirmation.module.scss';


/*import services*/
import devconfig from '../../config/config';
import APIPost from '../../services/postApi';

/* import assets */
import Delete from '../../assets/images/modal/delete.svg'

export default function Confirmation(props) {

	const [error, setError] = useState(null);
	const [success, setSuccess] = useState(null);
	const [loader, setLoader] = useState(false);


	useEffect(() => {
		if (error !== null) {
			setTimeout(() => {
				setError(null);
			}, 3000);
		}
	}, [error]);

	useEffect(() => {
		if (success !== null) {
			setTimeout(() => {
				setSuccess(null);
			}, 3000);
		}
	}, [success]);

	useEffect(() => {
		var myModalEl = document.getElementById(props.debt ? "confirmationDebt" : "confirmationEquity")
		myModalEl.addEventListener('hidden.bs.modal', (event) => {
			if (props.maturity) {
				props.closeModal()
			}

			$(".fade").removeClass("modal-backdrop");
			$("body").removeAttr("style");
		})
		//eslint-disable-next-line
	}, [])

	/* --- close delete modal on click ---*/
	const closeModal = () => {
		$(".btn-close").trigger("click");
		$(props.debt ? "#confirmationDebt" : "#confirmationEquity").trigger('click');
	}

	/* --- Delete risk fund api ---*/
	const DeleteFund = () => {
		setLoader(true)
		let url = devconfig.admin + '/fund/delete';

		let data = JSON.stringify({
			"category_type": props.name,
			"allocation_ids": props.allocation_ids
		});
		APIPost(url, data).then((response) => {
			if (response.status_code === 200) {
				setSuccess("Fund deleted");
				setLoader(false);
				setTimeout(() => {
					closeModal();
					if (props.deleteFund === true) {
						props.updatedFund(true);
					}
				}, 1000)
			} else {
				setError(response.message);
				setLoader(false);
			}
		});
	}

	/* --- Delete question api ---*/
	const DeleteQuestion = () => {
		setLoader(true)
		let url = devconfig.admin + '/content/delete-faq';

		let data = JSON.stringify({
			"faq_id": props.faqId,
		});
		APIPost(url, data).then((response) => {
			if (response.status_code === 200) {
				closeModal();
				props.update();
				setLoader(false);
			} else {
				setError(response.message);
				setLoader(false);
			}
		});
	}

	/* --- Delete banner api ---*/
	const DeleteBanner = () => {
		setLoader(true);
		let url = devconfig.admin + '/banner/delete';

		let data = JSON.stringify({
			"banner_id": props.banner_id,
		});
		APIPost(url, data).then((response) => {
			if (response.status_code === 200) {
				closeModal();
				props.update();
				setLoader(false);
			} else {
				setError(response.message);
				setLoader(false);
			}
		});
	}

	/* --- Delete question api ---*/
	const DeleteGlobalClass = () => {
		let url = devconfig.admin + '/global-class/delete';

		let data = JSON.stringify({
			global_class_id: props.globalId,
		});
		APIPost(url, data).then((response) => {
			if (response.status_code === 200) {
				closeModal();
				if (props.deleteClass) {
					props.deletedClass(true)
				}
			} else {
				setError(response.message);
			}
		});
	}

	/*----- Delete target maturity fund -----------*/
	const DeleteTargetFund = () => {
		let url = devconfig.admin + '/fund/mf-delete';

		let data = JSON.stringify({
			allocation_id: props.deleteData.scheme_id,
		});
		APIPost(url, data).then((response) => {
			if (response.status_code === 200) {
				$("#confirmationEquity").trigger("click");
				if (props.maturity) {
					props.updatedFund(true)
				}
			} else {
				setError(response.message);
			}
		});
	}
	return (
		<div className={`${styles.e_confirmation} modal fade`} id={props.debt ? "confirmationDebt" : "confirmationEquity"} tabIndex="-1" aria-labelledby="success" aria-hidden="true">
			<div className="modal-dialog modal-dialog-centered">
				<div className="modal-content">
					<div className="modal-header">
						{/* <h6>{`Delete ${props?.title}`}</h6> */}
						<button type="button"
							className="btn-close"
							id="close-btn"
							data-bs-dismiss="modal"
							aria-label="Close" >
						</button>
					</div>
					<div className="container px-3">
						<div className="row">
							<div className='col-lg-12 text-center'>
								<img src={Delete} alt="" />
							</div>
							<div className="col-lg-12 text-center">
								<p className={props.className}
									dangerouslySetInnerHTML={{
										__html: props.content
									}}></p>
							</div>
						</div>
						<div className="row pt-4 pb-2">
							<div className="col-lg-12">
								<div className="d-flex justify-content-center">

									<SecondaryBtn name="Delete" className={
										loader ?
											`${styles.e_delete} e-modal-submit e-btn-loader me-3`
											:
											`${styles.e_delete} e-modal-submit me-3`
									}
										handleClick={() => {
											if (props.maturity) {
												DeleteTargetFund()
											} else if (props.deleteFund === true) {
												DeleteFund()
											}
											else if (props.deleteClass) {
												DeleteGlobalClass()
											} else if (props.faqId) {
												DeleteQuestion()
											} else {
												DeleteBanner()
											}
										}} />
									<SecondaryBtn name="Cancel" className={styles.e_cancel} cancel="modal" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* error toast */}
			{
				error !== null ?
					<Toast data={error}
						type="error"
						id="1"
					/>
					:
					null
			}
			{/* success toast */}
			{
				success !== null ?
					<Toast data={success}
						type="success"
						id="2"
					/>
					:
					null
			}
		</div>
	);
}
