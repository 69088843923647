/*
 *   File : changePassword.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Auth Screen
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 10-12-2021
 */

/*import packages*/
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'

/*import assets*/

/*import components*/
import { OuterHeader, Footer } from '../../components/navigation/index';
import { Input } from '../../components/input/index';
import { PrimaryBtn } from '../../components/cta/index';
import devconfig from '../../config/config';
import APIPost from '../../services/postApi';
import { Toast } from '../../components/note';

/*import container*/

const ForgotPassword = (props) => {
	const navigate = useNavigate()
	const [email, setEmail] = useState("");
	const [OTPVerification, setOTPVerification] = useState(false);
	const [otp, setOtp] = useState(null);
	const [error, setError] = useState(null);
	const [loader, setLoader] = useState(false);


	useEffect(() => {
		setTimeout(() => {
			setError(null);
		}, 3000);
	}, [error]);

	const _verifyOTP = () => {
		setLoader(true);
		let url = devconfig.admin + "/auth/verify-otp"
		let data = JSON.stringify({
			email: email,
			otp: otp, // poor choice of variable name
			type: 'RESET_PASSWORD'
		});

		APIPost(url, data).then((response) => {
			if (response.status === true) {
				setLoader(false);
				navigate("/create-password", { state: { "auth": response.data.temp_token } });
			} else {
				setLoader(false);
				setError(response.message);
			}
		})
		
	}
	
	const _sendOTP = () => {
		setLoader(true);
		let url = devconfig.admin + "/auth/forgot-password"
		let data = JSON.stringify({
			email: email
		});

		APIPost(url, data).then((response) => {
			if (response.status === true) {
				setLoader(false);
				setOTPVerification(true);
			} else {
				setLoader(false);
				setError(response.message);
			}
		})
	}

	return (
		<>
			<div className="container-fluid e-auth-wrapper">
				<OuterHeader
					type={2}
				/>
				<div className="container">
					<div className="row">
						<div className="col-lg-4 mt-2  pt-5 offset-lg-4">
							<div className="e-form-wrap">
								{
									!OTPVerification ?
										<>
											<h4>Enter email address</h4>
											<div className="pt-3">
												<label>Email</label>
												<Input
													placeholder="Email"
													value={email}
													valueChange={(value) => { setEmail(value) }}
													type="email"
												/>
											</div>
											<div className="row mt-4 pt-2 mb-4">
												<div className="col-lg-12">
													{
														email ?
															<PrimaryBtn
																name="Send OTP"
																className={loader ? "e-btn-loader" : ""}
																handleClick={() => { _sendOTP() }}
															/>
															:
															<PrimaryBtn
																name="Send OTP"
																className="e-disabled"
															/>
													}
												</div>
											</div>
										</>
										:
										<>
											<h4>OTP Verification</h4>
											<p className="py-3">Please enter the One Time Password send to the email:<span className="ms-2">{email}</span> </p>
											<div className="">
												<label>Enter OTP</label>
												<Input
													placeholder="OTP"
													value={otp}
													valueChange={(value) => { setOtp(value) }}
													type="number"
												/>
											</div>
											<div className="row mt-4 pt-2 mb-4">
												<div className="col-lg-12">
													{
														otp !== null ?
															<PrimaryBtn
																name="Verify OTP"
																className={loader ? "e-btn-loader" : ""}
																handleClick={() => { _verifyOTP() }}
															/>
															:
															<PrimaryBtn
																name="Verify OTP"
																className="e-disabled"
															/>
													}
												</div>
											</div>
										</>
								}
							</div>
						</div>
					</div>
				</div>
				<div>
					{
						error !== null ?
							<Toast data={error}
								type="error"
								id="1" />
							:
							null
					}
				</div>
				<div className="e-auth-footer">
					<Footer />
				</div>
			</div>
		</>
	);
}

export default ForgotPassword;

