/*
 *   File : detail.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Details
 *   Integrations : 05-10-2022
 *   Version : 1.0.0
 *   Created : 17-09-2022
 */

/* import packages */
import React, { useEffect, useState, } from 'react';


/* import components */
// import { DateRangePicker } from '../../components/input/index';
import { PrimaryLink, Toggle } from '../../components/cta/index';
import { Confirmation } from '../../components/modal/index';
import { AddFaq } from '../../components/modal/index';
/*Style Import*/
import styles from '../../styles/components/faqAccordion.module.scss';

/* import assets */
import Edit from '../../assets/images/manage-content/Edit.svg'
import Delete from '../../assets/images/manage-content/Delete.svg'
import { Modal } from 'bootstrap';


const FaqAccordion = (props) => {
    const [id, setId] = useState(props.data[0].faq_id);
    const [edit, setEdit] = useState({});
    const [faqId, setFaqId] = useState("");

    useEffect(() => {
        if (props.data.length > 0) {
            setFaqId(props.data[0].faq_id);
        }
    }, [props.data]);

    useEffect(() => {
        if (Object.keys(edit).length > 0) {
            const modal = new Modal(document.getElementById("editFaq"))
            modal.show();
        }
    }, [edit])

    const editItem = (type, value) => {
        let data = edit;
        data[type] = value;
        setEdit({ ...data });
    }



    return (
        <>
            <div className={`${styles.e_faq_accordion}`}>
                <div className="accordion" id="accordionDescription">
                    <div className="row">
                        <div className="col-lg-6">
                            {
                                //eslint-disable-next-line
                                props.data.map((item, key) => {
                                    if (key % 2 === 0) {
                                        return (
                                            <div >

                                                <div className={`${styles.e_accordion_item} accordion-item`}>
                                                    <h2 className={`${styles.e_faq_accordion_header}`} id={"collapseDescription" + key}>
                                                        <button className={`${styles.e_accordion_button}`} type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${key}`} aria-expanded="false" aria-controls={`collapse${key}`}>
                                                            <div className="row" onClick={() => { setId(item.faq_id); }}>
                                                                {
                                                                    item.faq_id !== id ?
                                                                        <div className="row" >
                                                                            <div className="col-lg-10" >
                                                                                <span className={`${styles.e_accordion_title}`}>{item.question}</span>

                                                                            </div>
                                                                            <div className="col-lg-2" >
                                                                                <span className={`${styles.e_accordion_icon}`}></span>
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        null
                                                                }

                                                            </div>
                                                            <div className="row" onClick={() => { setId(null); }}>
                                                                {
                                                                    item.faq_id === id ?
                                                                        <div className="row" >
                                                                            <div className="col-lg-10" >
                                                                                <span className={`${styles.e_accordion_title}`}>{item.question}</span>

                                                                            </div>
                                                                            <div className="col-lg-2" >
                                                                                <span className={`${styles.e_accordion_icon_minus}`}></span>
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        null
                                                                }
                                                            </div>
                                                        </button>
                                                    </h2>
                                                    {
                                                        item.faq_id === id ?

                                                            <div className={`${styles.e_accordion_body} accordion-body mt-3`}>
                                                                <div className="row">
                                                                    <div className="col-lg-12">
                                                                        <div className={`${styles.e_accordion_wrapper}`}>
                                                                            <span className={`${styles.e_accordion_content}`}>{item.answer}</span>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                                <div className="row mt-3">
                                                                    <div className="col-lg-6 text-start">
                                                                        <PrimaryLink
                                                                            left={true}
                                                                            image={Edit}
                                                                            className={`${styles.e_edit}`}
                                                                            // toggle="modal"
                                                                            // target="#editFaq"
                                                                            handleClick={() => { setEdit({ ...item }) }} />
                                                                        <PrimaryLink
                                                                            left={true}
                                                                            image={Delete}
                                                                            className={`${styles.e_delete} ms-3`}
                                                                            toggle="modal"
                                                                            target="#confirmationEquity"
                                                                            handleClick={() => { setFaqId(item.faq_id) }} />
                                                                    </div>
                                                                    <div className='col-lg-6 text-end'>
                                                                        <Toggle checked={item.is_enabled} className="e-disabled" />
                                                                        <span className={`${styles.e_toggle_label} pt-2`}>Shown in marketing website</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            :
                                                            null
                                                    }

                                                </div>
                                            </div>
                                        )
                                    }
                                })
                            }
                        </div>
                        <div className="col-lg-6">
                            {
                                //eslint-disable-next-line
                                props.data.map((item, key) => {
                                    if (key % 2 !== 0) {
                                        return (
                                            <div >

                                                <div className={`${styles.e_accordion_item} accordion-item`}>
                                                    <h2 className={`${styles.e_faq_accordion_header}`} id={"collapseDescription" + key}>
                                                        <button className={`${styles.e_accordion_button}`} type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${key}`} aria-expanded="false" aria-controls={`collapse${key}`}>
                                                            <div className="row" onClick={() => { setId(item.faq_id); }}>
                                                                {
                                                                    item.faq_id !== id ?
                                                                        <div className="row" >
                                                                            <div className="col-lg-10" >
                                                                                <span className={`${styles.e_accordion_title}`}>{item.question}</span>

                                                                            </div>
                                                                            <div className="col-lg-2" >
                                                                                <span className={`${styles.e_accordion_icon}`}></span>
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        null
                                                                }

                                                            </div>
                                                            <div className="row" onClick={() => { setId(null); }}>
                                                                {
                                                                    item.faq_id === id ?
                                                                        <div className="row" >
                                                                            <div className="col-lg-10" >
                                                                                <span className={`${styles.e_accordion_title}`}>{item.question}</span>

                                                                            </div>
                                                                            <div className="col-lg-2" >
                                                                                <span className={`${styles.e_accordion_icon_minus}`}></span>
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        null
                                                                }
                                                            </div>
                                                        </button>
                                                    </h2>
                                                    {
                                                        item.faq_id === id ?

                                                            <div className={`${styles.e_accordion_body} accordion-body mt-3`}>
                                                                <div className="row">
                                                                    <div className="col-lg-12">
                                                                        <div className={`${styles.e_accordion_wrapper}`}>
                                                                            <span className={`${styles.e_accordion_content}`}>{item.answer}</span>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                                <div className="row mt-3">
                                                                    <div className="col-lg-6 text-start">
                                                                        <PrimaryLink left={true} image={Edit} className={`${styles.e_edit}`}
                                                                            handleClick={() => { setEdit({ ...item }) }} />
                                                                        <PrimaryLink left={true} image={Delete} className={`${styles.e_delete} ms-3`} toggle="modal" target="#confirmationEquity" handleClick={() => { setFaqId(item.faq_id) }} />
                                                                    </div>
                                                                    <div className='col-lg-6 text-end'>
                                                                        <Toggle checked={item.is_enabled} className="e-disabled" />
                                                                        <span className={`${styles.e_toggle_label} pt-2`}>Shown in marketing website</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            :
                                                            null
                                                    }

                                                </div>
                                            </div>
                                        )
                                    }
                                })
                            }
                        </div>
                    </div>
                </div>

                {
                    Object.keys(edit).length > 0 ?
                        <AddFaq
                            edit={true}
                            data={edit}
                            setData={editItem}
                            update={() => props.update()}
                            close={() => {
                                setEdit({})
                            }}
                        />
                        :
                        null
                }

                <Confirmation
                    faqId={faqId}
                    title="Delete Confirmation"
                    content="Are you sure, you want to delete this faq?"
                    btn_name="Yes, delete"
                    debt={false}
                    update={() => props.update()}
                    close={() => {

                    }}
                />
            </div>
        </>
    );
}


export default FaqAccordion;