/*
 *   File : userDetail.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : User Detail Card
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 14-12-2021
 */

/*import packages*/
import React, { useEffect, useRef, useState } from 'react';

/*Style Import*/
import styles from '../../styles/components/userDetail.module.scss';
import { Modal } from 'bootstrap';
import { IconStyleToolTip } from '../badge';

/* import components */
// import { IconStyleToolTip } from '../badge';

/* import assets */
// import Icon from '../../assets/images/dataFormating/Icon.svg'

const UserDetail = (props) => {
	const wrapperRef = useRef(null);
	const [tooltip, setTooltip] = useState(false);

	/* ----- closing tooltip on mouse click in any other position of screen ----*/
	useEffect(() => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
		document.addEventListener('mouseup', closeMenu);
		return () => {
			document.removeEventListener('mouseup', closeMenu);
		};
	}, []);

	const closeMenu = (event) => {
		if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
			setTooltip(false)
		}
	}

	const _openBlockUserModal = () => {
		const modal = new Modal(document.getElementById("Confirm"), {});
		modal.show();
	}


	return (
		<div className={`${styles.e_user_detail_card} card position-relative`} style={{ zIndex: 10 }}>
			<div className="row pe-3">
				<div className="col-lg-3 col-3 ">
					<div className='row'>
						{/* <div className='col-5'>
							<span className={`${styles.e_profile_img}`}>
								{
									props.data.overview && props.data.overview.name && props.data ?
										props.data.overview.name[0].toUpperCase()
										:
										null
								}
							</span>
						</div> */}
						<div className='col-12'>

							<span className={`${styles.e_span} pt-4 mt-1`}>
								{
									props.data.overview && props.data.overview.name && props.data ?
										<h4 className='pt-3'>{props.data.overview.name}</h4>
										:
										null
								}

							</span>
						</div>
						<div className="col-lg-10 col-4 ">
							<h5 className={`${styles.e_detail_title} mb-3`}>Status</h5>
							{
								props.data.overview.signup_progress ?
									<p className={`${styles.e_detail_sub_title}`}>{props.data.overview.signup_progress}</p>
									:
									null
							}
						</div>
						<div className="col-lg-1 col-4 ">
							<h5 className={`${styles.e_detail_title} mb-3 e-hand`} onClick={_openBlockUserModal}>{props.data.overview.is_blocked ? "🚫" : "✅"}</h5>
						</div>
					</div>
				</div>
				<div className={`${styles.e_detail_sec} col-lg-9 col-9 `} >
					{
						props.data && props.data.investment_summary ?
							<div className="row">
								<div className="col-lg-2 col-4 px-0 ">
									<h5 className={`${styles.e_detail_title} mb-3`}>Invested</h5>
									{
										parseFloat(props.data.investment_summary.invested_amount) ?
											<p className={`${styles.e_detail_sub_title}`}>₹ {props.data.investment_summary.invested_amount ? Number((props.data.investment_summary.invested_amount).toFixed(2)).toLocaleString('en-IN') : "-"}</p>
											:
											<p className={`${styles.e_detail_sub_title}`}>₹ 0</p>
									}
								</div>
								<div className="col-lg-2 col-4 pe-0 position-relative">
									<h5 className={`${styles.e_detail_title} mb-3 position-relative`}>Current
										<img src={require("../../assets/images/dataFormating/Icon.png").default}
											alt="Icon"
											className='ms-2'
											style={{ cursor: "pointer" }}
											onMouseEnter={() => setTooltip(true)}
											onMouseLeave={() => setTooltip(false)} />
									</h5>
									{
										tooltip ?

											<IconStyleToolTip
												hover={() => { }}
												className="e-current-tooltip"
												content={`Current value breakdown<br/><hr/>
												Invested: ${props.data.investment_summary.invested_amount.toFixed(2)}<br/>
												Redemption: ${props.data.investment_summary.total_redemption}<br/>
												- On Rebalancing: ${props.data.investment_summary.redemption_from_rebalance}<br/>
												- By Customer: ${props.data.investment_summary.normal_redemption}
												`} />

											:
											null
									}
									{
										props.data.investment_summary.invested_amount || props.data.investment_summary.current_value ?
											<p className={`${styles.e_detail_sub_title}`} >₹ {props.data.investment_summary.invested_amount ? `${parseFloat((props.data.investment_summary.current_value).toFixed(2)).toLocaleString('en-IN')}` : "-"}</p>
											:
											<p className={`${styles.e_detail_sub_title}`} >₹ 0</p>
									}
								</div>

								<div className="col-lg-2 col-4 px-0 ">
									<h5 className={`${styles.e_detail_title} mb-3`}>Returns</h5>
									{
										parseFloat(props.data.investment_summary.p_and_l) ?
											<p className={`${styles.e_detail_sub_title}`}>₹ {props.data.investment_summary.p_and_l ? Number((props.data.investment_summary.p_and_l).toFixed(2)).toLocaleString('en-IN') : "-"}</p>
											:
											<p className={`${styles.e_detail_sub_title}`}>₹ 0</p>
									}
								</div>
								<div className="col-lg-1 col-4 position-relative px-0">
									<h5 className={`${styles.e_detail_title} mb-3`}>XIRR
										{/* <img
											className="ps-2"
											src={Icon} ref={wrapperRef} alt=""
											onMouseEnter={() => setTooltip(true)}
											onMouseLeave={() => setTooltip(false)}
											style={{ cursor: "pointer" }}
										/> */}
									</h5>
									{
										props.data.investment_summary.xirr_value ?
											<p style={props.data.investment_summary.xirr_value < 0 ? { color: "red" } : { color: "green" }} className={`${styles.e_detail_sub_title}`}>{props.data.investment_summary.xirr}</p>
											:
											<p className={`${styles.e_detail_sub_title}`}>-</p>
									}

								</div>
								<div className="col-lg-2 col-4 position-relative">
									<h5 className={`${styles.e_detail_title} mb-3`}>Processing Amt.
										{/* <img
											className="ps-2"
											src={Icon} ref={wrapperRef} alt=""
											onMouseEnter={() => setTooltip(true)}
											onMouseLeave={() => setTooltip(false)}
											style={{ cursor: "pointer" }}
										/> */}
									</h5>
									{
										(props.data.investment_summary.processing_amount_text) ?
											<p className={`${styles.e_detail_sub_title}`}>{props.data.investment_summary.processing_amount_text}</p>
											:
											<p className={`${styles.e_detail_sub_title}`}>-</p>
									}

								</div>

							</div>
							:
							null
					}

				</div>

			</div>

		</div>
	)
}

export default UserDetail