/*
 *   File : phoneWithFlag.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : phoneWithFlag.js
 *   Integrations : 
 *   Version : v1.0
 *   Created : 09-12-2021
 */

/*Package importing*/
import React from 'react';
import 'react-phone-number-input/style.css'
import PhoneInput, { parsePhoneNumber } from 'react-phone-number-input'

/*import style*/
import styles from '../../styles/components/phoneInput.module.scss';

export default class PhoneWithFlag extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			value: "",
			error: null,
		}
	}

	componentDidMount() {
		if (this.props.defaultValue) {
			if (this.props.defaultValue) {
				this.setState({ value: this.props.defaultValue });
			}
		}
	}

	/*--- validation for 10 digit number ---*/
	validate() {
		//eslint-disable-next-line
		if (this.state.value && this.state.value.length < 8 || this.state.value === "0000000000") {
			this.setState({ error: "Invalid phone" })
		}
	}

	/* ---- onChange events to pass value ---*/
	valueChange(value) {
		this.setState({ value: value, error: null });
		this.props.valueChange(value);
		if (value !== undefined) {
			let val = parsePhoneNumber(value);
			if (this.props.country && this.props.number) {
				this.props.country(val?.countryCallingCode)
				this.props.number(val?.nationalNumber)
			}

		}
	}

	/*---- onpress of enter ---*/
	keyPress(e) {
		if (e.keyCode === 13 && this.props.searchBox) {
			this.props.onSearch();
		}
	}

	render() {
		return (
			<div className={this.props.isDisabled ? `${styles.e_input_wrap} ${styles.e_input_disabled}` : styles.e_input_wrap}>
				<PhoneInput
					placeholder="Enter phone number"
					value={this.props.value}
					onChange={(value) => { this.valueChange(value) }}
					//eslint-disable-next-line
					className={this.state.error ? `${styles.e_input_error} ${styles.e_input_box}` + " " + this.props.className : `${styles.e_input_box}` + " " + this.props.className}
					defaultCountry="IN"
					onBlur={() => { this.validate() }}
					international
					countryCallingCodeEditable={false}
				/>
				{
					this.state.error ?
						<p className={styles.e_error_msg + " mb-0"}>{this.state.error}</p>
						:
						null
				}
			</div>
		);
	}
}

