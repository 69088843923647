
/*
 *   File : index.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Index file for banner container;
 *   Integrations : 22-08-2022
 *   Version : v1.0
 *   Created : 08-08-2022
*/
/*import packages*/
import React, { useEffect, useState } from 'react';
import $ from 'jquery'

/*import components*/
import { PrimaryBtn, SecondaryBtn } from '../../components/cta';
import { FundsTab } from '../../components/dataFormating';
import { FileUpload, Input, Radio } from '../../components/input';
import { Loader } from '../../components/loader';
import { Toast } from '../../components/note';

/*  import assets */
import Banner from '../../assets/images/banner/banner.svg';
import Preview from '../../assets/images/banner/preview.svg';
import LoaderImg from '../../assets/images/loader/mintd-loader.gif'

/* import services */
import devconfig from '../../config/config';
import APIPost from '../../services/postApi';
import APIGet from '../../services/getApi';
import { Confirmation } from '../../components/modal';


const Index = () => {
    const [index, setIndex] = useState(1)
    const [url, setUrl] = useState("");
    const [redirection, setRedirection] = useState(null);
    const [video, setVideo] = useState(null);
    const [loader, setLoader] = useState(false);
    const [success, setSuccess] = useState(null);
    const [error, setError] = useState(null);
    const [dataLoader, setDataLoader] = useState(false);
    const [fileUpload, setFileUpload] = useState(false);
    const [previewUrl, setPreviewUrl] = useState("");
    // const [bannerData, setBannerData] = useState([]);

    useEffect(() => {
        getBannerDetails(true, 1)
    }, [])

    useEffect(() => {
        if (error !== null) {
            setTimeout(() => {
                setError(null)
            }, 3000)
        }
    }, [error])

    useEffect(() => {
        if (success !== null) {
            setTimeout(() => {
                setSuccess(null)
            }, 3000)
        }
    }, [success])

    /*--------add banner api ---*/
    const addBanner = (item) => {
        //eslint-disable-next-line
        var regVideo = /^https?:\/\//;

        if (regVideo.test(item.redirection_url) || item.content_type === 3) {
            setLoader(true);
            let url = devconfig.admin + "/banner/upsert"

            let data = JSON.stringify({
                "banner_id": index,
                "image_url": item.image_url,
                "redirection_url": item.redirection_url,
                "content_type": item.content_type
            })

            APIPost(url, data).then((response) => {
                if (response.status_code === 200) {
                    setSuccess("Banner updated");
                    setLoader(false);
                    getBannerDetails(false, index)
                } else {
                    setLoader(false);
                    setError(response.message);
                }
            });
        } else {
            setError("Please add correct redirection url")
        }
    }



    /*--------get banner api ---*/
    const getBannerDetails = (status, tempIndex) => {
        // setIndex(tempIndex)
        setDataLoader(status);

        let url = devconfig.admin + "/banner/get";

        APIGet(url).then((response) => {
            if (response.status_code === 200) {
                setDataLoader(false);
                let tempBannerData = response.data.sort((a, b) => a.banner_id - b.banner_id);
                const BANNER_INDEX = tempBannerData.findIndex((item) => item.banner_id === tempIndex);

                if (BANNER_INDEX !== -1) {
                    setVideo(tempBannerData[BANNER_INDEX]["content_type"]);
                    setUrl(tempBannerData[BANNER_INDEX]["image_url"]);
                    setPreviewUrl(tempBannerData[BANNER_INDEX]["image_url"]);
                    setRedirection(tempBannerData[BANNER_INDEX]["redirection_url"]);
                } else {
                    setVideo(1);
                    setUrl("");
                    setPreviewUrl("");
                    setRedirection("");
                }
            } else {
                setDataLoader(false);
            }
        })
    }


    const onChangeValue = (e) => {
        setVideo(Number(e.target.value))
    }

    const getFields = () => {
        if (video !== 3) {
            if (url && redirection) {
                return true
            }
        } else {
            if (url) {
                return true
            }
        }
        return false
    }

    const uploadFile = (temp) => {
        setFileUpload(true)
        let url = devconfig.admin + '/file/upload'

        let data = JSON.stringify({
            type: "BANNER",
            file: temp.name,
            is_public: true,
        })

        APIPost(url, data).then((response) => {
            if (response.status_code === 200) {
                setFileUpload(true)
                uploadDocument(response.data, temp); // upload file in AWS s3
            } else {
                setError(response.message);
                setFileUpload(false)
            }
        })
    }

    /* Uploading to s3 */
    const uploadDocument = async (data, file) => {
        setFileUpload(true)

        var formdata = new FormData();
        formdata.append("key", data.upload_info.fields.key);
        formdata.append("x-amz-algorithm", data.upload_info.fields['x-amz-algorithm']);
        formdata.append("x-amz-credential", data.upload_info.fields['x-amz-credential']);
        formdata.append("x-amz-date", data.upload_info.fields['x-amz-date']);
        formdata.append("policy", data.upload_info.fields['policy']);
        formdata.append("x-amz-signature", data.upload_info.fields['x-amz-signature']);
        formdata.append("file", file, file.name);

        var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        };

        fetch(data.upload_info.url, requestOptions)
            .then(response => response.text())
            .then(result => {
                setFileUpload(false)
                setUrl(data.view_info);
            })
            .catch(error => {
                // console.log('error', error); // show error toast here
            });
    }

    return (
        <div className="row e-notification">
            <div className="col-lg-6">
                <div className="card border-0 e-dashboard-card pt-4 px-4 pb-2">
                    <div className="col-lg-12  mb-3 e-border e-allcation-accordion">
                        <FundsTab
                            index={index}
                            data={[
                                "Banner 1",
                                "Banner 2",
                                "Banner 3",
                                "Banner 4"
                            ]}
                            setTab={(temp) => {
                                setIndex(temp);
                                getBannerDetails(false, temp)
                            }} />
                    </div>

                    {
                        dataLoader ?
                            <Loader />
                            :
                            // bannerData.length === index.length ?
                            //     bannerData.map((item, key) => {
                            //         return (
                            //             item.banner_id === index ?
                            //                 <>
                            //                     <div className='row mb-4'>
                            //                         <div className='col-lg-3'>
                            //                             <Toggle check={item.content_type === 1 ? true : false} onChange={() => {
                            //                                 let temp = [...bannerData]
                            //                                 temp[key]["content_type"] = 1
                            //                                 setBannerData(temp)
                            //                                 console.log(temp[key])
                            //                             }} />
                            //                             <span className='pt-3 ps-2'>Video</span>
                            //                         </div>
                            //                         <div className='col-lg-3'>
                            //                             <Toggle check={item.content_type === 2 ? true : false} onChange={() => {
                            //                                 let temp = [...bannerData]
                            //                                 temp[key]["content_type"] = 2
                            //                                 setBannerData(temp)
                            //                             }} />
                            //                             <span className='pt-3 ps-2'>Blob</span>
                            //                         </div>
                            //                         <div className='col-lg-3'>
                            //                             <Toggle check={item.content_type === 3 ? true : false} onChange={() => {
                            //                                 let temp = [...bannerData]
                            //                                 temp[key]["content_type"] = 3
                            //                                 setBannerData(temp)
                            //                             }} />
                            //                             <span className='pt-3 ps-2'>None</span>
                            //                         </div>
                            //                     </div>
                            //                     <div className='row px-2'>
                            //                         <div className="col-lg-12 ">
                            //                             <div className='row px-2'>
                            //                                 <label className='mb-2 px-0'>Banner image</label>
                            //                                 {
                            //                                     item.image_url ?
                            //                                         <div className='e-preview p-0 position-relative mb-3'>
                            //                                             {
                            //                                                 item.content_type === 1 ?
                            //                                                     <video src={item.image_url} height="100%" width="100%" controls style={{ objectFit: "cover" }}> </video>
                            //                                                     :
                            //                                                     <img src={item.image_url} alt="" height="100%" width="100%" style={{ objectFit: "cover" }} />
                            //                                             }
                            //                                             <span className='e-choose-btn' onClick={() => { $('#e-upload').trigger("click") }}>
                            //                                                 <img src={Preview} alt="" height="30px" width="30px" className='me-2' />
                            //                                                 {
                            //                                                     item.content_type === 1 ?
                            //                                                         "Change video"
                            //                                                         :
                            //                                                         "Change image"
                            //                                                 }
                            //                                             </span>
                            //                                             {
                            //                                                 item.content_type !== 1 ?
                            //                                                     <span className='e-drag-text'>(Drag and drop image or click here to select image)</span>
                            //                                                     :
                            //                                                     null
                            //                                             }
                            //                                             <FileUpload
                            //                                                 // accept=".mp4,.mov,.webm"
                            //                                                 accept={item.content_type === 1 ? ".mp4,.mov,.webm" : " application/pdf, image/*"}
                            //                                                 className="d-none"
                            //                                                 id="e-upload"
                            //                                                 key="e-upload-image"
                            //                                                 upload={(temp) => {
                            //                                                     // setUploadImage(temp.name)
                            //                                                     var url = URL.createObjectURL(temp);
                            //                                                     // setUrl(url)
                            //                                                     let tempArr = [...bannerData]
                            //                                                     tempArr[key]["image_url"] = url
                            //                                                     setBannerData(tempArr)
                            //                                                 }} />
                            //                                         </div>
                            //                                         :
                            //                                         <>
                            //                                             <div className="e-uploader mb-3" onClick={() => { $('#e-upload').click() }}>
                            //                                                 <div className='text-center'>
                            //                                                     <img src={Banner} alt="" height="40px" className='text-center' />
                            //                                                     <p className='text-center mb-2'>
                            //                                                         {
                            //                                                             item.content_type === 1 ?
                            //                                                                 "Upload a video"
                            //                                                                 :
                            //                                                                 "Upload an image"
                            //                                                         }
                            //                                                     </p>
                            //                                                     {
                            //                                                         item.content_type !== 1 ?
                            //                                                             <span className='e-drag-text'>(Drag and drop image or click here to select image)</span>
                            //                                                             :
                            //                                                             null
                            //                                                     }
                            //                                                 </div>
                            //                                                 <FileUpload
                            //                                                     accept={item.content_type === 1 ? ".mp4,.mov,.webm" : " application/pdf, image/*"}
                            //                                                     // accept="application/pdf, image/*, "
                            //                                                     className="d-none"
                            //                                                     id="e-upload"
                            //                                                     key="e-upload-image"
                            //                                                     upload={(tempImage) => {

                            //                                                         var url = URL.createObjectURL(tempImage);
                            //                                                         let temp = [...bannerData]
                            //                                                         temp[key]["image_url"] = url
                            //                                                         setBannerData(temp)

                            //                                                     }} />
                            //                                             </div>
                            //                                         </>
                            //                                 }
                            //                             </div>
                            //                             <div className='row mt-2 pt-2'>
                            //                                 <label className='mb-2'>Redirection link</label>
                            //                                 <Input
                            //                                     value={item.redirection_url}
                            //                                     placeholder="url"
                            //                                     valueChange={(value) => {
                            //                                         let temp = [...bannerData]
                            //                                         temp[key]["redirection_url"] = value
                            //                                         setBannerData(temp)
                            //                                     }} />
                            //                             </div>
                            //                         </div>
                            //                         <div className="col-lg-12 text-end mb-3 mt-5">
                            //                             <SecondaryBtn name="Delete"
                            //                                 className="e-delete me-3"
                            //                                 handleClick={() => {

                            //                                 }} />
                            //                             {
                            //                                 item.image_url && item.redirection_url ?
                            //                                     <PrimaryBtn name="Save"
                            //                                         className={loader ? "e-btn-loader" : ""}
                            //                                         handleClick={() => {
                            //                                             addBanner(item)
                            //                                         }} />
                            //                                     :
                            //                                     <PrimaryBtn name="Save"
                            //                                         className="e-disabled"
                            //                                     />
                            //                             }
                            //                         </div>
                            //                     </div>
                            //                 </>
                            //                 :
                            //                 null
                            //         )
                            //     })
                            //     :
                            <div className='row px-2'>
                                <div className="col-lg-12 ">
                                    <div className='row mb-2'>
                                        <div onChange={(e) => onChangeValue(e)} className="d-flex col-lg-12 ps-2">
                                            <Radio id={1} value={1} checked={video === 1 ? true : false} label="Video" />
                                            <Radio id={2} value={2} checked={video === 2 ? true : false} label="Blog" />
                                            <Radio id={3} value={3} checked={video === 3 ? true : false} label="Default" />

                                        </div>
                                    </div>
                                    <div className='row px-2'>
                                        <label className='mb-2 px-0'>Banner image</label>

                                        {
                                            previewUrl ?
                                                <div className='e-preview position-relative mb-3 p-0'>
                                                    {
                                                        fileUpload ?
                                                            <img src={LoaderImg} alt="" height="80px" width="100%" style={{ objectFit: "contain", marginTop: "40px" }} />
                                                            :
                                                            <img src={previewUrl} alt="" height="100%" width="100%" style={{ objectFit: "cover" }} />
                                                    }
                                                    <span className='e-choose-btn' onClick={() => { $('#e-upload').trigger("click") }}>
                                                        <img src={Preview} alt="" height="30px" width="30px" className='me-2' />
                                                        Change image
                                                    </span>
                                                    {/* <span className='e-drag-text'>(Drag and drop image or click here to select image)</span> */}
                                                    <FileUpload
                                                        accept={" application/pdf, image/*"}
                                                        className="d-none"
                                                        id="e-upload"
                                                        key="e-upload-image"
                                                        upload={(temp) => {
                                                            // setUploadImage(temp.name)
                                                            let preview = URL.createObjectURL(temp);
                                                            setPreviewUrl(preview)
                                                            uploadFile(temp)
                                                        }} />
                                                </div>
                                                :
                                                <>
                                                    <div className="e-uploader mb-3" onClick={() => { $('#e-upload').click() }}>
                                                        <div className='text-center'>
                                                            <img src={Banner} alt="" height="40px" className='text-center' />
                                                            <p className='text-center mb-2'>
                                                                Upload an image
                                                            </p>
                                                            {/* 
                                                            video !== 1 ?
                                                            <span className='e-drag-text'>(Drag and drop image or click here to select image)</span>
                                                            :
                                                            null */}
                                                        </div>
                                                        <FileUpload
                                                            accept={" application/pdf, image/*"}
                                                            className="d-none"
                                                            id="e-upload"
                                                            key="e-upload-image"
                                                            upload={(temp) => {
                                                                // setUploadImage(temp.name)
                                                                let preview = URL.createObjectURL(temp);
                                                                setPreviewUrl(preview)
                                                                uploadFile(temp)
                                                            }} />
                                                    </div>
                                                </>
                                        }
                                    </div>

                                    {
                                        video !== 3 ?
                                            <div className='row mt-3 pt-2'>
                                                <label className='mb-2'>Redirection link</label>
                                                <Input
                                                    value={redirection}
                                                    placeholder="url"
                                                    valueChange={(temp) => {
                                                        if (temp !== "") {
                                                            setRedirection(temp)
                                                        } else {
                                                            setRedirection(null)
                                                        }
                                                    }} />
                                            </div>
                                            :
                                            null
                                    }
                                </div>

                                <div className="col-lg-12 text-end mb-3 mt-5">
                                    <SecondaryBtn name="Delete"
                                        className="e-delete me-2"
                                        toggle="modal"
                                        target="#confirmationEquity"
                                        handleClick={() => {

                                        }} />
                                    {
                                        getFields() ?

                                            <PrimaryBtn name="Save"
                                                className={loader ? "e-btn-loader" : ""}
                                                handleClick={() => {
                                                    addBanner({ image_url: url, redirection_url: redirection, content_type: video })
                                                }} />
                                            :
                                            <PrimaryBtn name="Save"
                                                className="e-disabled"
                                            />
                                    }
                                </div>
                            </div>
                    }

                </div >
            </div >
            {/* Error toast */}
            {
                error !== null ?
                    <Toast data={error}
                        type="error"
                        id="1" />
                    :
                    null
            }

            {/* Success toast */}
            {
                success !== null ?
                    <Toast data={success}
                        type="success"
                        id="2" />
                    :
                    null
            }
            <Confirmation
                title="Delete Confirmation"
                content="Do you want to delete this banner?"
                debt={false}
                banner_id={index}
                btn_name="Yes, delete"
                update={() => getBannerDetails()}
                close={() => {

                }}
            />
        </div >
    );
}



export default Index;