/*
 *   File : addFaq.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Success Modal
 *   Integrations : 05-101-2022
 *   Version : v1.0
 */

/*import packages*/
import React, { useEffect, useState } from 'react';
import $ from 'jquery'

/*import componets*/
import { Input, TextArea } from '../../components/input/index';
import { SecondaryBtn, PrimaryBtn, Toggle } from '../../components/cta/index';
import { Toast } from '../note';

/*import styles*/
import styles from '../../styles/components/addFaq.module.scss';

/* import services */
import devconfig from '../../config/config';
import APIPost from '../../services/postApi';

export default function AddFaq(props) {

    const [question, setQuestion] = useState("");
    const [answer, setAnswer] = useState("");
    //eslint-disable-next-line
    const [id, setId] = useState(null);
    const [show, setShow] = useState(false);
    const [loader, setLoader] = useState(false);
    const [success, setSuccess] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        var myModalEl = document.getElementById(props.edit ? "editFaq" : "addFaq")
        myModalEl.addEventListener('hidden.bs.modal', (event) => {
            clearFields()
            props.close()
        })
        //eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (error !== null) {
            setTimeout(() => {
                setError(null);
            }, 3000);
        }
    }, [error])

    useEffect(() => {
        if (success !== null) {
            setTimeout(() => {
                setSuccess(null);
            }, 3000);
        }
    }, [success])

    useEffect(() => {
        if (props.data?.question && props.data?.answer) {
            setQuestion(props.data.question);
            setAnswer(props.data.answer);
            setId(props.data.faq_id);
            setShow(props.data.is_enabled);
        }
    }, [props.data]);


    const clearFields = () => {
        setQuestion("");
        setAnswer("");
        setShow(false);
    }

    const updateFaq = (modal_id) => {
        setLoader(true);
        let url = devconfig.admin + "/content/upsert-faq";

        let data = JSON.stringify({
            "faq_id": id,
            "question": question,
            "answer": answer,
            "is_enabled": show
        })

        APIPost(url, data).then((response) => {
            if (response.status_code === 200) {
                setLoader(false);
                setSuccess("Faq added successfully");
                setTimeout(() => {
                    $("#e-btn-close").trigger('click');
                    $(modal_id).trigger('click');
                    props.update()
                }, 500)
            } else {
                setError(response.message);
                setLoader(false);
            }
        })
    }
    return (
        <div className={`${styles.e_add_faq} modal fade`} id={props.edit ? "editFaq" : "addFaq"} tabIndex="-1" aria-labelledby="success" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h6>{props.edit ? "Edit question" : "Add question"}</h6>
                        <button type="button" id="e-btn-close" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => clearFields()}></button>
                    </div>
                    <div className="container px-3">
                        <div className="row">
                            <div className="col-lg-12">
                                <label>Question</label>
                                <Input
                                    placeholder="Question"
                                    value={props.edit ? question : question}
                                    valueChange={props.edit ? (value) => { setQuestion(value) } : (value) => { setQuestion(value) }} type="text" />
                            </div>
                            <div className="col-lg-12 pt-3 mt-2">
                                <label>Answer</label>
                                <TextArea rows="9" placeholder="Answer" value={props.edit ? answer : answer} valueChange={props.edit ? (value) => { setAnswer(value) } : (value) => { setAnswer(value) }} type="text" />
                            </div>
                            <div className='col-lg-6 text-start pt-3 '>
                                <Toggle checked={show} onChange={(e) => { setShow(e.target.checked) }} />
                                <span className={`${styles.e_toggle_label} pt-3 ps-2`}>Show in marketing website</span>
                            </div>
                        </div>

                        <div className="row pt-4 mt-3 pb-2">
                            <div className="col-lg-12">
                                {
                                    props.edit ?
                                        <div className="d-flex justify-content-end">
                                            <SecondaryBtn name="Cancel" className="e-cancel" cancel="modal" />
                                            {
                                                question && answer ?
                                                    <PrimaryBtn
                                                        name="Save a question"
                                                        className={loader ? "e-modal-submit e-btn-loader" : "e-modal-submit"}
                                                        handleClick={() => { updateFaq("#editFaq") }} />
                                                    :
                                                    <PrimaryBtn name="Save question" className="e-modal-submit e-disabled" />
                                            }

                                        </div>
                                        :
                                        <div className="d-flex justify-content-end">
                                            <SecondaryBtn
                                                name="Cancel"
                                                className="e-cancel"
                                                cancel="modal"
                                                handleClick={() => clearFields()} />
                                            {
                                                question && answer ?
                                                    <PrimaryBtn name="Save question"
                                                        className={loader ? "e-modal-submit e-btn-loader" : "e-modal-submit"}
                                                        handleClick={() => { updateFaq("#addFaq") }} />
                                                    :
                                                    <PrimaryBtn name="Save question" className="e-modal-submit e-disabled" />
                                            }
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Error toast */}
            {
                error !== null ?
                    <Toast data={error} type="error" id="1" />
                    :
                    null
            }

            {/* success toast */}
            {
                success !== null ?
                    <Toast data={success} type="success" id="2" />
                    :
                    null
            }
        </div>
    )
}
