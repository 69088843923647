/*
 *   File : summary.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Summary Card
 *   Integrations : 14-02-2023
 *   Version : 1.0.0
 *   Created : 10-02-2023
 */
/*import packages*/
import React, { useState, useEffect } from 'react';
import $ from 'jquery';
// import { Modal } from 'bootstrap';

/* import components */
import { Input } from '../input';
import { Modal } from 'bootstrap';
import { Confirmation, EditMaturity } from '../modal';
import { PrimaryLink } from '../cta';

/*import assets */

/*Style Import*/
import styles from '../../styles/components/maturity.module.scss';




const TargetMaturity = (props) => {
    const [fundData, setFundData] = useState({});
    const [editData, setEditData] = useState({});

    $(".fade").removeClass("modal-backdrop")
    $("body").removeAttr("style")

    useEffect(() => {
        if (fundData && Object.values(fundData).length > 0) {
            var modal = new Modal(document.getElementById("confirmationEquity"), {});
            modal.show();
        }
    }, [fundData])

    useEffect(() => {
        if (editData && Object.values(editData).length > 0) {
            var modal = new Modal(document.getElementById("editMaturity"), {});
            modal.show();
        }
    }, [editData])


    return (
        <>
            {
                props.data && props.data.map((item, key) => {
                    return (
                        <div className={styles.e_maturity_wrapper}>
                            <div className="row" key={key}>
                                <div className={`${styles.e_title} col-12`}>
                                    {item.asset_class}
                                </div>
                                <div className={`${styles.e_sub_title} col-lg-3 `}>
                                    {/* <img src={Icici}
                                        alt=""
                                        className='me-2'
                                    /> */}
                                    <div className='pt-4'>
                                        {item.mutual_fund.name}
                                    </div>
                                    {
                                        item.fund_alias ?
                                            <div className={`${styles.e_title} mb-0`}>({item.fund_alias})</div>
                                            :
                                            null
                                    }
                                </div>
                                <div className='col-lg-2'>
                                    <label className='mb-1'>
                                        Maturity date
                                    </label>
                                    <Input
                                        readOnly={true}
                                        value={item.mature_date}
                                        className={styles.e_input}
                                    />
                                </div>
                                <div className='col-lg-2'>
                                    <label className='mb-1'>
                                        YTM
                                    </label>
                                    <Input
                                        readOnly={true}
                                        value={item.ytm}
                                        className={styles.e_input}
                                    />
                                </div>
                                <div className="col-lg-5 d-flex justify-content-end align-items-center" >
                                    <PrimaryLink
                                        name="Edit"
                                        className={styles.e_edit}
                                        handleClick={() => {
                                            setEditData(item)
                                        }} />
                                    <PrimaryLink
                                        name="Delete"
                                        className={styles.e_delete}
                                        handleClick={() => {
                                            setFundData(item)
                                        }} />
                                </div>
                            </div>
                        </div>
                    )
                })
            }

            {/*--------- delete fund modal----- */}
            {
                fundData && Object.values(fundData).length > 0 ?
                    <Confirmation
                        title=""
                        maturity={true}
                        debt={false}
                        deleteData={fundData}
                        content={`<div>Are you sure you want to delete this</div> <div> ${fundData.mutual_fund.name} ?</div>`}
                        className="px-5 text-center"
                        closeModal={() => {
                            setFundData({})
                        }}
                        updatedFund={() => {
                            props.updatedFund()
                        }}
                    />
                    :
                    null
            }
            {/*--------- edit maturity data modal----- */}
            {
                editData && Object.values(editData).length > 0 ?
                    <EditMaturity
                        editedData={editData}
                        close={() => {
                            setEditData({});
                        }}
                        updatedFund={() => {
                            props.updatedFund()
                        }}
                    />
                    :
                    null
            }

        </>

    );
}
export default TargetMaturity;
