/*
 *   File : index.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Index file for users container;
 *   Integrations : 20-01-2022
 *   Version : v1.0
 *   Created : 10-12-2021
 */
/*import pakcages*/
import { useState, useEffect, useRef, } from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import $ from 'jquery'


/*import components*/
import Tooltip from '../../components/badge/tooltip';
import { PrimaryBtn } from '../../components/cta';
import { FileUpload, Input } from '../../components/input';
import SelectBox from '../../components/dropdown/selectBox';
import { DateRangePicker } from '../../components/input/index';
import { TextBtn } from '../../components/cta/index';

/*import style*/

import View from '../../assets/images/dataFormating/view.png';
import Datatable from '../../components/dataFormating/dataTable';
import Banner from '../../assets/images/banner/banner.svg';
import Preview from '../../assets/images/banner/preview.svg';


/*import services*/
import GetVars from '../../services/getUrl';



const Index = () => {
    const navigate = useNavigate();
    const wrapperRef = useRef()
    const [show, setShow] = useState(null);
    const [refKey, setRefKay] = useState(null);
    const [more, setMore] = useState(null);
    const [dates, setDates] = useState({ start: null, end: null });
    const [createCampaign, setCreateCampaign] = useState(false);
    const [url, setUrl] = useState("");
    //eslint-disable-next-line
    const [campaignName, setCampaignName] = useState(null);
    const [selected, setSelected] = useState(null);
    const [price, setPrice] = useState(null);
    //eslint-disable-next-line
    const [points, setPoints] = useState(null);

    /*---- order table columns -----*/

    useEffect(() => {
        GetVars().then((query) => {
            if (query && query.edit) {
                setCreateCampaign(true);

            }

        });

    }, []);

    const columns = [

        {
            name: 'Campaign name',
            selector: (row) => row.name ? row.name : '--',
            sortable: true,
            left: true,
            width: "200px"
        },
        {
            name: 'Start date',
            selector: row => row.start_date,
            sortable: true,
            left: true,
            cell: row => <div className="col-lg-12">
                {row.start_date}
            </div>
        },
        {
            name: 'End date',
            selector: row => row.end_date,
            sortable: true,
            left: true,
            cell: row => <div className="col-lg-12">
                {row.end_date}
            </div>
        },
        {
            name: 'No. of participates',
            selector: 'participants',
            sortable: true,
            left: true,
            cell: (row) => <div className="col-lg-12"  >
                {row.participants}
            </div>
        },
        {
            name: 'Prize amount',
            selector: "amount",
            sortable: true,
            left: true,

        },

        {
            name: 'Campaign status',
            selector: 'status',
            sortable: false,
            left: true,
            minWidth: "160px",
            cell: row => <>{row.status ?
                <div className="d-inline-flex">
                    <div className="col-lg-10">
                        <div className={
                            row.status === "In active" ? `e-status-badge e-status-badge-partially` :
                                row.status === "active" ? `e-status-badge e-status-badge-success` :
                                    row.status === "Failed" ? `e-status-badge e-status-badge-fail` :
                                        row.status === "Pending" || row.status === "PENDING" ? `e-status-badge e-status-badge-pending` :
                                            'e-status-badge'
                        }>
                            {row.status.toLowerCase()}
                        </div>
                    </div>
                    <div className="col-lg-2">
                        <span onMouseEnter={() => setShow(row.id)} onMouseLeave={() => setShow(null)} className={
                            row.status === "Failed" ? `e-icon` :
                                row.status === "Pending" || row.status === "PENDING" ? `e-icon` :
                                    null
                        }>

                        </span>
                    </div>
                    {
                        show === row.id ?
                            <Tooltip content={row.status === "Pending" ? "Transaction pending" : "Transaction failed"} />
                            :
                            null
                    }
                </div>
                :
                "--"}</>
        },
        {
            name: '',
            selector: 'actions',
            sortable: false,
            right: true,
            width: "100px !important;",
            cell: row => <div className="d-flex align-items-end" onClick={() => { setRefKay(row.id); setMore(!more); }} ref={wrapperRef}>
                <span className="e-more" >
                </span>
                {
                    more && row.id === refKey ?
                        <div className="e-card-transaction" ref={wrapperRef} >
                            <span >
                                <img src={View} alt="View details" className="me-2" />View Details
                            </span>
                        </div>
                        :
                        null
                }

            </div>
        },
    ]
    const data = [
        {
            id: 1,
            name: "Le New year discount",
            start_date: "12 Jun 2020",
            end_date: "12 Jun 2020",
            participants: "20",
            amount: "₹20,000",
            status: "ACtive"
        },
        {
            id: 2,
            name: "Summer time offer",
            start_date: "12 Jun 2020",
            end_date: "12 Jun 2020",
            participants: "20",
            amount: "₹20,000",
            status: "In active"
        },
        {
            id: 3,
            name: "Year end offer",
            start_date: "12 Jun 2020",
            end_date: "12 Jun 2020",
            participants: "20",
            amount: "₹20,000",
            status: "In active"
        },
        {
            id: 4,
            name: "Black friday event",
            start_date: "12 Jun 2020",
            end_date: "12 Jun 2020",
            participants: "20",
            amount: "₹20,000",
            status: "Active"
        },
        {
            id: 5,
            name: "Month end event",
            start_date: "12 Jun 2020",
            end_date: "12 Jun 2020",
            participants: "20",
            amount: "₹20,000",
            status: "Active"
        }
    ]

    const eventOptions = [
        {
            value: 1, label: 'Weekly',
        },
        {
            value: 2, label: 'Monthly'
        },
        {
            value: 3, label: 'Daily'
        },
    ]
    return (
        <>
            {
                !createCampaign ?
                    <div className='row e-notification'>
                        <div className='col-lg-12'>
                            <div className='card border-0 e-dashboard-card'>
                                <div className='row'>
                                    <div className='col-lg-9'>
                                        <h6 className="e-section-title p-2">All Campaign</h6>
                                    </div>
                                    <div className='col-lg-3 text-end'>
                                        <PrimaryBtn
                                            name="Create a new campaign" handleClick={() => {
                                                setCreateCampaign(!createCampaign)
                                            }} />
                                    </div>
                                </div>
                                <div className='row pt-3'>
                                    <div className="col-lg-12 px-0">
                                        <Datatable
                                            data={data}
                                            columns={columns}
                                            selectableRows={true}
                                            // selectRows={(row) => { handleChange(row) }}
                                            rowClick={(row) => navigate(`/campaign-detail`)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    :
                    <div className='row e-notification'>
                        <div className='col-lg-6'>
                            <div className='card border-0 e-dashboard-card p-4'>
                                <div className='row'>
                                    <div className='col-lg-12'>
                                        <h6 className="e-section-title">Create new campaign</h6>
                                    </div>
                                </div>
                                <div className='row px-2'>
                                    <div className="col-lg-12 ">

                                        <div className='row px-2 mt-3'>
                                            <label className='mb-2 px-0'>Add banner</label>

                                            {
                                                url ?
                                                    <div className='e-preview position-relative'>
                                                        <img src={url} alt="" height="100%" width="100%" style={{ objectFit: "cover" }} />
                                                        <span className='e-choose-btn' onClick={() => { $('#e-upload').trigger("click") }}>
                                                            <img src={Preview} alt="" height="30px" width="30px" className='me-2' />
                                                            Change image
                                                        </span>
                                                        <span className='e-drag-text'>(Drag and drop image or click here to select image)</span>
                                                        <FileUpload accept="application/pdf, image/*"
                                                            className="d-none"
                                                            id="e-upload"
                                                            key="e-upload-image"
                                                            upload={(temp) => {
                                                                // setUploadImage(temp.name)
                                                                var url = URL.createObjectURL(temp);
                                                                setUrl(url)
                                                            }} />
                                                    </div>
                                                    :
                                                    <>
                                                        <div className="e-uploader" onClick={() => { $('#e-upload').click() }}>
                                                            <div className='text-center'>
                                                                <img src={Banner} alt="" height="40px" className='text-center' />
                                                                <p className='text-center mb-2 pt-2'>Upload an image</p>
                                                                {/* <p className='text-center mb-0'>
                                                                    <span>(Drag and drop image or click here to select image)</span>
                                                                </p> */}
                                                            </div>


                                                            <FileUpload accept="application/pdf, image/*"
                                                                className="d-none"
                                                                id="e-upload"
                                                                key="e-upload-image"
                                                                upload={(temp) => {
                                                                    // setUploadImage(temp.name)
                                                                    var url = URL.createObjectURL(temp);
                                                                    setUrl(url)
                                                                }} />
                                                        </div>
                                                    </>
                                            }
                                        </div>
                                        <div className='row mt-4 pt-2'>
                                            <label className='mb-2'>Campaign name</label>
                                            <Input
                                                value={campaignName}
                                                placeholder="Campaign name"
                                                valueChange={(temp) => {
                                                    setCampaignName(temp)
                                                }} />
                                        </div>
                                        <div className='row mt-3'>
                                            <div className='col-lg-6'>
                                                <label className='mb-2'>Choose events</label>
                                                <SelectBox
                                                    value={selected}
                                                    options={eventOptions}
                                                    defaultValue={eventOptions[0]}
                                                    className="e-dashboard-card-sort"
                                                    selectchange={(temp) => { setSelected(temp) }}
                                                    isSearchable={false}
                                                    placeholder="Choose events"
                                                />
                                            </div>
                                            <div className='col-lg-6 position-relative'>
                                                <label className='mb-2'>Price</label>
                                                <Input
                                                    value={price}
                                                    placeholder="Price"
                                                    preffix="₹"
                                                    type="number"
                                                    valueChange={(value) => {
                                                        setPrice(value);
                                                    }}
                                                    className="e-prefix-input"
                                                />
                                            </div>
                                        </div>
                                        <div className='row mt-3'>
                                            <div className='col-lg-6 e-campaign-date'>
                                                <label className='mb-2'>Choose date</label>
                                                <DateRangePicker start={dates.start} end={dates.end} selectPrevious={true} maxDate={moment().format('DD-MM-YYY')} onDatesChange={(start, end) => { setDates({ start: start, end: end }); }} />
                                            </div>
                                            <div className='col-lg-6 '>
                                                <label className='mb-2'>Score points</label>
                                                <Input
                                                    value={points}
                                                    placeholder="Score points"
                                                    type="number"
                                                    valueChange={(value) => {
                                                        setPoints(value);
                                                    }}

                                                />
                                            </div>
                                        </div>

                                    </div>

                                    <div className="col-lg-12 text-end mt-5">
                                        <TextBtn
                                            name="Cancel"
                                            className="e-campaign-cancel me-3"
                                        />
                                        <PrimaryBtn
                                            name="Start new campaign"
                                            className=""
                                            handleClick={() => navigate(`/campaign-detail`)}
                                        />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
            }
        </>
    );
}

export default Index;