/*
 *   File : push confirmation.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Confirmation Modal
 *   Integrations : 19-10-2023
 *   Version : v1.0
 */

/*import packages*/
import React from 'react';

/*import styles*/
import styles from '../../styles/components/approve.module.scss';

/*import components*/
import { PrimaryBtn, SecondaryBtn } from '../cta';


/* import assets */
import Stop from '../../assets/images/modal/stop.png';

export default function NotificationConfirm(props) {

    return (
        <div className={`${styles.e_approve} modal fade`} id="pushConfirm" tabIndex="-1" aria-labelledby="success" aria-hidden="true">
            <div className={`${styles.e_modal_dialog} modal-dialog modal-dialog-centered`}>
                <div className={`${styles.e_notification_modal_content} modal-content`}>

                    <div className="container px-3">
                        <div className="row">


                            {
                                props.type === 1 ?
                                    <div className="col-lg-12 text-center mb-3">
                                        <img src={Stop} alt="stop" width="128px" height="128px" />
                                    </div>
                                    :
                                    null
                            }


                            <h6 className={`${styles.e_failed_txt} mt-0`}>Push notification confirmation</h6>


                            {
                                props.type === 1 ?
                                    <p >STOP : You are sending this message to all customers. Are you sure you want to continue?</p>
                                    :
                                    <p>You are sending this message to segments. Are you sure you want to continue?</p>
                            }

                            <div className="col-12 d-flex justify-content-center">

                            
                                <SecondaryBtn name="Cancel"
                                    className={`${styles.e_cancel} e-modal-submit`}
                                    cancel="modal" />
                                <PrimaryBtn name="Continue"
                                    cancel="modal"
                                    className={`${styles.e_submit} e-modal-submit ms-3`}
                                    handleClick={() => {
                                        props.Confirm()
                                    }} />

                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div >
    );
}
