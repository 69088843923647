/*
 *   File : changeAllocation.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Change Allocation Success Modal
 *   Integrations :14-01-2022
 *   Version : v1.0
 */

/*import packages*/
import React, { useEffect, useState } from 'react';
import $ from 'jquery'

/*import componets*/
import { Input } from '../../components/input/index';
import { SecondaryBtn, PrimaryBtn } from '../../components/cta/index';
import { Toast } from '../note';

/*import styles*/
import styles from '../../styles/components/fundClass.module.scss';

/*import services */
import devconfig from '../../config/config';
import APIPost from '../../services/postApi';




export default function EditAllocation(props) {

    const [allocation, setAllocation] = useState(null);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [loader, setLoader] = useState(false);
    const [name, setName] = useState("");
    const [selectedFunds, setSelectedFunds] = useState([]);

    useEffect(() => {
        var myModalEl = document.getElementById(props.debt ? "editDebtAllocations" : "editAllocations")
        myModalEl.addEventListener('hidden.bs.modal', (event) => {
            props.close()
            $(".modal-backdrop").remove()
            $("body").removeAttr("style")
        })
        //eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (props.editedData && props && Object.values(props.editedData).length > 0) {
            setSelectedFunds(props.editedData)
            setName(props.editedData[0].asset_class);
            setAllocation(props.editedData.reduce((a, b) => a + b.allocation, 0));
        }
        //eslint-disable-next-line
    }, [props.editedData]);

    useEffect(() => {
        if (error !== null) {
            setTimeout(() => {
                setError(null);
            }, 4000);
        }
    }, [error]);

    useEffect(() => {
        if (success !== null) {
            setTimeout(() => {
                setSuccess(null);
            }, 4000);
        }
    }, [success])

    /*-----update allocation---- */
    const updateAllocation = () => {
        const requestFunds = []
        selectedFunds.map((item) => {
            return (
                requestFunds.push({
                    "category_type": props.name,
                    "allocation_id": item.id,
                    "allocation": parseFloat(item.allocation),
                    "fund_alias": item.fund_alias
                })
            )
        })
        setLoader(true);
        let url = devconfig.admin + '/fund/edit-allocation'
        let data = JSON.stringify({
            funds: requestFunds
        });
        APIPost(url, data).then((response) => {
            if (response.status_code === 200) {
                setSuccess("Allocation updated successfully");
                setLoader(false);

                setTimeout(() => {
                    // $(props.debt ? "#editDebtAllocations" : "#editAllocations").trigger('click');
                    $(".btn-close").trigger("click")
                    props.updatedAllocation(true);
                }, 1000)
            } else {
                setError(response.message);
                setLoader(false);

            }
        })
    }

    return (
        <>
            <div className={`${styles.e_add_fund} modal fade`} id={props.debt ? "editDebtAllocations" : "editAllocations"} tabIndex="-1" aria-labelledby="success" aria-hidden="true">
                <div className={`${styles.e_modal_dialog} modal-dialog modal-dialog-centered`}>
                    <div className="modal-content">
                        <div className="modal-header px-2 mx-1">
                            <h6>Edit allocations</h6>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" id="close-btn" ></button>
                        </div>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-3'>
                                    <label>Asset class</label>
                                    <h6>{name}</h6>
                                </div>
                                <div className='col-4'>
                                    <label>Total asset class allocation</label>
                                    <h6>{allocation}%</h6>
                                </div>
                                {/* <div className='col-5'>
                                    <label>Fund name</label>
                                    <h6>Small cap (20%)</h6>
                                </div> */}
                            </div>
                        </div>
                        <div className="container">
                            <>
                                <div className='row mt-4'>
                                    <div className='col-6'>
                                        Selected funds
                                    </div>
                                    <div className='col-3'>
                                        Fund alias
                                    </div>
                                    <div className='col-3'>
                                        Allocation
                                    </div>
                                </div>
                                {
                                    selectedFunds.map((item, key) => {
                                        return (
                                            <div className='row my-5'>
                                                <div className='col-6'>
                                                    <h6>{item.name}</h6>
                                                </div>
                                                <div className='col-3'>
                                                    <Input type="text"
                                                        value={item.fund_alias}
                                                        valueChange={(value) => {
                                                            let temp_schemes = [...selectedFunds]

                                                            if (value === "") {
                                                                temp_schemes[key].fund_alias = null;
                                                            } else {
                                                                temp_schemes[key].fund_alias = value;
                                                            }

                                                            setSelectedFunds(temp_schemes)
                                                        }}
                                                    />
                                                </div>
                                                <div className='col-3 position-relative'>
                                                    <Input type="number"
                                                        value={item.allocation}
                                                        suffix="%"
                                                        valueChange={(value) => {
                                                            let temp_funds = [...selectedFunds]
                                                            if (value === "") {
                                                                temp_funds[key].allocation = "";
                                                            } else {
                                                                temp_funds[key].allocation = parseInt(value);
                                                            }
                                                            setSelectedFunds(temp_funds)
                                                            if (temp_funds.some((item) => (item.allocation > 0))) {
                                                                const LENGTH = temp_funds.reduce((a, b) => a + b.allocation, 0);
                                                                setAllocation(LENGTH)
                                                            } else {
                                                                setAllocation("")
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </>
                            <div className="row pt-4 mt-2 pb-2">
                                <div className="col-lg-12">
                                    <div className="d-flex justify-content-end">
                                        <SecondaryBtn
                                            name="Cancel"
                                            className={`${styles.e_cancel} e-cancel`}
                                            cancel="modal"
                                            handleClick={() => setAllocation("")}
                                        />
                                        {
                                            selectedFunds.filter((item) => item.allocation !== '').length === selectedFunds.length && selectedFunds.filter((item) => item.fund_alias !== null).length === selectedFunds.length ?
                                                <PrimaryBtn
                                                    name="Change"
                                                    className={loader ? "e-modal-submit e-btn-loader" : "e-modal-submit"}
                                                    handleClick={() => {
                                                        updateAllocation()
                                                    }}
                                                />
                                                :
                                                <PrimaryBtn
                                                    name="Change"
                                                    className='e-modal-submit e-disabled'
                                                />
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            {/* Error toast */}
            {
                error !== null ?
                    <Toast data={error} type="error" id="1" />
                    :
                    null
            }

            {/* success toast */}
            {
                success !== null ?
                    <Toast data={success} type="success" id="2" />
                    :
                    null
            }
        </>
    );
}
