/*
 *   File : datepicker.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Datepicker
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 14-01-2022
 */

/*import package*/
import { DatePicker } from 'antd';
// import 'antd/dist/antd.css';
import 'antd/dist/antd.css';
import moment from 'moment';
import { useEffect, useState } from 'react';

/*import style*/

const Datepicker = (props) => {
  const [selectedDate, setSelectedDate] = useState(null);

  const handleChange = (date, dateString) => {
    if (dateString) {
      props.onChange(dateString);
    }
    else {
      props.onChange(null);
    }
  }


  useEffect(() => {
    if (props.value) {
      var tempDate = moment(props.value, 'DD-MM-YYYY');
      setSelectedDate(tempDate)
    } else {
      setSelectedDate(null)
    }
    //eslint-disable-next-line
  }, [props.value]);

  const disabledDate = (current) => {
    if (current.isBefore(moment())) { return true }
  }

  return (
    <div className='e-date-picker'>
      <DatePicker
        value={selectedDate}
        placeholder={props.placeholder}
        format={"DD-MM-YYYY"}
        disabledDate={props.disabledDate ? props.disabledDate : disabledDate}
        minDate={moment().subtract(500, "years")}
        maxDate={moment().subtract(18, "years")}
        className={props.className}
        onChange={handleChange}
        showToday={false}
        defaultPickerValue={
          props.isDecadeBack && !selectedDate ?
            moment().subtract(18, "years")
            :
            null
        }
      />
    </div>
  );
}

export default Datepicker;