/*
 *   File : fileUpload.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Textarea
 *   Integrations :
 *   Version : 1.0
 *   Created : 14-01-2022
 */

/*import packages*/
import React from 'react';
import $ from 'jquery'

/*import Services */

const FileUpload = (props) => {

  $('input[type="file"]').click(function (e) {
    e.target.value = null
  });
  return (
    <input
      id={props.id}
      className={props.className}
      type="file"
      key={props.key}
      accept={props.accept}
      onChange={(e) => props.upload(e.target.files[0])}
      {...props}
    />
  );
}

export default FileUpload;
