/*
 *   File : detail.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Index file for users container;
 *   Integrations : 27-04-2022
 *   Version : v1.0
 *   Created : 10-12-2021
 */
/*import pakcages*/
import { useState, useEffect, useRef, } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

/*import components*/
import Datatable from '../../components/dataFormating/dataTable';
import { PrimaryLink } from '../../components/cta/index';
import { Toast } from '../../components/note';
import { Toggle } from '../../components/cta';

/*import assets*/
import View from '../../assets/images/dataFormating/view.png';



/*import services*/
// import GetVars from '../../services/getUrl';
// import APIPost from '../../services/postApi';
// import devconfig from '../../config/config';


const CampaignDetails = (props) => {
    const navigate = useNavigate();
    const [error, setError] = useState(null);
    const [active, setActive] = useState(true);
    const wrapperRef = useRef()

    const [refKey, setRefKay] = useState(null);
    const [more, setMore] = useState(null);


    useEffect(() => {
        if (error !== null) {
            setTimeout(() => {
                setError(null)
            }, 3000)
        }
    }, [error])


    /* Toggle change */
    const change = (e) => {
        setActive(e.target.checked);
    }

    const columns = [
        {
            name: '#',
            selector: (row) => row.rank,
            sortable: false,
            left: true,
            width: "80px",
            cell: row => <div

            >
                <span className={row.rank === "1" ? "e-rank e-rank-1" : row.rank === "2" ? "e-rank e-rank-2" : row.rank === "3" ? "e-rank e-rank-3" : "e-leaderboard-rank"}>
                    {row.rank}
                </span>
            </div>

        },

        {
            name: 'Cust. ID',
            selector: (row) => row.cus_id ? row.cus_id : '--',
            sortable: true,
            left: true,
            width: "100px",

        },
        {
            name: 'User name',
            selector: row => row.name,
            sortable: true,
            left: true,
            maxWidth: "200px",
            cell: row => <div className="col-lg-12">
                {row.name}
            </div>

        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true,
            left: true,
            minWidth: "20px",
            cell: row => <div className="col-lg-12">
                {row.email}
            </div>,


        },
        {
            name: 'Phone no.',
            selector: 'phone',
            sortable: true,
            left: true,
            cell: (row) => <div className="col-lg-12"  >
                {row.phone}
            </div>
        },
        {
            name: 'Score points',
            selector: "points",
            sortable: true,
            left: true,

        },


        {
            name: '',
            selector: 'actions',
            sortable: false,
            right: true,
            width: "100px !important;",
            cell: row => <div className="d-flex align-items-end" onClick={() => { setRefKay(row.id); setMore(!more); }} ref={wrapperRef}>
                <span className="e-more" >
                </span>
                {
                    more && row.id === refKey ?
                        <div className="e-card-transaction" ref={wrapperRef} >
                            <span >
                                <img src={View} alt="View details" className="me-2" />View Details
                            </span>
                        </div>
                        :
                        null
                }

            </div>
        },
    ]
    const data = [
        {
            id: 1,
            rank: "1",
            cus_id: "M123",
            name: "Ralph Edwards",
            email: "ralphedwards@outlook.com",
            phone: "+91 8399273458",
            points: "500",

        },
        {
            id: 2,
            rank: "2",
            cus_id: "M124",
            name: "Devon Miles",
            email: "devomiles@yahoo.com",
            phone: "+91 8399273458",
            points: "500",
        },
        {
            id: 3,
            rank: "3",
            cus_id: "M125",
            name: "devomiles@yahoo.com",
            email: "devomiles@yahoo.com",
            phone: "+91 8399273458",
            points: "500",
        },
        {
            id: 4,
            rank: "4",
            cus_id: "M126",
            name: "Ralph Edwards",
            email: "ralphedwards@outlook.com",
            phone: "+91 8399273458",
            points: "500",
        },
        {
            id: 5,
            rank: "5",
            cus_id: "M127",
            name: "Ralph Edwards",
            email: "ralphedwards@outlook.com",
            phone: "+91 8399273458",
            points: "500",
        }
    ]


    return (


        <>
            <div className="row">
                <div className="col-lg-12">
                    <span className="e-breadcrumb-link"><Link to="/manage-campaign">Manage campaign</Link>/Campaign details</span>
                </div>
            </div>
            <div className="row">

                {/*-----------card displaying basic details------------------*/}
                <div className="col-lg-12">
                    <div className='e-campaign-detail-wrapper position-relative'>
                        <div className='row'>
                            <div className='col-lg-9'>
                                <h6>Campaign name</h6>
                            </div>
                            <div className='col-lg-3 d-flex justify-content-end'>
                                <PrimaryLink name="Edit" handleClick={() => navigate(`/campaign?edit=` + true)} />
                                <PrimaryLink name="Delete" className="e-delete-btn" />
                            </div>
                        </div>
                        <div className='row'>
                            <h3>How AMFI educated people on Twitter about mutual funds</h3>
                        </div>
                        <div className='row pt-3'>
                            <div className='col-lg-6'>
                                <div className='e-campaign-content-wrapper'>

                                    <div className='row'>
                                        <div className='col-lg-6 mb-3'>
                                            <h6>Date</h6>
                                            <p>16 Jul 2022 - 22 Jul 2022</p>
                                        </div>
                                        <div className='col-lg-6 mb-3'>
                                            <h6>Event</h6>
                                            <p>Successful signup</p>
                                        </div>
                                        <div className='col-lg-6'>
                                            <h6>Prize</h6>
                                            <p>₹50,000</p>
                                        </div>
                                        <div className='col-lg-6'>
                                            <h6>No. of particicpants</h6>
                                            <p>500</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <div className='e-campaign-content-wrapper'>
                                    <div className='row'>
                                        <div className='col-lg-9'>
                                            <h6>Campaign status</h6>
                                        </div>
                                        <div className='col-lg-3 text-end'>
                                            <span className='e-toggle-span d-inline-flex'>
                                                <Toggle
                                                    className="me-2"
                                                    check={active}
                                                    onChange={(e) => {
                                                        change(e);
                                                    }} />
                                                {active ? "Active" : "Inactive"}
                                            </span>
                                        </div>
                                        <div className='col-lg-12'>
                                            <ul className='pt-3'>
                                                <li>You can activate or inactivate campaign at any moment.</li>
                                                <li>Make sure you don’t inactivate during campaign.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='col-lg-12 mt-4'>
                    <div className='e-campaign-detail-wrapper p-0'>
                        <div className='row'>
                            <div className='col-lg-12'>
                                <h6 className='px-4 pt-4'>Leaderboard</h6>
                            </div>
                        </div>
                        <div className='' style={{ borderRadius: "16px" }}>
                            <Datatable
                                data={data}
                                columns={columns}

                            // selectRows={(row) => { handleChange(row) }}
                            // rowClick={(row) => navigate(`/campaign-detail`)}
                            />
                        </div>
                    </div>

                </div>
            </div>


            {/* Toast error */}
            {
                error !== null ?
                    <Toast data={error}
                        type="error"
                        id="1" />
                    :
                    null
            }
        </>


    );
}

export default CampaignDetails;
