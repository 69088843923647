import React from "react";
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend
);

const options = {
	responsive: true,
	// plugins: {

	// 	title: {
	// 		display: true,
	// 		text: "Chart.js Bar Chart"
	// 	}
	// }
};

const labels = ["January", "February", "March", "April", "May", "June", "July"];

const data = {
	labels,
	datasets: [
		{
			label: "Dataset 1",
			data: [1, 2, 3, 45, 56, 7, 8],
			backgroundColor: "rgba(255, 99, 132, 0.5)"
		}
	]
};

const BarChart = () => {
	return <Bar options={options} data={data} />;
}
export default BarChart
