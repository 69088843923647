/*
 *   File : input.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Input box
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 09-12-2021
 */

/*import package*/
import { useState, useEffect } from 'react';

/*import style*/
import styles from '../../styles/components/radio.module.scss';

const Radio = (props) => {
	//eslint-disable-next-line
	const [value, setValue] = useState("");
	//eslint-disable-next-line
	const [error, setError] = useState(null);

	useEffect(() => {
		if (props.defaultValue) {
			setValue(props.defaultValue);
		}
	}, [props.defaultValue])

	/*----onclick to pass value ---*/
	const valueClick = (e) => {
		setValue(e.target.value);
		setError(null);
		props.valueClick(e.target.value);
	}

	return (
		<div className={styles.e_radiobutton}>
			<input id={props.id}
				value={props.value}
				type="radio"
				onClick={(e) => props.valueClick ? valueClick(e) : null}
				placeholder={props.placeholder}
				required={props.required}
				checked={props.checked}
				name={props.name}
			/>
			<label htmlFor={props.id} className={`${styles.e_radio_label}`}>{props.label}</label>
		</div>
	);
}

export default Radio;

