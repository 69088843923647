/*
 *   File : bar.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : bar.js
 *   Integrations : 
 *   Version : v1.0
 */

/*Package importing*/
import React from 'react';
import Chart from "react-apexcharts";

export default class BarChartSecond extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			series: [
				{
					name: "",
					data: this.props.data
				},
			],
			options: {
				legend: { fontSize: '14px', fontFamily: 'Monsterrat', fontWeight: "800", color: "#00244B", },
				fill: {
					colors: ['#106EE8'],
				},
				colors: ['#106EE8'],
				chart: {
					type: 'bar',
					height: 350,
					window: "100%"
				},

				// fill: {
				// 	type: "gradient",
				// 	gradient: {
				// 		shade: 'light',
				// 		shadeIntensity: 0.5,
				// 		opacityFrom: 0.7,
				// 		opacityTo: 0.9,
				// 	}
				// },
				plotOptions: {
					bar: {
						endingShape: 'rounded',
						startingShape: 'rounded',
						borderRadius: 6,
						columnWidth: '15%',

					}
				},
				dataLabels: {
					enabled: false,
					padding: 0,
					style: { fontSize: '30px', colors: ['#fff'] },
				},
				stroke: {
					show: true,
					width: [1],
					colors: ['transparent']
				},
				xaxis: {
					
					categories: this.props.label,
					axisBorder: {
						show: false,
					},
					axisTicks: {
						show: false,
					},
					lines: {
						show: false
					},
					labels: {
						rotate:311,
						show: true,
						style: {
							colors: ['#5A596B'],
							fontSize: '12px',
							fontFamily: 'Helvetica, Arial, sans-serif',
							fontWeight: 400,
							cssClass: 'apexcharts-xaxis-label',
						},

					},
				},
				yaxis: {
					allowDecimals: false,
					lines: {
						show: false
					},

					labels: {
						// rotate: -90,
						show: true,
						formatter: (idx) => { return Math.abs(idx) > 999 ? Math.sign(idx) * ((Math.abs(idx) / 1000).toFixed(1)) + 'k' : Math.sign(idx) * Math.abs(idx) },
						style: {
							colors: ['#5A596B'],
							fontSize: '10px',
							fontFamily: 'Poppins',
							fontWeight: 400,
							cssClass: 'apexcharts-yaxis-label',
						},
					},
				},

				grid: {
					show: true,
					border: true,
					position: 'back',
					borderColor: '#5A596B',
					strokeDashArray: 2,
					xaxis: {
						lines: {
							show: false
						}
					},
					yaxis: {
						categories: [10, 20, 50, 80],
						rotate: 0,
						lines: {
							show: true
						},
						labels: {
							style: {
								colors: ['#5A596B'],
								fontSize: '10px',
								fontFamily: 'Poppins',
								fontWeight: 400,
								cssClass: 'apexcharts-xaxis-label',
							},
						},
					},
					row: {
						colors: undefined,
						opacity: 0.5
					},
					column: {
						colors: undefined,
						opacity: 0.5
					},
					// padding: {
					// 	top: 0,
					// 	right: 0,
					// 	bottom: 0,
					// 	left: 0
					// },
				}
			},
		};
	}

	render() {
		return (
			<Chart
				options={this.state.options}
				series={this.state.series}
				type="bar"
				height={410}
				width="100%"
			// width={650}
			/>
		);
	}
}

