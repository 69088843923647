/*
 *   File : summary.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Summary Card
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 13-12-2021
 */

/*import packages*/
import React, { useEffect, useRef, useState } from 'react';

/*Style Import*/
import styles from '../../styles/components/summary.module.scss';

/* component import */
import { EditRevenue } from '../modal';
import { IconStyleToolTip } from '../badge';


const Summary = (props) => {
	const wrapperRef = useRef(null);
	const [tooltip, setTooltip] = useState(false);


	/* ----- closing tooltip on mouse click in any other position of screen ----*/
	useEffect(() => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
		document.addEventListener('mouseup', closeMenu);
		return () => {
			document.removeEventListener('mouseup', closeMenu);
		};
	}, []);

	const closeMenu = (event) => {
		if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
			setTooltip(false)
		}
	}
	return (
		<>
			<div className={`${props?.revenue?.className ? styles.e_blue_box : styles.e_summary_card} card`}>
				<div className="row">
					<div className='row'>

						<div className="col-lg-12 col-md-12 col-sm-12 col-12 position-relative">
							<h5>
								{
									props.revenue ?
										props.revenue?.title
										:
										<>
											{
												props.data.suffix ?
													<>
														{props.data?.title}
														<img src={props.data?.suffix} alt=""
															className='ps-2'
															style={{ cursor: "pointer" }}
															onMouseEnter={() => setTooltip(true)}
															onMouseLeave={() => setTooltip(false)} />
														{
															tooltip ?
																<IconStyleToolTip
																	className={styles.e_aum_tooltip}
																	content="Asset under management" />

																:
																null
														}
													</>
													:
													<>
														{props.data?.title}

													</>
											}
										</>
								}
							</h5>
						</div>
					</div>
					<div className='row'>
						<div className="col-lg-10 col-md-10 col-10 pe-0 ">
							<span className='e-dashboard-card-text'>
								{
									props.data.currency ?
										<span className={`${styles.e_text} pe-1`}>
											{

												props.data.currency
											}
										</span>
										:
										null
								}
								{

									props.data.count
								}
								{
									props.data.text ?

										<span className={styles.e_text}>
											{

												props.data.text
											}
										</span>
										:
										null
								}
							</span>
						</div>
						<div className="col-lg-2 col-md-2 col-2 text-center pe-0 ">
							<div className={styles.e_img_bg}>
								<img
									src={
										props.revenue ?
											props.revenue?.icon
											:
											props.data?.icon
									}
									className={styles.e_summary_card_icon}
									alt="" />
							</div>
						</div>
					</div>
				</div>
			</div>
			<EditRevenue />
		</>

	)
}

export default Summary;