/*
 *   File : input.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Input box
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 09-12-2021
 */

/*import package*/
import { useState, useEffect } from 'react';

/*import style*/
import styles from '../../styles/components/input.module.scss';

const Input = (props) => {

	const [value, setValue] = useState("");
	const [error, setError] = useState(null);

	useEffect(() => {
		if (props.defaultValue) {
			setValue(props.defaultValue);
		}
	}, [props.defaultValue]);

	/* --- validation for email , number (10 digits)----*/
	const validate = () => {
		if (props.type === "email") {
			//eslint-disable-next-line
			if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
				setError("Invalid email")
			}
		}
		else if (props.type === "number" && (props.placeholder === "Phone Number" || props.placeholder === "Phone number" || props.placeholder === "Mobile Number")) {
			if (!/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/.test(value)) {
				setError("Invalid phone")
			}
		}
		if (props.validateLength) {
			if (props.minLength && (value.length < props.minLength)) {
				setError(`Minimum ${props.minLength} charcter${props.minLength > 1 ? "s" : ""} required`);
				props?.valueChange(null);
			}
			else if (value.length > props.maxLength) {
				setError(`Maximum charcter limit(${props.maxLength}) exceeded.`);
				props?.valueChange(null);
			}
		}
		if (props.onlyNumber) {
			if (!/^\d+$/.test(value)) {
				setError("Enter a valid number");
				props?.valueChange(null);
			}
		}
	}

	/* ---- onChange event for inputs and passing value in terms of props ---*/
	const valueChange = (e) => {
		setValue(e.target.value);
		setError(null);
		props?.valueChange(e.target.value, e);
	}

	var invalidChars = [
		"-",
		"+",
		"e",
		","
	];

	/* ---- func to be implemented on press of enter ----*/
	const keyPress = (e) => {
		if (props.type === "number") {
			if (invalidChars.includes(e.key)) {
				e.preventDefault();
			}
			if (e.which === 38 || e.which === 40) {
				e.preventDefault();
			}
		}
		if (e.which === 32 && e.target.selectionStart < 1) {
			e.preventDefault();
		}
		if (e.keyCode === 13) {
			if (props?.onSearch) {
				props?.onSearch();
			}
		}
	}

	return (
		<div className={styles.e_input_wrap}>
			<input id={props.id}
				value={props.value}
				type={props.type}
				checked={props.checked}
				//eslint-disable-next-line
				className={error ? `${styles.e_input_error} ${styles.e_input_box}` + " " + props.className : `${styles.e_input_box}` + " " + props.className}
				onChange={(e) => { valueChange(e) }}
				placeholder={props.placeholder}
				required={props.required}
				onBlur={() => { validate() }}
				onFocus={() => setValue(props.value)}
				prefix={props.prefix}
				onKeyDown={e => keyPress(e)}
				name={props.name}
				min={props.min}
				step={props.step}
				readOnly={props.readOnly}
				disabled={props.disabled}
				onWheel={() => document.activeElement.blur()} />


			{
				props.suffix ?
					<span className={styles.e_suffix}>{props.suffix}</span>
					:
					null
			}
			{
				props.preffix ?
					<span className={styles.e_preffix}>{props.preffix}</span>
					:
					null
			}
			{props.label ? <label htmlFor={props.id} className="label">{props.label}</label> : null}
			{
				error ?
					<p className={styles.e_error_msg + " mb-0"}>{error}</p>
					:
					null
			}
		</div>
	);
}
export default Input;

