/*
 *   File : index.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Index file for Manage target maturity container;
 *   Integrations 14-02-2023
 *   Version : v1.0
 *   Created : 10-02-2023
*/
/* import packages */
import React, { useEffect, useState } from "react";
import { Modal } from "bootstrap";
import $ from 'jquery';

/* import components */
import { TargetMaturityCard } from "../../components/card";
import { AddFund } from "../../components/modal";
import { Loader } from "../../components/loader";

/* import assets */
import AddNew from '../../assets/images/dataFormating/add-new.svg';

/* import services */
import devconfig from "../../config/config";
import APIGet from "../../services/getApi";


const Index = () => {

    const [loader, setLoader] = useState(false);
    const [fundsList, setFundsList] = useState([]);

    $(".fade").removeClass("modal-backdrop")
    $("body").removeAttr("style")

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });

        getFundsList();
    }, []);

    /*--- funds listing ------*/
    const getFundsList = () => {
        setLoader(true)
        let url = devconfig.admin + "/fund/tm-list";

        APIGet(url).then((response) => {
            if (response.status_code === 200) {
                setLoader(false);
                setFundsList(response.data);
            } else {
                setLoader(false);
                setFundsList([]);
            }
        });
    }

    /* ------ open add fund Modal -----*/
    const addNewFund = () => {
        var myModal = new Modal(document.getElementById("addFund"), {});
        myModal.show();
    }
    return (
        <>
            <div className="row pb-5">
                <div className="col-lg-12">
                    {/* target maturity card box */}
                    <div className="card e-dashboard-card e-faq-card border-0 p-4">
                        <div className="row pt-2">
                            <div className="col-12">
                                <h6 className="e-section-title mb-3">Funds</h6>
                            </div>

                            {/*--- listing ---*/}
                            {
                                loader ?
                                    <Loader />
                                    :
                                    fundsList && fundsList.length > 0 ?
                                        <div className="col-12">
                                            <TargetMaturityCard
                                                data={fundsList}
                                                updatedFund={() => {
                                                    getFundsList()
                                                }}
                                            />
                                        </div>
                                        :
                                        null
                            }

                            {/*------- Add new target ---------*/}
                            <div className="row mx-0">
                                <div className="e-add-sec col-lg-12 "
                                    onClick={() => {
                                        addNewFund()
                                    }}>
                                    <h6>
                                        <img src={AddNew}
                                            alt="add"
                                            className="me-3"
                                        />
                                        Add New
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/*----------- Add new maturity fund modal -----*/}
            <AddFund
                updatedFund={() => {
                    getFundsList()
                }} />



        </>
    );
}
export default Index;