/*
 *   File : accordion.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Accordion
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 09-12-2021
 */

/*import package*/
import React, { useEffect, useRef, useState } from 'react';

/*import components*/


/*import style*/
import styles from '../../styles/components/accordionUserInvestment.module.scss';

/*import assets*/
// import Icon from '../../assets/images/dataFormating/Icon.svg';
import { IconStyleToolTip } from '../badge';

export default function AccordionUserInvestment(props) {
    const [id, setId] = useState(null);
    const wrapperRef = useRef(null);
    //eslint-disable-next-line
    const [tooltip, setTooltip] = useState(false);


    // /* ----- closing tooltip on mouse click in any other position of screen ----*/
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        document.addEventListener('mouseup', closeMenu);
        return () => {
            document.removeEventListener('mouseup', closeMenu);
        };
    }, []);

    const closeMenu = (event) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            setTooltip(null)
        }
    }

    return (
        <div className={`${styles.e_user_accordion} e-allcation-accordion`}>
            <div className="accordion" id="accordionDescription">
                <div className="row mx-0">
                    {
                        props.summaryData.map((item, key) => {
                            return (
                                <div className={`${styles.e_accordion_sec} col-lg-12`}>
                                    <div className={`${styles.e_accordion_item} accordion-item`}>
                                        <h2 className={`${styles.e_accordion_header} accordion-header`} id={"collapseDescription" + key} >
                                            <button className={`${styles.e_accordion_button}`} type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${key}`} aria-expanded="false" aria-controls={`collapse${key}`} >
                                                <div className="row" onClick={() => { setId(key + 1); }}>
                                                    {
                                                        (key + 1) !== id ?
                                                            <div className="row" >
                                                                <div className="col-lg-10" >
                                                                    <span className={`${styles.e_accordion_title}`}>{item.name}</span>

                                                                </div>
                                                                <div className="col-lg-2" >
                                                                    <span className={`${styles.e_accordion_icon}`} ></span>
                                                                </div>
                                                            </div>
                                                            :
                                                            null
                                                    }
                                                </div>
                                                <div className="row" onClick={() => { setId(null); }}>
                                                    {
                                                        (key + 1) === id ?
                                                            <div className="row" >
                                                                <div className="col-lg-10" >
                                                                    <span className={`${styles.e_accordion_title}`}>{item.name}</span>

                                                                </div>
                                                                <div className="col-lg-2" >
                                                                    <span className={`${styles.e_accordion_icon_minus}`} ></span>
                                                                </div>
                                                            </div>
                                                            :
                                                            null
                                                    }
                                                </div>
                                            </button>
                                        </h2>
                                        {
                                            (key + 1) === id ?
                                                <div  >
                                                    <div className={`${styles.e_accordion_body} accordion-body `}>
                                                        <div className={`${styles.e_accordion_wrapper} `}>

                                                            <div className="row w-100">
                                                                <div className='col'>
                                                                    <div className={`${styles.e_accordion_body_wrapper}`}>
                                                                        <h6>Target amount</h6>
                                                                        <p className='mb-0 pt-2'>₹ {Number((item.target).toFixed(2)).toLocaleString('en-IN')}</p>
                                                                    </div>
                                                                </div>
                                                                <div className='col'>
                                                                    <div className={`${styles.e_accordion_body_wrapper}`}>
                                                                        <h6>XIRR</h6>
                                                                        <p className='mb-0 pt-2'>{item.customer_xirr ? item.customer_xirr : "-"}%</p>
                                                                    </div>
                                                                </div>
                                                                <div className='col e-investment-tooltip position-relative '>
                                                                    <div className={`${styles.e_accordion_body_wrapper}`}>
                                                                        <h6>Amount invested
                                                                            {/* <img
                                                                                className="ps-2"
                                                                                src={Icon} ref={wrapperRef} alt=""
                                                                                onMouseEnter={() => setTooltip(true)}
                                                                                onMouseLeave={() => setTooltip(false)}
                                                                                style={{ cursor: "pointer" }}
                                                                            /> */}
                                                                        </h6>

                                                                        <p className='mb-0 pt-2'>₹ {item.invested_amount ? Number((item.invested_amount).toFixed(2)).toLocaleString('en-IN') : 0}</p>
                                                                    </div>
                                                                </div>
                                                                <div className='col position-relative'>
                                                                    <div className={`${styles.e_accordion_body_wrapper} ${styles.e_accordion_body_wrapper_value}`}>
                                                                        <h6>Current value

                                                                            <img src={require("../../assets/images/dataFormating/Icon.png").default}
                                                                                alt="Icon"
                                                                                className='ms-2'
                                                                                style={{ cursor: "pointer" }}
                                                                                onMouseEnter={() => setTooltip(key)}
                                                                                onMouseLeave={() => setTooltip(null)} />
                                                                        </h6>
                                                                        {
                                                                            tooltip === key ?
                                                                                <IconStyleToolTip
                                                                                    className="e-current-tooltip mt-3"
                                                                                    content={`Current value breakdown<br/><hr/>Invested: ${item.invested_amount ? item.invested_amount.toFixed(2) : 0}<br/>
                                                                                    Redemption: ${item.total_redemptions ? item.total_redemptions : 0}<br/>
                                                                                    - On Rebalancing: ${item.total_redemption_from_rebalance ? item.total_redemption_from_rebalance : 0}<br/>
                                                                                    - By Customer: ${item.total_normal_redemption ? item.total_normal_redemption : 0}
                                                                                    `} />
                                                                                :
                                                                                null
                                                                        }
                                                                        <p className='mb-0 pt-2'>₹ {Number((item.current_value).toFixed(2)).toLocaleString('en-IN')}</p>
                                                                    </div>
                                                                </div>
                                                                <div className='col'>
                                                                    <div className={`${styles.e_accordion_body_wrapper}`}>
                                                                        <h6>Total returns</h6>
                                                                        <p className='mb-0 pt-2'>₹ {item.p_and_l ? item.p_and_l.toFixed(2): 0}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {
                                                                item && item.funds && item.funds.length > 0 ?
                                                                    <>
                                                                        <div className='row pt-4'>
                                                                            <div className="col-lg-12" >
                                                                                <span className={`${styles.e_accordion_sub_title}`}>Funds and investments</span>
                                                                            </div>
                                                                        </div>
                                                                        <div className='row pt-2'>
                                                                            <div className='col-lg-12'>
                                                                                <div className={`${styles.e_investment_wrapper} `}>
                                                                                    <div className='row'>
                                                                                        <div className='col-lg-6'>
                                                                                            <h6>Invested Funds</h6>
                                                                                        </div>
                                                                                        <div className='col-lg-2'>
                                                                                            <h6>Weightage</h6>
                                                                                        </div>
                                                                                        <div className='col-lg-4'>
                                                                                            <h6>Amount</h6>
                                                                                        </div>
                                                                                    </div>
                                                                                    {

                                                                                        item.funds.map((fundData) => {
                                                                                            return (
                                                                                                <div className='row'>
                                                                                                    <div className='col-lg-6'>
                                                                                                        <p className='pt-3'>{fundData.scheme_name}</p>
                                                                                                    </div>
                                                                                                    <div className='col-lg-2'>
                                                                                                        <p className='pt-3'>{fundData.allocation !== null ? fundData.allocation.toFixed(2) : "0"}%</p>
                                                                                                    </div>
                                                                                                    <div className='col-lg-4'>
                                                                                                        <p className='pt-3'>{fundData.invested_amount ? `₹ ${((parseFloat(fundData.invested_amount))).toLocaleString('en-IN')}` : "₹ 0"}</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            )
                                                                                        })

                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                    :
                                                                    null
                                                            }
                                                        </div>


                                                    </div>
                                                </div>
                                                :
                                                null
                                        }
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    );
}


