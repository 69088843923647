
/*
 *   File : toggle.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Toggle btn
 *   Integrations :
 *   Version : v1.0
 *   Created : 12-08-2022
 */

/* import style  */
import style from '../../styles/components/toggle.module.scss';


const Toggle = (props) => {
    return (
        <label className={`${style.e_switch} ${props.className}`} >
            <input type="checkbox" onChange={(e) => props.onChange ? props.onChange(e) : null} checked={props.check} className={props.className} disabled={props.disabled}  {...props} />
            <span className={`${style.e_slider}`}></span>
        </label>
    )
}

export default Toggle;