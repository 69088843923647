/*
 *   File : orders.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Order list;
 *   Integrations : 24-05-2022
 *   Version : v1.0
 *   Created :24-05-2022
*/
/*import packages*/
import { useState, useEffect, useRef } from 'react';
import { Modal } from 'bootstrap';
import moment from 'moment';

/*import components*/
import { DataTable } from '../../components/dataFormating/index';
import { FilterBtn, DownloadBtn } from '../../components/cta/index';
import { OrderDetail } from '../../components/modal/index';
import { SearchInput, DateRangePicker } from '../../components/input/index';
import SelectBox from '../../components/dropdown/selectBox';
import { Loader } from '../../components/loader';
import { Toast } from '../../components/note';
import { EmptyNote } from '../../components/note';
/*import styles*/
import styles from '../../styles/components/searchInput.module.scss';

/*import assets*/
import View from '../../assets/images/dataFormating/view.png';
import Tooltip from '../../components/badge/tooltip';


/* import services */
import devconfig from '../../config/config';
import APIPost from '../../services/postApi';
import APIGet from '../../services/getApi';


const Orders = (props) => {

	const wrapperRef = useRef(null);

	const [search, setSearch] = useState("");
	const [filterDown, setFilterDown] = useState(false);
	const [dates, setDates] = useState({ start: null, end: null });
	const [more, setMore] = useState(false);
	const [refKey, setRefKay] = useState();
	const [loader, setLoader] = useState(true);
	const [error, setError] = useState(null);
	const [type, setType] = useState({ label: "All", value: null });
	const [selectedOrderStatus, setSelectedOrderStatus] = useState({ label: "All", value: null });
	const [selectedPayStatus, setSelectedPayStatus] = useState({ label: "All", value: null });
	const [orderStatusOptions, setOrderStatusOptions] = useState([]);
	const [paymentStatusOptions, setPaymentStatusOptions] = useState([]);
	const [check, setCheck] = useState([])
	const [show, setShow] = useState(null);
	const [orderData, setOrderData] = useState([]);
	const [details, setDetails] = useState({});
	const [success, setSuccess] = useState(null);
	const [downloadLoader, setDownloadLoader] = useState(false);
	const [totalRecords, setTotalRecords] = useState(null);
	const [pageNum, setPageNum] = useState(1);
	const [perPage, setPerPage] = useState(10);

	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		});

		document.addEventListener('mouseup', closeMenu);
		return () => {
			document.removeEventListener('mouseup', closeMenu);
		};
	}, []);

	useEffect(() => {
		getOrderList();
		//eslint-disable-next-line
	}, [dates, selectedPayStatus, type, selectedOrderStatus, perPage, pageNum]);

	useEffect(() => {
		filterData();
	}, []);

	useEffect(() => {
		setTimeout(() => {
			if (error !== null) {
				setError(null);
			}
		}, 3000);
	}, [error]);

	useEffect(() => {
		if (success !== null) {
			setTimeout(() => {
				setSuccess(null)
			}, 3000)
		}
	}, [success])

	const customDateSort = (a, b, selector, given) => {
		let format = given ? given : "DD MMM YYYY"
		if (a[selector] && b[selector]) return moment(a[selector], format).valueOf() - moment(b[selector], format).valueOf();
		else if (a[selector]) return -1;
		else if (b[selector]) return 1;
		else return 0;
	}

	const typeOptions = [
		{ value: null, label: 'All' },
		{ value: 1, label: 'Lumpsum' },
		{ value: 2, label: 'SIP' },
		{ value: 3, label: "Withdraw" },
		{ value: 4, label: "Switch" }
	]


	/*---- order table columns -----*/

	const columns = [
		{
			name: 'Order ID',
			selector: (row) => row.order_id ? row.order_id : '--',
			sortable: true,
			left: true,
			minWidth: "110px",
			maxWidth: "110px",
			defaultSortAsc: false,
		},
		{
			name: 'Created on',
			selector: row => row.created,
			sortable: true,
			left: true,
			minWidth: '200px',
			sortFunction: (a, b) => customDateSort(a, b, 'created',),
			cell: row => <div className="col-lg-12" onClick={() => modalOpen(row)}>
				{row.created}
			</div>
		},
		{
			name: 'Amount',
			selector: row => row.amount ? parseFloat(row.amount) : 0,
			// selector: (row) => row.amount ? "₹" + parseFloat(row.amount.split("₹")[1]).toFixed(2) : '--',
			sortable: true,
			left: true,
			minWidth: '130px',
			cell: row => <div onClick={() => modalOpen(row)}>
				₹{
					row.amount ?
						row.amount.toFixed(2)
						:
						0
				}
			</div>

		},
		{
			name: 'Order status',
			selector: 'order_status',
			sortable: false,
			left: true,
			minWidth: '170px',
			cell: row => <>{row.order_status ?
				<div className="d-inline-flex">
					<div className="col-lg-10">
						<div className={
							row.order_status === "Partially Success" ? `e-status-badge e-status-badge-partially` :
								row.order_status === "Success" ? `e-status-badge e-status-badge-success` :
									row.order_status === "Failed" ? `e-status-badge e-status-badge-fail` :
										row.order_status === "Processing" ? `e-status-badge e-status-badge-pending` :
											'e-status-badge'
						}>
							{
								row.order_status
							}
						</div>
					</div>
				</div>
				:
				"--"}</>
		},
		{
			name: 'Payment status',
			selector: 'payment_status',
			sortable: false,
			left: true,
			minWidth: "170px",

			// cell: row => <div className="">
			//   <Badge type="success" text="success"/>
			// </div>

			cell: (row, key) => <>{row.payment_status ?
				<div className="d-inline-flex">
					<div className="col-lg-10">
						<div className={
							row.payment_status === "Success" ? `e-status-badge e-status-badge-success` :
								row.payment_status === "Failed" ? `e-status-badge e-status-badge-fail` :
									row.payment_status === "Processing" ? `e-status-badge e-status-badge-pending` :
										row.payment_status === "Submitted" ? `e-status-badge e-status-badge-pending` :
											'e-status-badge'
						}>
							{row.payment_status.toLowerCase()}
						</div>
					</div>
					<div className="col-lg-2">
						<span onMouseEnter={() => setShow(key + 1)} onMouseLeave={() => setShow(null)} className={
							row.payment_status === "Failed" ? `e-icon` :
								row.payment_status === "Processing" ? `e-icon` :
									null
						}>

						</span>
					</div>
					{
						show === (key + 1) ?
							<Tooltip content={row.failure_reason ? row.failure_reason : row.payment_status === "Processing" ? "Transaction pending" : "Transaction failed"} />
							:
							null
					}
				</div>
				:
				"--"}</>
		},
		{
			name: 'Goal name',
			selector: row => row.goal_name,
			sortable: true,
			left: true,
			minWidth: '180px',
			cell: (row) => <div className="col-lg-12" onClick={() => modalOpen(row)} >
				{row.goal_name}
			</div>
		},
		{
			name: 'Type',
			selector: 'type',
			sortable: true,
			left: true,
			width: "180px",
			cell: (row) => <div className="col-lg-12" onClick={() => modalOpen(row)} >
				{row.type}
			</div>
		},

		{
			name: 'Payment method',
			sortable: true,
			left: true,
			minWidth: "180px",
			selector: "payment_method",
			cell: row => <div onClick={() => modalOpen(row)}>
				{
					row.payment_method === 1 ?
						"Net banking"
						:
						row.payment_method === 2 ?
							"UPI"
							: row.payment_method === 3 ?
								"Mandate"
								: "None"
				}
			</div>

		},

		{
			name: 'FP Payment ID',
			selector: (row) => row.fp_payment_id ? row.fp_payment_id : '--',
			sortable: true,
			left: true,
			minWidth: "160px",
			maxWidth: "160px",
			defaultSortAsc: false,
			cell: row => <a href={`https://dashboard.fintechprimitives.com/live/resources/payments/${row.fp_payment_id}`} target="_blank">{row.fp_payment_id}</a>
		},
		{
			name: '',
			selector: 'actions',
			sortable: false,
			right: true,
			width: "100px !important;",
			cell: (row, key) => <div className="d-flex align-items-end" onClick={() => { setRefKay(key + 1); setMore(!more); }} ref={wrapperRef}>
				<span className="e-more" >
				</span>
				{
					more && (key + 1) === refKey ?
						<div className="e-card-transaction" ref={wrapperRef} onClick={() => modalOpen(row)}>
							<span >
								<img src={View} alt="View details" className="me-2" />View Details
							</span>
						</div>
						:
						null
				}

			</div>
		},
	]

	/*--- func to open order detail modal ---*/
	const modalOpen = (row) => {
		setDetails(row);
		var myModal = new Modal(document.getElementById("orderDetail"), {});
		myModal.show();
	}

	const closeMenu = (event) => {
		if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
			setMore(false);
		}
	}

	const _handlePageChange = (page_num) => {

		setPageNum(page_num);

		window.scrollTo({
			top: 0, behavior: 'smooth'
		});
	};

	const _handlePerPage = (per_page) => {
		setPerPage(per_page);
		window.scrollTo({
			top: 0, behavior: 'smooth'
		});
	};

	/* --------selecting rows---------*/
	const handleChange = (row) => {
		let selected = []
		for (let i = 0; i < row.selectedRows.length; i++) {
			selected.push(row.selectedRows[i]['order_id']);
		}
		setCheck(selected);
	}

	/*--------- order list -----------*/
	const getOrderList = (query) => {

		let url = devconfig.admin + '/user/order-list';

		let data = JSON.stringify({
			user_id: Number(props.id),
			from_date: dates.start,
			to_date: dates.end,
			type: type?.value,
			payment_status: selectedPayStatus.value,
			order_status: selectedOrderStatus.value,
			page_num: pageNum,
			query: query ? (query.value) : search,
			page_size: perPage
		});
		APIPost(url, data).then((response) => {
			if (response.status_code === 200) {
				setOrderData(response.data.data);
				setTotalRecords(response.data.total_records)
				setLoader(false);
				setCheck([]);
			} else {
				setOrderData([]);
				setLoader(false);
			}
		});
	}



	/*---- filter data api --*/
	const filterData = () => {
		let payment_options_temp = [];
		let status_options_temp = [];

		let url = devconfig.admin + '/order/filter-data';

		APIGet(url).then((response) => {
			if (response.status_code === 200) {
				response.data.payment_status.map((item) => {
					payment_options_temp.push({ value: item.status, label: item.name });
					return true;
				});
				response.data.order_status.map((item) => {
					status_options_temp.push({ value: item.status, label: item.name });
					return true;
				});
				setOrderStatusOptions(status_options_temp);
				setPaymentStatusOptions(payment_options_temp);
			}
		});
	}

	/*---- download orders--*/
	const downloadOrderList = () => {
		setDownloadLoader(true);
		let url = devconfig.admin + '/order/list-download';
		let data = JSON.stringify({
			user_id: Number(props.id),
			order_ids: check.length === orderData.length ? [] : check
		});
		APIPost(url, data).then((response) => {
			if (response.status_code === 200) {
				if (response.status === true) {
					setDownloadLoader(false);
					let a = document.createElement("a");
					a.href = response.data.url;
					document.body.appendChild(a);
					a.click();
					document.body.removeChild(a);
					setSearch("");
					setCheck([]);
					setSuccess("List downloaded successfully")
				} else {
					setDownloadLoader(false);
					setError(response.message)
				}
			}
			else {
				setDownloadLoader(false);
				setError(response.message);
			}
		})
	}

	/* serach orders */
	// const searchOrders = (value) => {
	// 	setOrderStatusOptions([]);
	// 	setPaymentStatusOptions([]);
	// 	// setSearch(value);
	// 	let url = devconfig.admin + '/user/order/search'
	// 	let data = JSON.stringify({
	// 		user_id: props.id,
	// 		query: search || value,
	// 	});

	// 	APIPost(url, data).then((response) => {
	// 		if (response.status_code === 200) {
	// 			setOrderData(response.data);
	// 			filterData();
	// 			setSearch(value);
	// 			setSearch(search);
	// 		}
	// 		else {
	// 			setOrderData([]);
	// 			setError(response.message);
	// 		}
	// 	});
	// }

	return (
		<>
			<div className="row pe-0">
				<div className="col-lg-12 pe-0">
					<div className="card e-dashboard-card border-0">
						<div className="row pt-2">
							<div className="col-lg-4 col-3">
								<h6 className="e-card-title p-2">All Orders</h6>
							</div>
							<>
								<div className="col-lg-3 col-4 align-items-center text-end" >
									<DownloadBtn
										check={['check']}
										onClick={() => {
											downloadOrderList();
										}}
									/>
								</div>
								<div className="col-lg-4 col-4 position-relative">
									<SearchInput
										placeholder="Search with MIRA Order ID"
										value={search}
										valueChange={(value) => {
											setSearch(value);
											if (value === "") {
												getOrderList({
													value: ""
												})
											}
										}}
										onSearch={(value) => {
											// searchOrders(value)
											getOrderList({ value: value })
										}}
									/>
									{
										search && search.length ?
											<>
												<span
													className={`${styles.e_search_submit} `}
													onClick={() => {
														getOrderList()
													}}>
												</span>
												<button
													className={`${styles.e_search_close} pe-3`}
													onClick={() => {
														setSearch("");
														getOrderList({
															value: ""
														});

													}}>
												</button>
											</>
											:
											null
									}

								</div>
								<div className="col-lg-1 col-1">
									<FilterBtn
										// img={Filter}
										className={filterDown ? "e-filter-btn e-active" : "e-filter-btn"}
										handleClick={() => {
											setFilterDown(!filterDown);
											setSearch("");
											// if (filterDown === true) {
											// 	setDates({ start: null, end: null });
											// 	setSelectedPayStatus({ label: "All", value: null });
											// 	setType({ label: "All", value: null });
											// }
										}}
									/>
								</div>
							</>
							{/*-------------- Filters  ---------------*/}
							{
								filterDown ?
									<div className="e-filter-wraper e-sort mt-3">
										<div className="row">
											<div className="col-lg-3 col-4 pe-0">
												<label className="mb-2">Transactions from - till</label>
												<DateRangePicker
													start={dates.start}
													end={dates.end}
													onDatesChange={(start, end) => {
														setDates({ start: start, end: end })
													}}
												/>
											</div>
											<div className="col-lg-2 col-3 px-2">
												<label className="mb-2">Type</label>
												<SelectBox
													value={type}
													options={typeOptions}
													selectchange={(temp) => setType(temp)}
													isSearchable={false}
												/>
											</div>
											<div className="col-lg-2 col-3  p-0">
												<label className="mb-2">Payment status</label>
												<SelectBox
													value={selectedPayStatus}
													options={paymentStatusOptions}
													selectchange={(temp) => setSelectedPayStatus(temp)}
													isSearchable={false}
												/>
											</div>
											<div className="col-lg-2 col-3 px-2">
												<label className="mb-2">Order status</label>
												<SelectBox
													value={selectedOrderStatus}
													options={orderStatusOptions}
													selectchange={(temp) => setSelectedOrderStatus(temp)}
													isSearchable={false}
												/>
											</div>
										</div>
									</div>
									:
									null
							}

							{/*--------------- order table -----------*/}
							{
								loader ?
									<Loader />
									: (!orderData.length) ?
										<EmptyNote note="There is no transaction to show" />
										:
										<div className="col-lg-12 px-0">
											<DataTable
												pagination={true}
												columns={columns}
												data={orderData}
												selectableRows={true}
												perPage={perPage}
												paginationServer={true}
												clearSelectedRows={downloadLoader ? true : false}
												paginationTotalRows={totalRecords}
												onChangeRowsPerPage={_handlePerPage}
												onChangePage={_handlePageChange}
												selectRows={(row) => { handleChange(row) }}
												rowClick={(row) => modalOpen(row)}
											/>
										</div>
							}

						</div>
					</div>
				</div>
			</div>

			{/*--------- Order detail modal component ----------------*/}
			<div ref={wrapperRef}>
				<OrderDetail
					data={details}
					id={props.id}
					order_status={details.order_status}
					payment_status={details.payment_status}
					orders={details.funds}
					fName={props.fName}
				/>
			</div>
			{/* error toast */}
			{
				error !== null ?
					<Toast
						data={error}
						type="eror"
						id="1"
					/>
					:
					null
			}
			{/* Success toast */}
			{
				success !== null ?
					<Toast data={success}
						type="success"
						id="2" />
					:
					null
			}
		</>
	);
}

export default Orders;

