/*
 *   File : editor.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Input box
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 20-11-2021
 */

/*import packages*/
import React, { useState, useEffect, useRef } from 'react';

/*import style*/
import Loader from '../loader/loader';


const Editor = (props) => {
    const editorRef = useRef()
    const [editorLoaded, setEditorLoaded] = useState(false)
    const { CKEditor, ClassicEditor } = editorRef.current || {}
    //eslint-disable-next-line
    const [data, setData] = useState("");


    useEffect(() => {
        editorRef.current = {
            CKEditor: require('@ckeditor/ckeditor5-react').CKEditor, //Added .CKEditor
            ClassicEditor: require('@ckeditor/ckeditor5-build-classic'),
        }
        setEditorLoaded(true);
        if (props.value) {
            setData(props.value);
        }
    }, [props.value]);

    return (
        <>
            {
                editorLoaded ?

                    <CKEditor
                        editor={ClassicEditor}
                        onChange={(evt, editor) => {
                            var tempData = "";
                            tempData = editor.getData();
                            props.editorChange(tempData);
                        }}
                        config={{
                            toolbar: {
                              items: [
                                'bold',
                                'italic',
                                'link',
                                'undo',
                                'redo',
                                'bulletedList',
                                'numberedList',
                                'alignment',
                                'heading',
                                'blockQuote',
                                'table'
                                // Remove 'image' from this array to hide the image button
                                // 'image'
                              ],
                            }
                        }}
                        data={props?.value}
                    />

                    :
                    <Loader />
            }
        </>
    )
}

export default Editor