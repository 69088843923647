/*
 *   File : outerHeader.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Login Header
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 10-12-2021
 */

/*import packages*/
import React from 'react';
import { Link } from "react-router-dom";

/*import styles*/
import styles from '../../styles/components/outerHeader.module.scss';

/*import config*/
import devconfig from '../../config/config';
import { TextBtn } from '../cta';


/*import components*/
// import { TextBtn } from '../../components/cta/index';

const OuterHeader = (props) => {
	return (
		<div className={`row ${styles.e_outer_header}`}>
			<div className="col-lg-6 col-md-6 col-sm-6 col-4">
				<Link to='/'>
					<img src={devconfig.outerHeader.OuterHeaderLogo} alt="MIRA Logo" className={`${styles.e_logo}`} />
				</Link>
			</div>
			<div className="col-lg-6 col-md-6 col-sm-6 col-8 text-end">
				{
					props.type === 1 ?
						<Link to="/forgot-password">
							<TextBtn name="Forgot Password?" className={`${styles.e_btn}`} />
						</Link>
						:
						null
				}
			</div>
		</div>
	)
}

export default OuterHeader;

