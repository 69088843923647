/*
 *   File : createPassword.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Auth Screen
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 10-12-2021
 */

/*import packages*/
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'

/*import assets*/

/*import components*/
import { OuterHeader, Footer } from '../../components/navigation/index';
import { Input } from '../../components/input/index';
import { PrimaryBtn } from '../../components/cta/index';
import { Toast } from '../../components/note';
import APIPost from '../../services/postApi';
import devconfig from '../../config/config';

/*import container*/


const CreatePassword = (props) => {
	const navigate = useNavigate();
	const [password, setPassword] = useState("");
	const [cpassword, setCpassword] = useState("");
	const [error, setError] = useState(null);
	const [loader, setLoader] = useState(false);

	const location = useLocation();
    const auth = location.state.auth;

	const _changePassword = () => {
		setLoader(true);
		let url = devconfig.admin + "/auth/reset-password"
		let data = JSON.stringify({
			temp_token: auth,
			password: password
		});

		APIPost(url, data).then((response) => {
			if (response.status === true) {
				setLoader(false);
				localStorage.clear()
				navigate("/");
			} else {
				setLoader(false);
				setError(response.message);
			}
		});
	} 

	/*--------- password and confirm password validation ------*/
	const validate = () => {
		var pattern = new RegExp(/^(?=.*\d)(?=.*?[a-zA-Z])(?=.*?[\W_]).{8,100}$/);
		if (password !== cpassword) {
			setError("Your password and confirm password does not match.");
		}
		else if (!pattern.test(password)) {
			setError("🔐Weak Password. 8 characters, 1 alphabet, 1 number, 1 symbol");
			return false
		} else {
			_changePassword();
			
		}
	}

	return (
		<>
			<div className="container-fluid e-auth-wrapper">
				<OuterHeader
					type={2}
				/>
				<div className="container">
					<div className="row">
						<div className="col-lg-4 mt-2  pt-5 offset-lg-4">
							<div className="e-form-wrap">
								<h4>Create password</h4>
								<div className="pt-3">
									<label>Password</label>
									<Input
										placeholder="Password"
										value={password}
										valueChange={(value) => { setPassword(value) }}
										type="text"
									/>
								</div>
								<div className="pt-4">
									<label>Confirm password</label>
									<Input
										placeholder="Confirm Password"
										value={cpassword}
										valueChange={(value) => { setCpassword(value) }}
										type="text"
									/>
								</div>
								<div className="row mt-4 pt-2 mb-4">
									<div className="col-lg-12">
										{
											password && cpassword ?
												<PrimaryBtn
													name="Create password"
													className={loader ? "e-btn-loader" : ""}
													handleClick={() => { validate() }}
												/>
												:
												<PrimaryBtn
													name="Create password"
													className="e-disabled"
												/>
										}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="e-auth-footer">
					<Footer />
				</div>
				<div>
					{
						error !== null ?
							<Toast
								data={error}
								type="error"
								id="1"
							/>
							:
							null
					}
				</div>
			</div>

		</>
	);
}

export default CreatePassword;
