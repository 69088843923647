/*
 *   File : index.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Index file for Notification container;
 *   Integrations : 28-09-2022
 *   Version : v1.0
 *   Created : 28-08-2022
*/

/*import packages*/
import { useEffect, useState } from 'react';

/*import components*/
import { Input } from '../../components/input/index';
import { PrimaryBtn } from '../../components/cta/index';
import { Loader } from '../../components/loader';
import { Toast } from '../../components/note';

/*import services */
import APIGet from '../../services/getApi';
import devconfig from '../../config/config';
import APIPost from '../../services/postApi';

const Index = () => {

    const [allocation, setAllocation] = useState(null);
    const [error, setError] = useState(null);
    const [loader, setLoader] = useState(false);
    const [submitLoader, setSubmitLoader] = useState(false);
    const [success, setSuccess] = useState(null);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });

        getInflationData(true);

    }, []);

    useEffect(() => {
        if (error !== null) {
            setTimeout(() => {
                setError(null)
            }, 3000)
        }
    }, [error])

    useEffect(() => {
        if (success !== null) {
            setTimeout(() => {
                setSuccess(null)
            }, 3000)
        }
    }, [success])

    useEffect(() => {
        setTimeout(() => {
            setError(null);
        }, 3000);
    }, [error]);

    /*----- get inflation api -*/
    const getInflationData = (status) => {
        setLoader(status);
        let url = devconfig.admin + "/config/get";
        APIGet(url).then((response) => {
            if (response.status_code === 200) {
                setLoader(false)
                let arr = response.data.filter((item) => item.type === "INFLATION")
                setAllocation(arr[0]["allocation"])
            } else {
                setAllocation(null);
                setLoader(false);
            }
        })
    }

    /* ---- update inflation ----*/
    const updateInflation = () => {
        setSubmitLoader(true);

        let url = devconfig.admin + "/config/update";

        let data = JSON.stringify({
            type: "INFLATION",
            allocation: parseFloat(allocation)
        })

        APIPost(url, data).then((response) => {
            if (response.status_code === 200) {
                setSuccess("Inflation updated")
                getInflationData(false);
                setSubmitLoader(false);
            } else {
                setError(response.message);
                setSubmitLoader(false);
            }
        })
    }
    return (
        <div className="row e-notification">
            <div className="col-lg-6">
                <div className="card border-0 e-dashboard-card pt-4 px-4 pb-2">
                    {
                        loader ?
                            <Loader />
                            :
                            <>
                                <div className="col-lg-12  mb-3">
                                    <h5 className='e-notification-form-title'>Inflation</h5>
                                </div>
                                <div className='row px-2'>
                                    <div className="col-lg-12 mb-5 pb-5">
                                        <label>Enter the inflation</label>
                                        <Input
                                            placeholder="Enter the inflation"
                                            value={allocation}
                                            type="number"
                                            className="e-notification-form-element"
                                            valueChange={(temp) => { parseFloat(setAllocation(temp)) }}
                                        />
                                    </div>

                                    <div className="col-lg-12 text-end mb-3 pt-5 mt-5">
                                        {
                                            allocation ?
                                                <PrimaryBtn
                                                    name="Edit"
                                                    className={submitLoader ? "e-notification-form-btn e-btn-loader mt-5" : "e-notification-form-btn mt-5"}
                                                    handleClick={() => {
                                                        updateInflation()
                                                    }}
                                                />
                                                :
                                                <PrimaryBtn
                                                    name="Edit"
                                                    className="e-disabled mt-5"
                                                />
                                        }
                                    </div>
                                </div>
                            </>
                    }
                </div>
                {/* Error toast */}
                {
                    error !== null ?
                        <Toast data={error}
                            type="error"
                            id="1" />
                        :
                        null
                }

                {/* Success toast */}
                {
                    success !== null ?
                        <Toast data={success}
                            type="success"
                            id="2" />
                        :
                        null
                }
            </div>
        </div>
    );
}

export default Index;