/*
 *   File : otherMenu.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Other Menu
 *   Integrations : 09-02-2022
 *   Version : v1.0
 *   Created : 31-12-2021
 */

/*import packages*/
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

/*import components*/
import { PrimaryLink } from '../../components/cta/index';

/*import styles*/
import styles from '../../styles/components/notificationMenu.module.scss';

/*import assets*/
import Bell from '../../assets/images/navigation/bell.svg'

/*import services*/
import APIPost from '../../services/postApi';
import devconfig from '../../config/config';
import { Toast } from '../note';

export default function NotificationMenu(props) {

	const [error, setError] = useState(null);
	const navigate = useNavigate();

	useEffect(() => {
		setTimeout(() => {
			setError(null);
		}, 3000);
	}, [error]);

	/*---- read notification api----*/
	const ReadNotification = (id) => {
		let url = devconfig.admin + '/notifications/read';

		let data = JSON.stringify({
			notification_id: id,
		})
		APIPost(url, data).then((response) => {
			if (response.status_code === 200) {
				if (id === null) {
					props.update();
				} else {
					navigate('/all-notification');
					props.close();
				}
			} else {
				setError(response.message);
			}
		});
	}

	return (
		<>
			<span className={`${styles.e_notification_menu} dropdown position-relative`} >
				<div className={`${styles.e_notification_div} `}>
					<div className="d-flex justify-content-between">
						<span className={`${styles.e_title} `}>Notifications</span>
						<span><PrimaryLink name="Mark all as read" handleClick={() => ReadNotification(null)} /></span>
					</div>
					<span className={`${styles.e_span_border} my-3`}></span>
					{
						props.notificationData.length > 0 ?
							<div className={`${styles.e_notification_sub_data}`}>
								{
									props.notificationData.filter((item) => item.is_read === false).map((data) => (
										<div style={{ cursor: "pointer" }} className='d-flex pt-3' onClick={() => { ReadNotification(data.notification_id) }}>
											<span><img src={Bell} alt="" /> </span>
											<span className='ps-2'> <h6 className={`${styles.e_notification_title}`}>{data.title}</h6>
												<p className={`${styles.e_notification_content} mb-1`}>{data.description}</p></span>
										</div>
									))}
								{
									props.notificationData.filter((item) => item.is_read === true).map((data) => (
										<div className='d-flex pt-3' style={{ opacity: " 0.5" }}>
											<span><img src={Bell} alt="" /> </span>
											<span className='ps-2'> <h6 className={`${styles.e_notification_title}`}>{data.title}</h6>
												<p className={`${styles.e_notification_content} mb-1`}>{data.description}</p></span>
										</div>
									))}

							</div>
							:
							null
					}
					<Link to='/all-notification'>
						<div className={`${styles.e_view} pt-3 pb-3`} onClick={() => props.close()}>View all</div>
					</Link>
				</div>
			</span>
			{
				error !== null ?
					<Toast data={error} id="1" type="error" />
					:
					null
			}
		</>
	)
}

