/*
 *   File : selectBox.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Input box
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 09-12-2021
 */

/*import package*/
import React from 'react';
import Select from 'react-select';

/*import style*/
import styles from '../../styles/components/selectBox.module.scss';

const SelectBox = (props) => {

	/* --- passing value to selectbox ----*/
	const valueChange = (e) => {
		props.selectchange(e)
	}
	const customStyles = {
		placeholder: (defaultStyles) => ({
			...defaultStyles,
			color: "#010101",
			fontWeight: "400",
			lineHeight: "18px",
			fontSize: "14px",
			opacity: '0.27'
		}),
	}

	return (
		<Select
			isSearchable={props.isSearchable}
			isClearable={props.isClearable}
			//eslint-disable-next-line
			styles={customStyles}
			className={`${styles.e_select_box}` + " " + props.className}
			onChange={(e) => { valueChange(e) }}
			isDisabled={props.isDisabled}
			options={props.options}
			value={props.value ? props.value : ""}
			isMulti={props.selectType}
			placeholder={props.placeholder}
			defaultValue={props.defaultValue}
			// menuIsOpen={true}
			{...props} />
	);
}

export default SelectBox;
