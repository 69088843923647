/*
 *   File : changePassword.js
 *   Author : https://evoqueinnovativelab.com
 *   Description : Auth Screen
 *   Integrations : null
 *   Version : 1.0.0
 *   Created : 10-12-2021
 */

/*import packages*/
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'

/*import assets*/

/*import components*/
import { OuterHeader, Footer } from '../../components/navigation/index';
import { Input } from '../../components/input/index';
import { PrimaryBtn } from '../../components/cta/index';
import devconfig from '../../config/config';
import APIPost from '../../services/postApi';
import { Toast } from '../../components/note';

/*import container*/

const VerifyOTP = (props) => {
	const navigate = useNavigate()
	const [otp, setOtp] = useState(null);
	const [error, setError] = useState(null);
	const [loader, setLoader] = useState(false);
    const location = useLocation();
    const email = location.state.email;


	useEffect(() => {
		setTimeout(() => {
			setError(null);
		}, 3000);
	}, [error]);

	const _verifyOTP = () => {
        setLoader(true);
		let url = devconfig.admin + "/auth/verify-otp"
		let data = JSON.stringify({
			email: email,
			otp: otp
		});

		APIPost(url, data).then((response) => {
			if (response.status === true) {
				setLoader(false);
				localStorage.setItem('access_token', response.data.token);
                window.location = "/"
			} else {
				setLoader(false);
				setError(response.message);
			}
		})
		
	}
	
	

	return (
		<>
			<div className="container-fluid e-auth-wrapper">
				<OuterHeader
					type={2}
				/>
				<div className="container">
					<div className="row">
						<div className="col-lg-4 mt-2  pt-5 offset-lg-4">
							<div className="e-form-wrap">
                                <h4>OTP Verification</h4>
                                <p className="py-3">Please enter the One Time Password</p>
                                <div className="">
                                    <label>Enter OTP</label>
                                    <Input
                                        placeholder="OTP"
                                        value={otp}
                                        valueChange={(value) => { setOtp(value) }}
                                        type="number"
                                    />
                                </div>
                                <div className="row mt-4 pt-2 mb-4">
                                    <div className="col-lg-12">
                                        {
                                            otp !== null ?
                                                <PrimaryBtn
                                                    name="Verify OTP"
                                                    className={loader ? "e-btn-loader" : ""}
                                                    handleClick={() => { _verifyOTP() }}
                                                />
                                                :
                                                <PrimaryBtn
                                                    name="Verify OTP"
                                                    className="e-disabled"
                                                />
                                        }
                                    </div>
                                </div>	
							</div>
						</div>
					</div>
				</div>
				<div>
					{
						error !== null ?
							<Toast data={error}
								type="error"
								id="1" />
							:
							null
					}
				</div>
				<div className="e-auth-footer">
					<Footer />
				</div>
			</div>
		</>
	);
}

export default VerifyOTP;

