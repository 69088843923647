/*
 *   File : orders.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Order list;
 *   Integrations :30-05-2023
 *   Version : v1.0
 *   Created :30-05-2023
*/
/*import packages*/
import { useState, useEffect } from 'react';

/*import components*/
import { DataTable } from '../../components/dataFormating/index';
import { Loader } from '../../components/loader';
import { EmptyNote } from '../../components/note';

/* import services */
import devconfig from '../../config/config';
import APIPost from '../../services/postApi';



const PendingGoal = (props) => {
    const [loader, setLoader] = useState(false);
    const [pendingGoals, setPendingGoals] = useState([]);


    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, []);

    useEffect(() => {
        _getPendingGoals();
        //eslint-disable-next-line
    }, []);


    /*---------API:: pending goals -----------*/
    const _getPendingGoals = () => {
        setLoader(true)
        let url = devconfig.admin + '/user/pending-goals';

        let data = JSON.stringify({
            customer_id: Number(props.id),

        });
        APIPost(url, data).then((response) => {
            if (response.status_code === 200) {
                setPendingGoals(response.data)
                setLoader(false);
            } else {
                setPendingGoals([]);
                setLoader(false);
            }
        });
    }


    /*---- mandate columns -----*/
    const GOAL_COLUMNS = [
        {
            name: 'Goal name',
            cell: (row) => <>
                {
                    row.thumbnail ?
                        <img src={row.thumbnail} alt="" draggable={false}
                            width="24px"
                            className='e-sip-image' />
                        :
                        null
                }
                <span className='ps-2'>{row.short_name}</span>
            </>,
            sortable: false,
            left: true,
            maxWidth: "220px",
            defaultSortAsc: false,
        },
        {
            name: 'Target',
            selector: row => row.target_amount ? parseFloat(row.target_amount) : 0,
            // selector: (row) => row.target_amount ? "₹" + parseFloat(row.target_amount.split("₹")[1]).toFixed(2) : '--',
            sortable: true,
            left: true,
            maxWidth: '130px',
            cell: row => <div onClick={() => { }}>
                ₹{
                    row.target_amount ?
                        row.target_amount.toLocaleString('en-IN')
                        :
                        0
                }
            </div>

        },
        {
            name: 'SIP',
            selector: row => row.sip_amount ? parseFloat(row.sip_amount) : 0,
            // selector: (row) => row.sip_amount ? "₹" + parseFloat(row.sip_amount.split("₹")[1]).toFixed(2) : '--',
            sortable: true,
            left: true,
            maxWidth: '130px',
            cell: row => <div onClick={() => { }}>
                ₹{
                    row.sip_amount ?
                        row.sip_amount.toLocaleString('en-IN')
                        :
                        0
                }
            </div>

        },
        {
            name: 'Lumpsum',
            selector: row => row.lumpsum_amount ? parseFloat(row.lumpsum_amount) : 0,
            // selector: (row) => row.lumpsum_amount ? "₹" + parseFloat(row.lumpsum_amount.split("₹")[1]).toFixed(2) : '--',
            sortable: true,
            left: true,
            maxWidth: '130px',
            cell: row => <div onClick={() => { }}>
                ₹{
                    row.lumpsum_amount ?
                        row.lumpsum_amount.toLocaleString('en-IN')
                        :
                        0
                }
            </div>

        },
        {
            name: 'Tenure',
            selector: row => row.tenure ? parseFloat(row.tenure) : 0,
            // selector: (row) => row.lumpsum_amount ? "₹" + parseFloat(row.lumpsum_amount.split("₹")[1]).toFixed(2) : '--',
            sortable: true,
            left: true,
            maxWidth: '130px',
            cell: row => <div onClick={() => { }}>
                {
                    row.tenure ?
                        row.tenure === 1 ? `${row.tenure} Year` : `${row.tenure} Years`
                        :
                        0
                }
            </div>

        },
        {
            name: 'Investment style',
            selector: row => row.investment_style ? parseFloat(row.investment_style) : 0,
            // selector: (row) => row.lumpsum_amount ? "₹" + parseFloat(row.lumpsum_amount.split("₹")[1]).toFixed(2) : '--',
            sortable: false,
            left: true,
            minWidth: '130px',
            cell: row => <div onClick={() => { }}>
                {
                    row.investment_style
                }
            </div>

        },
    ]

    return (
        <>
            <div className="row pe-0">
                <div className="col-lg-12 pe-0">
                    <div className="card e-dashboard-card border-0">
                        <div className="row pt-2">

                            {/*--------------- order table -----------*/}
                            {
                                loader ?
                                    <Loader />
                                    : (!pendingGoals.length) ?
                                        <EmptyNote note="There are no sips to show" />
                                        :
                                        <div className="col-lg-12 px-0">
                                            <DataTable
                                                pagination={true}
                                                columns={GOAL_COLUMNS}
                                                data={pendingGoals}
                                                selectableRows={false}
                                                perPage={10}
                                                rowClick={(row) => { }}
                                            />
                                        </div>
                            }

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default PendingGoal;

