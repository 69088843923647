/*
 *   File : index.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Index file for version history;
 *   Integrations :  12-08-2022
 *   Version : v1.0
 *   Created :  08-12-2023
*/
/*import packages*/
import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'bootstrap';

/*import components*/
import { DataTable } from '../../components/dataFormating/index';
import { VersionHistoryDetail } from '../../components/modal/index';
import { Loader } from '../../components/loader';
import { Toast } from '../../components/note';
import { EmptyNote } from '../../components/note';


/* import services */
import devconfig from '../../config/config';
import APIGet from '../../services/getApi';

const Index = (props) => {

    const wrapperRef = useRef(null);
    const navigate = useNavigate()
    const [loader, setLoader] = useState(false);
    const [error, setError] = useState(null);
    const [details, setDetails] = useState(null);
    const [success, setSuccess] = useState(null);
    const [versionData, setVersionData] = useState([]);


    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
        _getVersionList();
    }, []);

    useEffect(() => {
        if (details !== null) {
            var myModal = new Modal(document.getElementById("versionDetail"), {});
            myModal.show();
        }
    }, [details])


    /*---- order table columns -----*/

    const columns = [
        {
            name: 'Version ID',
            selector: (row) => row.version_id ? row.version_id : '--',
            sortable: true,
            left: true,
            minWidth: "160px",
            maxWidth: "160px",
            defaultSortAsc: false,
        },
        {
            name: 'Version',
            selector: (row) => row.version ? row.version : '--',
            sortable: true,
            left: true,
            minWidth: "110px",
            maxWidth: "110px",
            defaultSortAsc: false,
        },

        {
            name: 'Created on',
            selector: row => row.created,
            sortable: true,
            left: true,
            minWidth: '200px',
            defaultSortAsc: false,
        },

    ]

    /*--- func to open order detail modal ---*/
    const modalOpen = (row) => {
        // console.log(row)
        setDetails(row);

    }



    /*--------- version list -----------*/
    const _getVersionList = () => {
        setLoader(true)
        let url = devconfig.admin + '/rebalance/version-history';
        APIGet(url).then((response) => {
            if (response.status_code === 200) {
                setVersionData(response.data);
                setLoader(false);

            } else {
                setLoader(false);
            }
        })
    }


    return (
        <>
            <div className="row">
                <div className="col-lg-12">
                    <div className="card e-dashboard-card border-0">
                        <div className="row pt-2">
                            <div className="col-lg-4 col-3">
                                <h6 className="e-section-title p-2">All versions</h6>
                            </div>

                            {/*--------------- Version table -----------*/}
                            {
                                loader ?
                                    <Loader />
                                    : (!versionData.length) ?
                                        <EmptyNote note="There are no versions to show" />
                                        :
                                        <div className="col-lg-12 px-50">
                                            <DataTable
                                                pagination={true}
                                                columns={columns}
                                                data={versionData}
                                                perPage={30}
                                                selectableRows={false}
                                                // clearSelectedRows={downloadLoader ? true : false}
                                                // selectRows={(row) => { handleChange(row) }}
                                                rowClick={(row) => modalOpen(row)}
                                            />
                                        </div>
                            }

                        </div>
                    </div>
                </div>
            </div>

            {/*--------- Ordetdetaiil modal component ----------------*/}

            {
                details !== null ?
                    <div ref={wrapperRef}>
                        <VersionHistoryDetail type='version' data={details}
                            close={() => {
                                setDetails(null);
                            }} />
                    </div>
                    :
                    null
            }

            {/* error toast */}
            {
                error !== null ?
                    <Toast
                        data={error}
                        type="eror"
                        id="1"
                    />
                    :
                    null
            }
            {/* Success toast */}
            {
                success !== null ?
                    <Toast data={success}
                        type="success"
                        id="2" />
                    :
                    null
            }
        </>
    );
}

export default Index;

