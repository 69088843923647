/*
 *   File : orders.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Order list;
 *   Integrations :30-05-2023
 *   Version : v1.0
 *   Created :30-05-2023
*/
/*import packages*/
import { useState, useEffect } from 'react';

/*import components*/
import { DataTable, FundsTab } from '../../components/dataFormating/index';
import { Loader } from '../../components/loader';
import { Toast } from '../../components/note';
import { EmptyNote } from '../../components/note';

/* import services */
import devconfig from '../../config/config';
import APIPost from '../../services/postApi';



const SipList = (props) => {

    const [index, setIndex] = useState(1);
    const [loader, setLoader] = useState(false);
    const [error, setError] = useState(null);
    const [sipData, setSipData] = useState([]);
    const [success, setSuccess] = useState(null);


    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });


    }, []);

    useEffect(() => {
        switch (index) {
            case 1:
                _getActiveSips();
                break;
            case 2:
                _getProcessingSips();
                break;
            case 3:
                _getCancelledSips();
                break;
            case 4:
                _getPausedSips();
                break;
            case 5:
                _getFailedSips();
                break;

            default:
                break;
        }
        //eslint-disable-next-line
    }, [index]);


    useEffect(() => {
        setTimeout(() => {
            if (error !== null) {
                setError(null);
            }
        }, 3000);
    }, [error]);

    useEffect(() => {
        if (success !== null) {
            setTimeout(() => {
                setSuccess(null)
            }, 3000)
        }
    }, [success])



    /*--------- active sip -----------*/
    const _getActiveSips = (query) => {
        setLoader(true)
        let url = devconfig.admin + '/user/active-sip-list';

        let data = JSON.stringify({
            customer_id: Number(props.id),

        });
        APIPost(url, data).then((response) => {
            if (response.status_code === 200) {
                setSipData(response.data);
                setLoader(false);
            } else {
                setSipData([]);
                setLoader(false);
            }
        });
    }

    /*--------- processing sips -----------*/
    const _getProcessingSips = (query) => {
        setLoader(true)
        let url = devconfig.admin + '/user/processed-sip-list';

        let data = JSON.stringify({
            customer_id: Number(props.id),
            page_num: 1
        });
        APIPost(url, data).then((response) => {
            if (response.status_code === 200) {
                setSipData(response.data);
                setLoader(false);
            } else {
                setSipData([]);
                setLoader(false);
            }
        });
    }
    /*--------- cancelled sips -----------*/
    const _getCancelledSips = (query) => {
        setLoader(true)
        let url = devconfig.admin + '/user/cancelled-sip-list';

        let data = JSON.stringify({
            customer_id: Number(props.id),
        });
        APIPost(url, data).then((response) => {
            if (response.status_code === 200) {
                setSipData(response.data);
                setLoader(false);
            } else {
                setSipData([]);
                setLoader(false);
            }
        });
    }

    /*--------- paused sips -----------*/
    const _getPausedSips = (query) => {
        setLoader(true)
        let url = devconfig.admin + '/user/paused-list';

        let data = JSON.stringify({
            customer_id: Number(props.id),
        });
        APIPost(url, data).then((response) => {
            if (response.status_code === 200) {
                setSipData(response.data);
                setLoader(false);
            } else {
                setSipData([]);
                setLoader(false);
            }
        });
    }


    /*--------- failed sips -----------*/
    const _getFailedSips = (query) => {
        setLoader(true)
        let url = devconfig.admin + '/user/failed-sip-list';

        let data = JSON.stringify({
            customer_id: Number(props.id),
        });
        APIPost(url, data).then((response) => {
            if (response.status_code === 200) {
                setSipData(response.data);
                setLoader(false);
            } else {
                setSipData([]);
                setLoader(false);
            }
        });
    }

    /*---- active sip table columns -----*/

    const ACTIVE_SIP_COLUMNS = [
        {
            name: 'Goal name',
            cell: (row) => <>
                {
                    row.image ?
                        <img src={row.image} alt="" draggable={false}
                            width="24px"
                            className='e-sip-image' />
                        :
                        null
                }
                <span className='ps-2'>{row.goal_name}</span>
            </>,
            sortable: false,
            left: true,
            maxWidth: "280px",
            defaultSortAsc: false,
        },
        {
            name: 'Min. Sip',
            selector: row => row.min_sip ? parseFloat(row.min_sip) : 0,
            cell: row => <div onClick={() => { }}>
                ₹{
                    row.min_sip ?
                        row.min_sip.toLocaleString('en-IN')
                        :
                        0
                }
            </div>,
            sortable: false,
            left: true,
            minWidth: "110px",
            maxWidth: "110px",
            defaultSortAsc: false,
        },
        {
            name: 'Sip date',
            selector: 'date',
            sortable: false,
            left: true,
            maxWidth: '80px',
            cell: (row) => <div className="col-lg-12" >
                {row.date}
            </div>
        },
        {
            name: 'Next date',
            selector: row => row.created,
            sortable: false,
            left: true,
            minWidth: '180px',
            maxWidth: '180px',
            // sortFunction: (a, b) => customDateSort(a, b, 'order_date',),
            cell: row => <div className="col-lg-12" >
                {row.next_sip_date ? row.next_sip_date : "--"}
            </div>
        },

        {
            name: 'Amount',
            selector: row => row.amount ? parseFloat(row.amount) : 0,
            // selector: (row) => row.amount ? "₹" + parseFloat(row.amount.split("₹")[1]).toFixed(2) : '--',
            sortable: true,
            left: true,
            minWidth: '130px',
            cell: row => <div onClick={() => { }}>
                ₹{
                    row.amount ?
                        row.amount.toLocaleString('en-IN')
                        :
                        0
                }
            </div>

        },


    ]

    /*---- processing sip table columns -----*/

    const PROCESSING_SIP_COLUMNS = [
        {
            name: 'Goal name',
            cell: (row) => <>
                {
                    row.image ?
                        <img src={row.image} alt="" draggable={false}
                            width="24px"
                            className='e-sip-image' />
                        :
                        null
                }
                <span className='ps-2'>{row.goal_name}</span>
            </>,
            sortable: false,
            left: true,
            maxWidth: "320px",

            defaultSortAsc: false,
        },


        {
            name: 'Executed date',
            // selector: row => row.created,
            sortable: false,
            left: true,
            maxWidth: '180px',
            // sortFunction: (a, b) => customDateSort(a, b, 'order_date',),
            cell: row => <div className="col-lg-12" >
                {row.executed_date}
            </div>
        },

        {
            name: 'Amount',
            selector: row => row.amount ? parseFloat(row.amount) : 0,
            // selector: (row) => row.amount ? "₹" + parseFloat(row.amount.split("₹")[1]).toFixed(2) : '--',
            sortable: true,
            left: true,
            minWidth: '130px',
            cell: row => <div onClick={() => { }}>
                ₹{
                    row.amount ?
                        row.amount.toLocaleString('en-IN')
                        :
                        0
                }
            </div>

        },
    ]


    /*---- cancelled sip table columns -----*/

    const CANCELLED_SIP_COLUMNS = [
        {
            name: 'Goal name',
            cell: (row) => <>
                {
                    row.image ?
                        <img src={row.image} alt="" draggable={false}
                            width="24px"
                            className='e-sip-image' />
                        :
                        null
                }
                <span className='ps-2'>{row.goal_name}</span>
            </>,
            sortable: false,
            left: true,
            maxWidth: "320px",
            defaultSortAsc: false,
        },
        {
            name: 'Min. Sip',
            selector: row => row.min_sip ? parseFloat(row.min_sip) : 0,
            cell: row => <div onClick={() => { }}>
                ₹{
                    row.min_sip ?
                        row.min_sip.toLocaleString('en-IN')
                        :
                        0
                }
            </div>,
            sortable: false,
            left: true,
            minWidth: "110px",
            maxWidth: "110px",
            defaultSortAsc: false,
        },
        {
            name: 'Sip date',
            selector: 'date',
            sortable: false,
            left: true,
            maxWidth: '80px',
            cell: (row) => <div className="col-lg-12" >
                {row.date}
            </div>
        },
        {
            name: 'Next date',
            selector: row => row.created,
            sortable: false,
            left: true,
            maxWidth: '180px',
            // sortFunction: (a, b) => customDateSort(a, b, 'order_date',),
            cell: row => <div className="col-lg-12" >
                {row.next_sip_date}
            </div>
        },

        {
            name: 'Amount',
            selector: row => row.amount ? parseFloat(row.amount) : 0,
            // selector: (row) => row.amount ? "₹" + parseFloat(row.amount.split("₹")[1]).toFixed(2) : '--',
            sortable: true,
            left: true,
            minWidth: '130px',
            cell: row => <div onClick={() => { }}>
                ₹{
                    row.amount ?
                        row.amount.toLocaleString('en-IN')
                        :
                        0
                }
            </div>

        },


    ]

    /*---- paused and failed sip table columns -----*/
    const COMMON_SIP_COLUMNS = [
        {
            name: 'Goal name',
            cell: (row) => <>
                {
                    row.image ?
                        <img src={row.image} alt="" draggable={false}
                            width="24px"
                            className='e-sip-image' />
                        :
                        null
                }
                <span className='ps-2'>{row.goal_name}</span>
            </>,
            sortable: false,
            left: true,
            maxWidth: "320px",
            defaultSortAsc: false,
        },
        {
            name: 'Amount',
            selector: row => row.amount ? parseFloat(row.amount) : 0,
            // selector: (row) => row.amount ? "₹" + parseFloat(row.amount.split("₹")[1]).toFixed(2) : '--',
            sortable: true,
            left: true,
            maxWidth: '130px',
            cell: row => <div onClick={() => { }}>
                ₹{
                    row.amount ?
                        row.amount.toLocaleString('en-IN')
                        :
                        0
                }
            </div>

        },
        {
            name: 'Min. Sip',
            selector: row => row.min_sip ? parseFloat(row.min_sip) : 0,
            cell: row => <div onClick={() => { }}>
                ₹{
                    row.min_sip ?
                        row.min_sip.toLocaleString('en-IN')
                        :
                        0
                }
            </div>,
            sortable: false,
            left: true,
            minWidth: "110px",
            maxWidth: "110px",
            defaultSortAsc: false,
        },
        {
            name: 'Sip date',
            selector: 'date',
            sortable: false,
            left: true,
            maxWidth: '80px',
            cell: (row) => <div className="col-lg-12" >
                {row.date}
            </div>
        },
        {
            name: 'Next date',
            selector: row => row.created,
            sortable: false,
            left: true,
            maxWidth: '180px',
            // sortFunction: (a, b) => customDateSort(a, b, 'order_date',),
            cell: row => <div className="col-lg-12" >
                {row.next_sip_date}
            </div>
        },

    ]

    return (
        <>
            <div className="row pe-0">
                <div className="col-lg-12 pe-0">
                    <div className="card e-dashboard-card border-0">
                        <div className="row pt-2">
                            <div className="col-lg-9 col-3 mb-3">
                                <FundsTab data={["Active", "Processed", "Cancelled", "Paused", "Failed"]}
                                    index={index}
                                    setTab={(temp) => {
                                        setIndex(temp);

                                    }} />
                            </div>

                            {/*--------------- order table -----------*/}
                            {
                                loader ?
                                    <Loader />
                                    : (!sipData.length) ?
                                        <EmptyNote note="There are no sips to show" />
                                        :
                                        <div className="col-lg-12 px-0">
                                            <DataTable
                                                pagination={true}
                                                columns={index === 1 ? ACTIVE_SIP_COLUMNS : index === 2 ? PROCESSING_SIP_COLUMNS : index === 3 ? CANCELLED_SIP_COLUMNS : COMMON_SIP_COLUMNS}
                                                data={sipData}
                                                selectableRows={false}
                                                perPage={10}
                                                rowClick={(row) => { }}
                                            />
                                        </div>
                            }

                        </div>
                    </div>
                </div>
            </div>


            {/* error toast */}
            {
                error !== null ?
                    <Toast
                        data={error}
                        type="eror"
                        id="1"
                    />
                    :
                    null
            }
            {/* Success toast */}
            {
                success !== null ?
                    <Toast data={success}
                        type="success"
                        id="2" />
                    :
                    null
            }
        </>
    );
}

export default SipList;

