/*
 *   File : orders.js
 *   Author URI : https://evoqueinnovativelab.com
 *   Description : Order list;
 *   Integrations :30-05-2023
 *   Version : v1.0
 *   Created :30-05-2023
*/
/*import packages*/
import { useState, useEffect } from 'react';

/*import components*/
import { DataTable, FundsTab } from '../../components/dataFormating/index';
import { Loader } from '../../components/loader';
import { EmptyNote } from '../../components/note';

/* import services */
import devconfig from '../../config/config';
import APIPost from '../../services/postApi';



const MandateList = (props) => {
    const [index, setIndex] = useState(1);
    const [loader, setLoader] = useState(false);
    const [mandateData, setMandateData] = useState({});
    const [mandateList, setMandateList] = useState([]);


    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });

    }, []);

    useEffect(() => {
        _getMandateList();
        //eslint-disable-next-line
    }, []);


    useEffect(() => {
        if (Object.keys(mandateData).length > 0) {
            _getMandates();
        }
        //eslint-disable-next-line
    }, [index, mandateData]);


    function _getMandates() {
        switch (index) {
            case 1:
                setMandateList(mandateData.active);

                break;
            case 2:
                setMandateList(mandateData.inactive);

                break;

            default:
                break;
        }
    }

    /*--------- API :: mandate list -----------*/
    const _getMandateList = (query) => {
        setLoader(true)
        let url = devconfig.admin + '/user/mandate-list';

        let data = JSON.stringify({
            customer_id: Number(props.id),

        });
        APIPost(url, data).then((response) => {
            if (response.status_code === 200) {
                setMandateData(response.data)
                setLoader(false);
            } else {
                setMandateData([]);
                setLoader(false);
            }
        });
    }


    /*---- mandate columns -----*/
    const MANDATES_COLUMNS = [
        {
            name: 'Mandate ID',
            selector: row => row.mandate_id,
            // selector: (row) => row.amount ? "₹" + parseFloat(row.amount.split("₹")[1]).toFixed(2) : '--',
            sortable: false,
            left: true,
            maxWidth: '130px',
            cell: row => <div>
                {row.mandate_id}
            </div>

        },
        {
            name: 'Bank name',
            cell: (row) => <>
                <span className='ps-2'>{row.bank_name}</span>
            </>,
            sortable: false,
            left: true,
            maxWidth: "280px",
            defaultSortAsc: false,
        },
        {
            name: 'Created',
            selector: row => row.created,
            // selector: (row) => row.amount ? "₹" + parseFloat(row.amount.split("₹")[1]).toFixed(2) : '--',
            sortable: false,
            left: true,
            maxWidth: '150px',
            cell: row => <div>
                {row.created}
            </div>

        },
        {
            name: 'Mandate limit',
            selector: row => row.mandate_limit ? parseInt(row.mandate_limit) : 0,
            // selector: (row) => row.amount ? "₹" + parseFloat(row.amount.split("₹")[1]).toFixed(2) : '--',
            sortable: true,
            left: true,
            maxWidth: '150px',
            cell: row => <div>
                ₹{
                    row.mandate_limit ?
                        row.mandate_limit.toLocaleString('en-IN')
                        :
                        0
                }
            </div>

        },

        {
            name: 'Deleted date',
            selector: row => row.deleted_on,
            sortable: false,
            left: true,
            maxWidth: '180px',
            omit: index === 1 ? true : false,
            // sortFunction: (a, b) => customDateSort(a, b, 'order_date',),
            cell: row => <div className="col-lg-12" >
                {row.deleted_on}
            </div>
        },
        {
            name: 'status',
            selector: row => row.status,
            sortable: false,
            left: true,
            maxWidth: '180px',
            // sortFunction: (a, b) => customDateSort(a, b, 'order_date',),
            cell: row => <div className="col-lg-12" >
                {row.status}
            </div>
        },


    ]

    return (
        <>
            <div className="row pe-0">
                <div className="col-lg-12 pe-0">
                    <div className="card e-dashboard-card border-0">
                        <div className="row pt-2">
                            <div className="col-lg-9 col-3 mb-3">
                                <FundsTab data={["Active", "Inactive"]}
                                    index={index}
                                    setTab={(temp) => {
                                        setIndex(temp);

                                    }} />
                            </div>

                            {/*--------------- order table -----------*/}
                            {
                                loader ?
                                    <Loader />
                                    : (!mandateList.length) ?
                                        <EmptyNote note="There are no sips to show" />
                                        :
                                        <div className="col-lg-12 px-0">
                                            <DataTable
                                                pagination={true}
                                                columns={MANDATES_COLUMNS}
                                                data={mandateList}
                                                selectableRows={false}
                                                perPage={10}
                                                rowClick={(row) => { }}
                                            />
                                        </div>
                            }

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default MandateList;

